import { useEffect, useRef, useState, useCallback } from "react";
import Card from "@components/Card";
import useIProovFaceAuth from "./hooks/useIProovFaceAuth";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useAnalytics } from "@contexts/Analytics";
import store from "@store/index";
import { illustrations } from "@utils/illustrations";
import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";
import { Skeleton } from "antd";

export interface Props {
  name: string;
  heading?: string;
  subheading?: string;
  illustration?: string;
  takeSelfieButtonText?: string;

  preview: boolean;
  previewHeading?: string;
  previewSubheading?: string;
  previewRetryText?: string;
  sdkOptions?: {
    totalAttempts: {
      liveness: number;
      faceAuthentication: number;
    };
  };
}

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps.uniqueSteps.faceAuthIProov";

const FaceAuthIProov = (props: Props) => {
  const [error, setError] = useState(false);
  const [sdkLoading, setSdkLoading] = useState(true);
  const [numberOfLivenessAttempts, setNumberOfLivenessAttempts] = useState(0);
  const [numberOfFaceAuthenticationAttempts, setNumberOfFaceAuthenticationAttempts] = useState(0);
  const [disableGoForward, setDisableGoForward] = useState(true);

  const incrementLivenessAttempts = useCallback(() => {
    setNumberOfLivenessAttempts((count) => count + 1);
  }, [numberOfLivenessAttempts, setNumberOfLivenessAttempts]);

  const incrementFaceAuthenticationAttempts = useCallback(() => {
    setNumberOfFaceAuthenticationAttempts((count) => count + 1);
  }, [numberOfFaceAuthenticationAttempts, setNumberOfFaceAuthenticationAttempts]);

  const containerRef = useRef(null);

  const { t, i18n } = useTranslation();
  const { logAnalyticsStepInfo, analyticsSteps } = useAnalytics();
  const faceAuth = useIProovFaceAuth();
  const totalOfFaceAuthenticationAttempts = props.sdkOptions?.totalAttempts?.faceAuthentication ?? 0;
  const totalOfLivenessAttempts = props.sdkOptions?.totalAttempts?.liveness ?? 0;

  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const { goForward } = store.navigation;

  const retryOnError = () => {
    setError(true);
    setSdkLoading(true);
    capture(t("general.message.loader.buttonText", "Tentar novamente"));
  };

  const validateWebHookSent = async () => {
    const faceAuthIproovResult = await faceAuth.getResults();
    const webHookSent = faceAuth.verifyWebHook();

    if (!webHookSent) {
      if (faceAuthIproovResult?.attemptId) {
        return await faceAuth.callWebHook(faceAuthIproovResult.attemptId, false);
      } else {
        return await faceAuth.callWebHook(null, true);
      }
    }
    return true;
  };

  const canGoForward = async () => {
    const webHookValidated = await validateWebHookSent();
    const faceAuthIproovHasResult = faceAuth.hasResults();

    if (faceAuthIproovHasResult && webHookValidated) {
      setDisableGoForward(false);
      goForward();
    } else {
      retryOnError();
    }
  };

  const capture = (buttonText?: string) => {
    document.addEventListener("sdk-button-ready", () => {
      setSdkLoading(false);
    });
    faceAuth.capture(containerRef.current, {
      onStartLoader: (loaderMessage: string) => {
        startLoader({
          heading: t(loaderMessage, "Processando sua foto, aguarde um momento..."),
        });
      },
      onGetResults: async (result: any) => {
        await validateWebHookSent();

        if (result.isAlive) {
          if (result.isMatch) {
            setError(false);
            stopLoader();
            canGoForward();
          } else {
            if (totalOfFaceAuthenticationAttempts > 0) {
              incrementFaceAuthenticationAttempts();
            } else {
              retryOnError();
            }
          }
        } else {
          if (totalOfLivenessAttempts > 0) {
            incrementLivenessAttempts();
          } else {
            retryOnError();
          }
        }
      },
      onInitialize: () => {
        stopLoader();
      },
      onError: () => {
        stopLoader();
        setError(true);
      },
      onRetry: () => {
        setSdkLoading(true);
        capture();
      },
      buttonText,
      language: i18n?.language ?? "pt_BR",
    });
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_FACE_AUTH_IPROOV);
    capture();
    setError(false);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    if (numberOfLivenessAttempts > 0 || numberOfFaceAuthenticationAttempts > 0) {
      let isFaceAuthenticationAttemptsOver = numberOfFaceAuthenticationAttempts >= totalOfFaceAuthenticationAttempts;
      let isLivenessAttemptsOver = numberOfLivenessAttempts >= totalOfLivenessAttempts;

      if (isLivenessAttemptsOver || isFaceAuthenticationAttemptsOver) {
        goForward();
      } else {
        setError(true);
        capture(t("general.message.loader.buttonText", "Tentar novamente"));
      }
    }
  }, [numberOfLivenessAttempts, numberOfFaceAuthenticationAttempts]);

  return (
    <Card>
      <Card.Body center>
        <Card.Illustration
          alt={
            error
              ? t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.CIRCLE_ERROR])
              : t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.PHONE])
          }
          src={error ? illustrations.CIRCLE_ERROR : props.illustration ?? illustrations.PHONE}
          className=""
        />
        <Card.Heading
          text={
            error
              ? t(`${I18N_BASE_PATH}.error.title`, "Oops, algo deu errado")
              : props.heading ?? t(`${I18N_BASE_PATH}.heading`, "Hora da selfie!")
          }
        />
        <Card.Subheading
          text={
            error
              ? t(`${I18N_BASE_PATH}.error.description`, "Não foi possível tirar a selfie, tente novamente.")
              : props.subheading ??
                t(`${I18N_BASE_PATH}.subheading`, "Clique no botão abaixo quando estiver pronto para tirar a selfie.")
          }
        />
        <div ref={containerRef} id="fa-container" className="h-full w-full flex items-center justify-center" />
        {sdkLoading && <Skeleton.Button active={true} shape="round" size="large" />}
      </Card.Body>
      <Card.NavBar disabledGoForward={disableGoForward} />
    </Card>
  );
};

export default observer(FaceAuthIProov);
