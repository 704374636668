import { useEffect, useMemo, useRef } from "react";
import useTemplateData from "@hooks/useTemplateData";
import store from "@store/index";
import { removeSpecialCharsAndNumbers } from "@utils/formatting";
import useEvents from "@hooks/useEvents";
import { useAnalytics } from "@contexts/Analytics";
import IPROOV_EVENTS from "@utils/iproovEvents";

let _result;
let _language;
let facesSdk;
let isClosing = false;

export default function useIProovLiveness() {
  const { sdkToken, templateData } = useTemplateData();
  const { emitEvent } = useEvents();
  const { logAnalyticsEventInfo, analyticsEvents } = useAnalytics();

  const eventProcessed = useRef({});
  const eventListeners = useRef([]);

  const sdk = window["FacesSDK"];

  const { personId } = store.variables.person;

  const pflStep = useMemo(
    () =>
      templateData?.template?.steps.find((step) => {
        const stepName = removeSpecialCharsAndNumbers(step.name);
        return stepName === "LIVENESSIPROOV";
      }),
    [templateData],
  );
  const transactionTemplateId = useMemo(() => templateData?.data?.transactionTemplateId, [templateData]);

  const linkPerson = useMemo(
    () => ({
      cpf: templateData?.data?.cpf?.trim(),
    }),
    [templateData],
  );

  const sdkPersonId = useMemo(() => {
    if (!!personId) return personId;
    if (!!linkPerson.cpf) return linkPerson.cpf;

    return `${transactionTemplateId}.${Math.random()}`;
  }, [personId, linkPerson, transactionTemplateId]);

  const addEventListener = (type, listener, options = {}) => {
    document.addEventListener(type, listener, options);
    eventListeners.current.push({ type, listener });
  };

  const removeEventListeners = () => {
    eventListeners.current.forEach(({ type, listener }) => {
      document.removeEventListener(type, listener);
    });
    eventListeners.current = [];
  };

  const capture = async (containerNode, options) => {
    _result = undefined;
    _language = "pt_br";
    removeEventListeners();

    if (options?.language === "pt-br") _language = "pt_br";
    if (options?.language === "en-US") _language = "en";
    if (options?.language === "es-MX") _language = "es";

    if (facesSdk) {
      if (!isClosing) {
        isClosing = true;
        await facesSdk.close();
        facesSdk = null;
        isClosing = false;
      } else {
        return;
      }
    }

    try {
      addEventListener("sdk-ready", options.onInitialize, { once: true });

      addEventListener("streamed", () => {
        options.onStartLoader("general.message.loader.iproovProcessingPhoto");
      });

      addEventListener(
        "retryFromPreview",
        async () => {
          if (facesSdk) {
            await facesSdk.close();
          }
        },
        { once: true },
      );

      IPROOV_EVENTS.forEach((type) => {
        addEventListener(
          type,
          async (e) => {
            if (eventProcessed.current[type]) {
              return;
            }

            eventProcessed.current[type] = true;

            const detail = e.detail;
            const executeRetry =
              type === "canceled" || (type === "error" && detail?.feedback === "error_no_face_found");

            if (executeRetry) {
              if (facesSdk) {
                await facesSdk.close();
              }
              eventProcessed.current[type] = false;
              await options.onRetry();
            }

            const eventType = `LIVENESS_IPROOV_${type.toUpperCase()}`;

            logAnalyticsEventInfo(analyticsEvents[eventType], { detail: e.detail, type });

            let sendEvent = () => {};

            if (type === "failed") {
              sendEvent = () => emitEvent({ code: "SDK_CAPTURE_FAILED", detail: _result || {} });
            }

            if (type === "error") {
              sendEvent = () => emitEvent({ code: "SDK_CAPTURE_INVALID", detail: _result || {} });
            }

            window.addEventListener("SDK_CAPTURE_RESULT", () => sendEvent(), { once: true });
          },
          { once: true },
        );
      });

      facesSdk = await sdk.initializeSdk(sdkToken, containerNode?.id, false, sdkPersonId, {
        environment: process.env.REACT_APP_SDK_ENV,
        isSigned: false,
        settings: {
          filter: pflStep?.sdkOptions?.filter ?? "clear",
          language: _language,
        },
        permissionButton: {
          label: "",
          color: "",
          backgroundColor: "",
          borderRadius: "",
          border: "",
          padding: "",
        },
        startButton: {
          label: options?.buttonText ?? pflStep?.takeSelfieButtonText,
          color: pflStep?.buttonTextColor ?? "#fff",
          backgroundColor: pflStep?.buttonBackgroundColor ?? "#666666",
          borderRadius: "100px",
          border: `1px solid ${pflStep?.buttonBorderColor ?? pflStep?.buttonBackgroundColor ?? "#666666"}`,
          padding: "0rem 0.7rem",
        },
      });

      await options.onInitialize();

      logAnalyticsEventInfo(analyticsEvents.LIVENESS_IPROOV_START, { _result });

      _result = await facesSdk.execute();

      logAnalyticsEventInfo(analyticsEvents.LIVENESS_IPROOV_CLOSE, { _result });

      options.onStartLoader("general.message.loader.iproovProcessingPhoto");

      emitEvent({ code: "SDK_CAPTURE_RESULT", detail: _result || {} });

      await options.onGetResults(_result);
    } catch (error) {
      logAnalyticsEventInfo(analyticsEvents.LIVENESS_IPROOV_ERROR, {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      });
    }
  };

  const hasResults = () => !!_result;

  const getImagePreview = () => _result?.imageUrl || "";

  useEffect(() => {
    return () => {
      removeEventListeners();
    };
  }, []);

  return {
    capture,
    hasResults,
    getImagePreview,
  };
}
