import { useTranslation } from "react-i18next";

import Card from "@components/Card";
import { illustrations } from "@utils/illustrations";
import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";

const I18N_BASE_PATH = "src.pages.onboarding.steps.special.notFound";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body className="not-found-card" center>
        <Card.Illustration
          src={illustrations.NOT_FOUND}
          alt={t(defaultOnboardingIllustrationsAlternativeText?.illustration_notFound) || ""}
          className="p-8"
        />
        <Card.Heading text={t(`${I18N_BASE_PATH}.components.heading`, "Página não encontrada (404)")} />
        <Card.Subheading text={t(`${I18N_BASE_PATH}.components.subheading`, "Essa URL não existe ou já expirou.")} />
      </Card.Body>
    </Card>
  );
};

export default NotFound;
