import { useMemo } from "react";
import { useAnalytics } from "@contexts/Analytics";
import store from "@store/index";

import { getUploadUrl, uploadTempFile } from "@steps/UploadDocuments/services";
import useTemplateData from "@hooks/useTemplateData";
import { removeSpecialCharsAndNumbers } from "@utils/formatting";

let csLiveness,
  _result,
  _resultCaf,
  imageSaved = false,
  isSavedInCafBucket = false;

export default function useLivenessClearSale() {
  const { templateData } = useTemplateData();
  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const { logAnalyticsEventInfo, analyticsEvents } = useAnalytics();

  const clearSaleStep = useMemo(
    () =>
      templateData?.template?.steps.find((step) => {
        const stepName = removeSpecialCharsAndNumbers(step.name);
        return stepName === "LIVENESSCLEARSALE";
      }),
    [templateData],
  );

  const isOnTemplate = () => !!clearSaleStep;

  const hasResults = () => !!isSavedInCafBucket;
  const resetResults = () => {
    _result = undefined;
    _resultCaf = undefined;
    imageSaved = false;
    isSavedInCafBucket = false;
  };

  const capture = (options) => {
    const sdkCapture = () => {
      imageSaved = false;
      csLiveness.open();
      if (options?.onReady) {
        options.onReady();
      }
    };

    // const CSLivenessClass = process.env.REACT_APP_ENV === "prod" ? CSLiveness : CSLivenessDev;
    // const CSLivenessFolder =
    //   process.env.REACT_APP_ENV === "prod" ? "/csliveness/dist/core-sdk" : "/csliveness-dev/dist/core-sdk";

    startLoader("Aguardando leitor facial...");
    if (!csLiveness) {
      // eslint-disable-next-line no-undef
      csLiveness = new CSLiveness.Instance(
        process.env.REACT_APP_CLEARSALE_CLIENT_ID,
        process.env.REACT_APP_CLEARSALE_SECRET,
        "./@studio/csliveness/dist/core-sdk",
      );
      csLiveness.initialize();
    } else {
      sdkCapture();
    }

    csLiveness.on("onReady", () => {
      logAnalyticsEventInfo(analyticsEvents.LIVENESS_CLEARSALE_START, { clearSaleSdkInstanceInfo: csLiveness });
      csLiveness.open();
      if (options?.onReady) {
        options.onReady();
      }
    });

    csLiveness.on("onCompleted", async (event) => {
      logAnalyticsEventInfo(analyticsEvents.LIVENESS_CLEARSALE_INFO, {
        clearSaleSdkCompletedInfo: { event, imageSaved },
      });
      if (imageSaved) {
        return;
      }

      _result = event;
      imageSaved = true;

      let numberOfRetries = 0;

      while (numberOfRetries < 3 && !isSavedInCafBucket) {
        try {
          isSavedInCafBucket = await saveInCafBucket();
          numberOfRetries++;
        } catch (e) {
          if (numberOfRetries < 3) {
            numberOfRetries++;
          } else {
            resetResults();
            logAnalyticsEventInfo(analyticsEvents.LIVENESS_CLEARSALE_UPLOAD_ERROR, {
              clearSaleSdkErrorInfo: JSON.stringify(e, Object.getOwnPropertyNames(e)),
            });
            if (options?.onError) {
              options.onError();
            }
          }
        }
      }

      if (!isSavedInCafBucket) {
        resetResults();
        logAnalyticsEventInfo(analyticsEvents.LIVENESS_CLEARSALE_UPLOAD_ERROR, {
          clearSaleSdkUploadError: "Error Uploading clearSale Selfie File to S3",
        });
        if (options?.onError) {
          options.onError();
        }
      }

      if (options?.onCompleted) {
        if (isSavedInCafBucket) {
          options.onCompleted();
        } else {
          capture(options);
        }
      }

      stopLoader();
    });

    csLiveness.on("onError", (event) => {
      console.error("onError", event);
      logAnalyticsEventInfo(analyticsEvents.LIVENESS_CLEARSALE_ERROR, { clearSaleSdkErrorInfo: event });
      stopLoader();
      if (options?.onError) {
        options.onError();
      }
    });
  };

  const saveInCafBucket = async () => {
    if (!_result) return false;

    const response = await getUploadUrl({ contentType: "jpg" });
    _resultCaf = await response.json();

    if (_resultCaf?.uploadUrl) {
      const resFetch = await fetch(`data:image/png;base64,${_result.image}`);
      const blob = await resFetch.blob();

      const resUploadTempFile = await uploadTempFile(_resultCaf?.uploadUrl, blob);

      if (!!resUploadTempFile && resUploadTempFile.status === 200) {
        logAnalyticsEventInfo(analyticsEvents.LIVENESS_CLEARSALE_UPLOAD, {
          clearSaleSdkUploadInfo: { imageCafBucketUrl: _resultCaf.getUrl },
        });
        return true;
      } else {
        logAnalyticsEventInfo(analyticsEvents.LIVENESS_CLEARSALE_UPLOAD_ERROR, {
          clearSaleSdkUploadError: resUploadTempFile,
        });
        return false;
      }
    }
  };

  const getImageUrl = () => {
    if (!_resultCaf) return "";

    return _resultCaf.getUrl;
  };

  return {
    capture,
    isOnTemplate,
    getImageUrl,
    hasResults,
  };
}
