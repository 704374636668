import { useState, useCallback, useEffect } from "react";
import BrowserCompatibility from "@components/BrowserCompatibility";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import getStep, { Step } from "@steps/index";
import Cookies from "js-cookie";

const Onboarding = () => {
  const [overlay, setOverlay] = useState(false);

  const { currentStep } = store.navigation;

  const onBeforeUnload = useCallback(() => {
    // TODO: test if the event is being fired once
    if (currentStep) {
      const currentTime = new Date().getTime();
      Cookies.set("lastTimeAcessed", String(currentTime));
      Cookies.set("lastTimeAcessedStep", currentStep.name);
    }
  }, [currentStep]);

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);
  }, [onBeforeUnload]);

  if (!currentStep) return null;

  const Step = getStep(currentStep.name as Step);

  return (
    <>
      <div className={`h-full w-full ${overlay ? "opacity-30" : "opacity-100"}`}>{<Step {...currentStep.props} />}</div>
      <BrowserCompatibility setOverlay={setOverlay} />
    </>
  );
};

export default observer(Onboarding);
