import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

import PhoneIllustration from "@assets/images/illustrations/phone.svg";
import LeftArrowImage from "@assets/images/left-arrow.png";

import { Logger } from "@utils/logging";
import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";

const QRCode = require("qrcode.react");
const TinyURL = require("tinyurl");
const currentUrl = window.location.href;

const I18N_BASE_PATH = "src.pages.onlyMobile";

const OnlyMobile = () => {
  const { t } = useTranslation();

  const [shortUrl, setShortUrl] = useState(t(`${I18N_BASE_PATH}.states.shortUrl`, "Carregando endereço..."));

  useEffect(() => {
    TinyURL.shorten(currentUrl, function (res, error) {
      if (error || !res) {
        Logger.error(error, {
          message: "Couldn't get tiny url.",
          currentUrl,
        });
        setShortUrl(currentUrl.replace("https://", ""));
      } else setShortUrl(res.replace("https://", ""));
    });
  }, []);

  return (
    <div className="only-mobile-card bg-white relative h-screen w-screen py-10 pl-16 xl:pl-20 overflow-y-auto bigger-zoom">
      <div className="flex flex-col v-sm:justify-center relative h-full w-full z-20 xl:max-w-2xl">
        <h1 className="text-gray-800 text-3xl v-sm:text-4xl v-lg:text-5xl font-bold">
          {t(`${I18N_BASE_PATH}.components.heading`, "Este link só pode ser acessado de seu celular")}
        </h1>
        <p className="text-gray-800 text-xl v-sm:text-2xl v-lg:text-3xl mb-6 v-sm:mb-10 v-lg:mb-16">
          {t(
            `${I18N_BASE_PATH}.components.paragraph`,
            "Para prosseguir é necessário acessar esta página em seu dispositivo móvel.",
          )}
        </p>
        <div className="flex">
          <QRCode value={currentUrl} size={260} />
          <div className="flex flex-col items-start pl-10">
            <img src={LeftArrowImage} alt="" className="h-20 object-scale-down relative" imageClassName="h-20" />
            <p className="text-gray-800 text-xl v-sm:text-2xl v-lg:text-3xl font-bold mb-2">
              {t(
                `${I18N_BASE_PATH}.components.qrCode`,
                "Este QR Code vai lhe redirecionar para esta página automaticamente.",
              )}
            </p>
            <p className="text-gray-800 text-xl v-lg:text-2xl">
              {t(`${I18N_BASE_PATH}.components.camera`, "Aponte sua câmera aqui")}
            </p>
          </div>
        </div>
        <p className="text-gray-800 text-xl v-lg:text-2xl pt-10">
          {t(`${I18N_BASE_PATH}.components.camera`, "Ou abra seu navegador e acesse:")}
          <br />
          <span className="font-bold">{shortUrl}</span>
        </p>
      </div>
      <div
        className="fixed flex items-end justify-end right-0 bottom-0 pt-20 h-full invisible xl:visible"
        style={{ width: "37.5rem" }}
      >
        <ReactSVG
          src={PhoneIllustration}
          role="img"
          aria-label={t(defaultOnboardingIllustrationsAlternativeText?.illustration_phone) || ""}
          className="themed-svg z-10 w-full h-full"
        />
      </div>
    </div>
  );
};

export default OnlyMobile;
