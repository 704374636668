import { useEffect, useState } from "react";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";
import Button from "@components/Button";
import Card from "@components/Card";
import { FormInput } from "@components/Form";
import { useAnalytics } from "@contexts/Analytics";
import { illustrations } from "@utils/illustrations";

import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";

import usePassiveFaceLiveness from "@steps/PFL/hooks/usePassiveFaceLiveness";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import useTemplateData from "@hooks/useTemplateData";
import useSendFinalPost from "./hooks/useSendFinalPost";

const I18N_BASE_PATH = "src.pages.onboarding.steps.done";

const Done = (props) => {
  const [isAnalyticsLoading, setAnalyticsIsLoading] = useState(false);
  const { t } = useTranslation();

  const { templateData } = useTemplateData();
  const finalPost = useSendFinalPost({ postEventMessage: props.postEventMessage });

  const passiveFaceLiveness = usePassiveFaceLiveness();
  const exceededAttemptsError = passiveFaceLiveness.isExceededAttemptsError();

  const { start: startLoader, stop: stopLoading } = store.ui.loading;
  const companyData = store.variables.company;
  const { personName, personAttorney } = store.variables.person;

  const { logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions, analyticsSteps } = useAnalytics();

  useEffect(() => {
    const logAnalyticsFinalStep = async () => {
      setAnalyticsIsLoading(true);
      await logAnalyticsStepInfo(analyticsSteps.STEP_DONE);
      setAnalyticsIsLoading(false);
    };

    logAnalyticsFinalStep();
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    if (finalPost.loading || (props.redirectUrl && isAnalyticsLoading)) {
      startLoader({ heading: t("general.message.loader.finishing", "Finalizando...") });
    }
    if (!finalPost.loading && (!props.redirectUrl || !isAnalyticsLoading) && !props.postEventMessage) {
      stopLoading();
    }
  }, [finalPost.loading, isAnalyticsLoading, props]);

  return (
    <Card>
      <Card.Body center={templateData?.template.type !== "PJ_QSA" && templateData?.template.type !== "PJ"}>
        {Boolean((finalPost.success && !props.postEventMessage) || finalPost.error) && (
          <>
            <Card.Illustration
              alt={
                finalPost.success &&
                t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.DATASET])
                  ? t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.DATASET])
                  : !finalPost.success &&
                    t(
                      defaultOnboardingIllustrationsAlternativeText?.[
                        props.errors?.finalPostError?.illustration ?? illustrations.NOT_FOUND
                      ],
                    )
                  ? t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.DATASET])
                  : ""
              }
              src={
                finalPost.success && !exceededAttemptsError
                  ? props.illustration ?? illustrations.DATASET
                  : props.errors?.finalPostError?.illustration ?? illustrations.NOT_FOUND
              }
            />
            <Card.Heading
              className="text-center"
              text={
                finalPost.success && !exceededAttemptsError
                  ? props.heading ?? t("src.pages.onboarding.steps.allSteps.uniqueSteps.done.description", "Tudo certo")
                  : props.errors?.finalPostError?.heading ??
                    t(
                      "src.pages.onboarding.steps.allSteps.uniqueSteps.done.errors.finalPostError.heading",
                      "Ocorreu um erro",
                    )
              }
            />
            <Card.Subheading
              className="text-center"
              text={
                finalPost.success && !exceededAttemptsError
                  ? props.subheading ??
                    t(
                      "src.pages.onboarding.steps.allSteps.uniqueSteps.done.subheading",
                      "Sua documentação foi enviada para análise.",
                    )
                  : props.errors?.finalPostError?.subheading ??
                    t(
                      "src.pages.onboarding.steps.allSteps.uniqueSteps.done.errors.finalPostError.subheading",
                      "Por favor, refaça o Onboarding. Caso o problema persistir, entre em contato com o suporte.",
                    )
              }
            />
            {finalPost.success && props.redirectUrl && (
              <Button
                type="primary"
                onClick={() => {
                  logAnalyticsActionInfo(analyticsActions.ONBOARDING_REDIRECT, {}, props.name);
                  window.location.href = props.redirectUrl;
                }}
              >
                {props.redirectText ||
                  t(`${I18N_BASE_PATH}.components.button.continueRegistration`, "Continuar cadastro")}
              </Button>
            )}
            {!finalPost.error && companyData.companyQsaInfo.length > 0 && (
              <section className="mt-5">
                {companyData.companyQsaInfo.filter((qsaPartner) => qsaPartner.main).length > 0 && (
                  <div className="flex flex-col text-left w-full">
                    <Card.Text
                      small
                      className="font-bold !mb-6"
                      text={t("general.message.flowCompleted", "Fluxo completo")}
                    />
                    {companyData.companyQsaInfo
                      .filter((qsaPartner) => qsaPartner.main)
                      .map((qsaPartner, i) => (
                        <FormInput
                          key={`SOCIO_COMPLETED_${i}`}
                          id={`socio_completed_${i}`}
                          label={t(`${personAttorney ? "general.label.attorney" : "general.label.partner"}`)}
                          className="w-full !border-primary !border rounded-md pointer-events-none"
                          icon={<Icon icon="person" />}
                          valid={true}
                          value={qsaPartner?.name ? qsaPartner.name : personName}
                          uppercase
                          textOnly
                        />
                      ))}
                  </div>
                )}
                {companyData.companyQsaInfo.filter((qsaPartner) => !qsaPartner.main).length > 0 && (
                  <div className="flex flex-col text-left w-full">
                    <Card.Text
                      small
                      className="font-bold !mb-6"
                      text={t("general.message.flowUncompleted", "Aguardando completar")}
                    />
                    {companyData.companyQsaInfo
                      .filter((qsaPartner) => !qsaPartner.main)
                      .map((qsaPartner, i) => (
                        <FormInput
                          key={`SOCIO_NOT_COMPLETED_${i}`}
                          id={`socio_${i}`}
                          label={t("general.label.partner", "Sócio")}
                          className="w-full pointer-events-none"
                          icon={<Icon icon="person" />}
                          suffix={<Icon className="text-gray-400" icon="clock" />}
                          value={qsaPartner.name}
                          uppercase
                          textOnly
                        />
                      ))}
                  </div>
                )}
              </section>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default observer(Done);
