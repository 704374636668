import { useMemo, useContext, createContext, useState, useCallback, useEffect } from "react";
import type { Steps } from "@store/navigation";
import type { Qsa, QSAStatus } from "@store/variables/company";
import i18n from "../i18n";

import cloneDeepWith from "lodash.clonedeepwith";
import isEqual from "lodash.isequal";
import { applyVariable } from "@utils/formatting";

import store from "@store/index";
import isEmpty from "lodash.isempty";
import usePhoneFormatter from "./formatters/usePhoneFormatter";
import { useParams } from "react-router-dom";
import { getQueryVariable } from "@utils/index";

interface TemplateMetadata {
  sdkToken: string;
  workflowId?: string;
  status: "PENDING" | "COMPLETED";
  token: string;
  transactionTemplateId: string;
  oneTimeLinkAcessed?: boolean;
  cpf?: string;
  name?: string;
  birthDate?: string;
  cnpj?: string;
  email?: string;
  phone?: string;
  variables?: {
    [key: string]: string;
  };
  idType?: string;
  personId?: string;
  companyId?: string;
  qsa?: QSAStatus[];
  companyQuery?: {
    endedAt: string;
    startedAt: string;
    status: "QUERY_COMPLETED" | "QUERY_REQUESTED" | "QUERY_ERROR";
  };
  company?: {
    QSA: Qsa[];
    officialName?: string;
    fantasyName?: string;
    phoneNumber?: string;
    address?: {
      zipCode: string;
      street: string;
      neighborhood: string;
      number: string;
      complement: string;
      city: string;
      state: string;
    };
  };
}

interface TemplateService {
  _id: string;
  name: string;
  body: {
    [key: string]: string;
  };
  urlParams: {
    [key: string]: string;
  };
}

interface Template {
  _id: string;
  version: string;
  user: {
    id: string;
  };
  updatedAt: string;
  type: "PF" | "PJ" | "PJ_QSA";
  themeId: string;
  tenantId: string;
  steps: Steps["props"][];
  name: string;
  internationalization: {
    language: string;
  };
  footer: {
    text: string;
    textColor: string;
    logo: string;
    background: string;
  };
  font: {
    name: string;
    source: string;
  };
  events: Array<{
    on: string;
    run: string[];
  }>;
  documentType: Array<{
    type: string;
  }>;
  createdAt: string;
  loader?: string;
  favicon?: string;
  configurations?: {
    captureAcceptedTermsData: boolean;
    verifyBrowserCompatibility: boolean;
    dataReload: boolean;
    immutableAttributes: boolean;
    accessibility: {
      highContrast: boolean;
      increaseFont: boolean;
      monochromeMode: boolean;
      monochromeColor: string;
    };
    googleTagManagerId?: string;
  };
  colors: {
    primary?: string;
    secondary?: string;
    illustrations?: string;
    headings?: string;
    backButtonIcon?: string;
    backButton?: string;
    backButtonBorder?: string;
    tipsIcon?: string;
    tipsBackground?: string;
    tipsText?: string;
    progressbar?: string;
  };
  background: string;
  variables: {
    [key: string]: string;
  };
  backgroundSteps?: string;
  texts?: {
    backButton?: string;
  };
  mobileOnly?: boolean;
  helpButtonLink?: string;
  logo: string;
  documentTypes?: any; // TODO: type this
  rules?: Array<{
    id: string;
    flow: string;
    else_flow: string;
  }>;
  category?: "FACE_AUTHENTICATOR";
}

export interface TemplateData {
  requestId: string;
  tenantId: string;
  data: TemplateMetadata;
  services: TemplateService[];
  template: Template; //clientOptions
}

export interface TemplateDataError {
  message: string;
  requestId: string;
}

interface TemplateDataContextProps {
  templateData?: TemplateData;
  isTemplateValid?: boolean;
  sdkToken?: string;
  oneTimeLinkAcessed?: boolean;
  onboardingStatus?: TemplateMetadata["status"];
  changeOnboardingStatus: (status: TemplateMetadata["status"]) => void;
}

const TemplateDataContext = createContext<TemplateDataContextProps>({} as TemplateDataContextProps);

interface TemplateDataContextProviderProps {
  children: React.ReactElement;
}

export const TemplateDataContextProvider: React.FC<TemplateDataContextProviderProps> = ({ children }) => {
  const [templateData, _setTemplateData] = useState<TemplateData | undefined>(undefined);
  const [isTemplateValid, setIsTemplateValid] = useState<boolean | undefined>(undefined);
  const [onboardingStatus, setOnboardingStatus] = useState<TemplateMetadata["status"]>();
  const [oneTimeLinkAcessed, setOneTimeLinkAcessed] = useState<boolean>(false);
  const { token: urlToken } = useParams<{ token: string }>();
  const queryToken = getQueryVariable("token");

  const token = useMemo(() => urlToken ?? queryToken, [urlToken, queryToken]);

  const { start: startLoading, stop: stopLoading } = store.ui.loading;
  const { setImmutableVariable, updateVariables, person: personStore, company: companyStore } = store.variables;
  const { setSteps } = store.navigation;
  const { setIdtype, setToken, setTenantId, setWorkflowId } = store.variables.general;
  const { formatPhone } = usePhoneFormatter();

  const sdkToken = useMemo(() => templateData?.data?.sdkToken, [templateData]);

  const parseTemplateData = useCallback((data: TemplateData["template"]) => {
    let clientOptionsUpdated: Partial<TemplateData["template"]> = {};
    const parsedClientOptionsValuesArray: any[] = [];
    const clientOptionsKeys = Object.keys(data) as Array<keyof TemplateData["template"]>;
    // template options that can't be parsed
    const clientOptionsNotToBeParsed = {
      steps: data?.steps || null,

      // there is no point to parse the object containing the variables
      variables: data?.variables || null,
    };

    // Replacing the name of each variable found with its assigned value
    Object.values(data)?.forEach((option) => {
      let parsedOption = cloneDeepWith(option, (value) => {
        if (typeof value === "string") {
          return applyVariable(value, true, store.variables.flatten());
        }
      });

      // Populating the array with the values of the parsed variables
      parsedClientOptionsValuesArray.push(isEqual(option, parsedOption) ? option : parsedOption);
    });

    // Updating the client options object with the values of the variables found
    parsedClientOptionsValuesArray?.forEach(
      (option, index) => (clientOptionsUpdated[clientOptionsKeys[index]] = option),
    );

    return { ...clientOptionsUpdated, ...clientOptionsNotToBeParsed } as TemplateData["template"];
  }, []);

  const fetchTemplateData = useCallback(async () => {
    try {
      startLoading({
        isPageLoading: true,
      });

      const template = await fetch(`${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/onboardings/${token}`);

      const templateData = (await template.json()) as TemplateData | TemplateDataError;

      if ("message" in templateData) {
        throw new Error(templateData.message);
      }

      if (!templateData) {
        throw new Error("Template not found");
      }

      if (templateData.template?.internationalization?.language === "en-GB") {
        templateData.template.internationalization.language = "en-US";
      }

      if (!isEmpty(templateData.data?.variables)) updateVariables(templateData.data.variables);

      _setTemplateData({ ...templateData, template: parseTemplateData(templateData.template) });
      setIsTemplateValid(true);
    } catch (error) {
      console.error("fetchTemplateData", error);
      setIsTemplateValid(false);
    } finally {
      stopLoading();
    }
  }, [token, _setTemplateData]);

  useEffect(() => {
    fetchTemplateData();
  }, [fetchTemplateData]);

  useEffect(() => {
    setToken(token);
  }, [token]);

  // set initial data
  useEffect(() => {
    if (templateData) {
      if (templateData?.data?.status) setOnboardingStatus(templateData.data.status);
      if (templateData?.data?.oneTimeLinkAcessed) setOneTimeLinkAcessed(templateData.data.oneTimeLinkAcessed);

      if (templateData.template?.internationalization?.language) {
        i18n.changeLanguage(templateData.template.internationalization.language);
      }

      if (templateData.data?.idType) setIdtype(templateData.data.idType);
      if (templateData.template?.tenantId) setTenantId(templateData.template.tenantId);
      if (templateData.data?.workflowId) setWorkflowId(templateData.data?.workflowId);
      if (templateData.data?.personId) personStore.setPersonId(templateData.data.personId);
      if (templateData.data?.companyId) companyStore.setCompanyId(templateData.data.companyId);
      if (templateData.template?.steps) setSteps(templateData.template.steps);

      if (templateData.data?.cpf) {
        personStore.setPersonCpf(templateData.data.cpf);
        setImmutableVariable("personCpf");
      }
      if (!!templateData.data?.name) {
        personStore.setPersonName(templateData.data.name);
      }
      if (!!templateData.data?.birthDate) {
        personStore.setPersonBirthDate(templateData.data.birthDate);
      }
      if (templateData.data?.cnpj) {
        companyStore.setCompanyCnpj(templateData.data.cnpj);
        setImmutableVariable("companyCnpj");
      }
      if (templateData.data?.email) personStore.setPersonEmail(templateData.data.email);
      if (templateData.data?.phone) {
        const { phone } = templateData.data;

        const formattedPhone = formatPhone("+55 ".concat(phone.startsWith("55") ? phone.slice(2) : phone));
        if (templateData.template?.type === "PF") {
          personStore.setPersonPhoneNumber(formattedPhone);
        } else if (templateData.template?.type === "PJ") {
          companyStore.setCompanyPhoneNumber(formattedPhone);
        }
      }
      // Company data
      if (templateData.data?.company) {
        const QSA = templateData?.data?.company?.QSA?.filter((qsa) => !qsa?.cnpj);
        companyStore.setCompanyQsa(QSA || []);

        let { officialName, fantasyName, phoneNumber } = templateData.data.company;

        if (officialName) companyStore.setCompanyOfficialName(officialName);
        if (fantasyName) companyStore.setCompanyFantasyName(fantasyName);

        if (!!officialName) setImmutableVariable("companyOfficialName");
        if (!!fantasyName) setImmutableVariable("companyFantasyName");

        if (phoneNumber) companyStore.setCompanyPhoneNumber(formatPhone("+55 " + phoneNumber));

        if (templateData.data?.company?.address) {
          let { zipCode, street, neighborhood, number, complement } = templateData.data?.company?.address;
          companyStore.setCompanyAddressZipCode(zipCode);
          companyStore.setCompanyAddressStreet(street);
          companyStore.setCompanyAddressNeighborhood(neighborhood);
          companyStore.setCompanyAddressNumber(number);
          companyStore.setCompanyAddressComplement(complement);
        }
      }
      if (templateData.data?.qsa) companyStore.setCompanyQsaStatus(templateData.data.qsa);

      if (templateData?.template?.configurations?.immutableAttributes) {
        const attributes: any[any] = [
          "personId",
          "personCpf",
          "personName",
          "personBirthDate",
          "companyId",
          "companyCnpj",
        ];
        attributes.map((attribute: any) => setImmutableVariable(attribute));
      }
    }
  }, [templateData]);

  const changeOnboardingStatus = useCallback((status: TemplateMetadata["status"]) => {
    setOnboardingStatus(status);
  }, []);

  return (
    <TemplateDataContext.Provider
      value={{ templateData, isTemplateValid, sdkToken, oneTimeLinkAcessed, onboardingStatus, changeOnboardingStatus }}
    >
      {children}
    </TemplateDataContext.Provider>
  );
};

export default function useTemplateData() {
  const context = useContext(TemplateDataContext);

  if (context === undefined) {
    throw new Error("useTemplateData must be used within a TemplateDataContextProvider");
  }

  return context;
}
