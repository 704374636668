import { useTranslation } from "react-i18next";

import Button from "@components/Button";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { useEffect, useMemo } from "react";
import useTemplateData from "@hooks/useTemplateData";

const I18N_BASE_PATH = "src.components.browserCompatibility";

export const compatibleBrowsers = ["Chrome", "Safari", "Firefox"];

interface Props {
  setOverlay: (value: boolean) => void;
}

const BrowserCompatibility = (props: Props) => {
  const { templateData } = useTemplateData();
  const { t } = useTranslation();

  const userStore = store.user;

  const showCompatibility = useMemo(
    () =>
      Boolean(
        templateData?.template?.configurations?.verifyBrowserCompatibility &&
          !(userStore.metadata.browserName && compatibleBrowsers.includes(userStore.metadata.browserName)) &&
          !userStore.readBrowserCompatibilityTerms,
      ),
    [
      templateData?.template?.configurations?.verifyBrowserCompatibility,
      userStore.metadata.browserName,
      userStore.readBrowserCompatibilityTerms,
    ],
  );

  useEffect(() => {
    if (showCompatibility) {
      props.setOverlay(true);
    } else {
      props.setOverlay(false);
    }
  }, [showCompatibility]);

  const handleAcceptButton = () => {
    props.setOverlay(false);
    userStore.setReadBrowserCompatibilityTerms();
  };

  if (showCompatibility)
    return (
      <div
        className={`browser-compatibility-card absolute bg-transparent w-full h-full z-50 flex items-center justify-center transition-all duration-300`}
      >
        <div className="bg-white flex items-center justify-center flex-col px-5 py-6 mx-4 md:mx-16 text-center shadow-lg rounded-2xl">
          <p className="!text-black text-sm font-semibold !leading-5 max-w-lg">
            {t(
              `${I18N_BASE_PATH}.heading`,
              "Identificamos que você está utilizando um navegador que não garantimos compatibilidade.",
            )}
          </p>
          <p className="!text-black text-sm font-semibold !leading-5 pt-4 max-w-sm">
            {t(`${I18N_BASE_PATH}.paragraph`, "Se possível, utilize Chrome, Safari ou Firefox.")}
          </p>

          <Button rounded type="primary" size="md" onClick={handleAcceptButton} className="mt-5">
            {t(`${I18N_BASE_PATH}.button`, "Ok, entendi")}
          </Button>
        </div>
      </div>
    );

  return null;
};

export default observer(BrowserCompatibility);
