import Card from "@components/Card";
import "./LoaderDefault.css";

export const LoaderDefaultLogo = () => <div className="animated-background logo"></div>;

const LoaderDefault = () => {
  return (
    <div className="timeline-item">
      <div className="animated-background image"></div>
      <div className="animated-background headline"></div>
      <div className="animated-background content"></div>
      <div className="animated-background footer"></div>
    </div>
  );
};
export default LoaderDefault;
