import { Icon } from "@combateafraude/react";
import { FormInput, InputTypes } from "@components/Form";

const Password = ({
  id,
  icon = "lock_valid",
  maxLength,
  label,
  placeholder,
  required = false,
  onChange,
  goForwardButtonWasClicked,
  value,
  onBlur,
  validity,
}) => {
  return (
    <FormInput
      id={id}
      className="!m-0"
      maxLength={maxLength}
      inputType={InputTypes.PASSWORD}
      onBlur={onBlur}
      label={label}
      placeholder={placeholder}
      icon={!!icon && <Icon icon={icon} />}
      value={value}
      onChange={onChange}
      required={required}
      valid={required ? validity : undefined}
      showRequiredMessage={required && goForwardButtonWasClicked && !validity}
    />
  );
};

export default Password;
