import { useMemo } from "react";
import { Logger } from "@utils/logging";
import { useAnalytics } from "@contexts/Analytics";
import useEvents from "@hooks/useEvents";
import { sendWebHookNotify } from "../services";
import store from "@store/index";
import useTemplateData from "@hooks/useTemplateData";
import { removeSpecialCharsAndNumbers } from "@utils/formatting";

let faceAuth, _result;

export default function useFaceAuth() {
  const { templateData, sdkToken } = useTemplateData();
  const { logAnalyticsEventInfo, analyticsEvents } = useAnalytics();
  const { emitEvent } = useEvents();
  const { FaceAuthenticatorSdk } = window["@combateafraude/face-authenticator"];
  const { token } = store.variables.general;
  const { personId } = store.variables.person;
  const { haveStep } = store.navigation;

  const linkPerson = useMemo(
    () => ({
      cpf: templateData?.data?.cpf?.trim(),
      name: templateData?.data?.name,
    }),
    [templateData],
  );
  const faStep = useMemo(
    () =>
      templateData?.template?.steps.find((step) => {
        const stepName = removeSpecialCharsAndNumbers(step.name);
        return stepName === "FA";
      }),
    [templateData],
  );

  const initialize = async () => {
    _result = undefined;
    if (!faceAuth) {
      Logger.console("FA initialize");

      faceAuth = new FaceAuthenticatorSdk({
        token: sdkToken,
        environment: process.env.REACT_APP_SDK_ENV,
        environmentSettings: {
          disableVisibilityChangeSecurity: true,
          disableFaceDetectionSecurity: true,
        },
        capturerSettings: {
          disableAdvancedCapturing: true,
          disableVideoCapturing: true,
        },
        textSettings: {
          messages: {
            isNotAliveMessage: faStep?.sdkOptions?.initialize?.textSettings?.messages?.isNotMatchMessage,
          },
        },
      });
    }

    if (!faceAuth?.getIsInitialized()) {
      const permission = await faceAuth.initPermissions();
      const suported = faceAuth.isSupported();

      return permission && suported;
    }

    return true;
  };

  const reset = async () => {
    Logger.console("FA reset", {
      _result,
      initialized: faceAuth?.getIsInitialized(),
    });
    try {
      if (await faceAuth?.getIsInitialized()) {
        await faceAuth.close();
        await faceAuth.dispose();
      }
    } catch (error) {
      Logger.error("FA reset", error);
    }
  };

  const capture = async (containerNode, options) => {
    _result = undefined;
    try {
      if (!faceAuth?.getIsInitialized()) {
        await faceAuth.initialize();
      }
      await options.onInitialize();

      Logger.console("FA capture", {
        _result,
        initialized: faceAuth?.getIsInitialized(),
        hasContainerNode: !!containerNode,
        isOnTemplate: isOnTemplate(),
        onboardingLinkPersonCpf: linkPerson.cpf,
      });

      if (!!containerNode) {
        logAnalyticsEventInfo(analyticsEvents.FA_START);

        const callWebHook = async (attemptId) => {
          const webHookNotifyResponse = await sendWebHookNotify(attemptId, token);
          if (webHookNotifyResponse?.status === 200) {
            Logger.console("FA WebHook Notify Response", webHookNotifyResponse);
          } else {
            Logger.console("FA WebHook Notify Error", webHookNotifyResponse);
            logAnalyticsEventInfo(analyticsEvents.FA_WEB_HOOK_ERROR, webHookNotifyResponse);
          }
        };

        faceAuth.addEventListener("capture_failed", (e) => {
          options?.onCaptureInvalid?.();
          Logger.console("FA: IS_MATCH_FALSE EVENT", e?.detail);
          emitEvent({
            code: "SDK_FACE_AUTH",
            detail: {
              isMatch: false,
              personId: linkPerson.cpf,
            },
          });
          if (!!e.detail.data) {
            callWebHook(e.detail.data);
          }
        });

        _result = await faceAuth.capture(
          containerNode,
          [
            {
              mode: faStep?.sdkOptions?.capture?.captureSettings?.mode ?? "manual",
              attempts: 0,
              duration: faStep?.sdkOptions?.capture?.captureSettings?.automaticCaptureTimeoutInSeconds ?? 0,
            },
          ],
          {
            personId: { cpf: personId ?? linkPerson.cpf, name: linkPerson.name },
            totalAttempts: faStep?.sdkOptions?.capture.captureSettings?.totalAttempts ?? 25,
          },
        );

        Logger.console("FA capture results", {
          _result,
        });

        if (!!_result) {
          if (_result.attemptId) {
            await callWebHook(_result.attemptId);
          }

          logAnalyticsEventInfo(analyticsEvents.FA_CLOSE);

          if (typeof _result.isMatch === "boolean") {
            emitEvent({
              code: "SDK_FACE_AUTH",
              detail: {
                isMatch: _result.isMatch,
                personId: linkPerson.cpf,
              },
            });
          }

          await options.onGetResults(_result);
        } else {
          options.onError(true);
        }
      } else {
        Logger.error("FA capture", {
          initialized: faceAuth?.getIsInitialized(),
          containerNode,
        });
        options.onError();
      }
    } catch (error) {
      Logger.error("FA capture", error);
      options.onError();
    }
  };

  const getIsMatch = () => {
    return _result?.isMatch;
  };

  const hasResults = () => {
    return _result !== undefined;
  };

  const getImagePreview = () => {
    if (!hasResults()) return "";

    return _result.imageUrl;
  };

  const isOnTemplate = () => {
    return templateData?.template?.category === "FACE_AUTHENTICATOR" && haveStep("FA");
  };

  return {
    initialize,
    capture,
    isOnTemplate,
    reset,
    getIsMatch,
    getImagePreview,
    hasResults,
  };
}
