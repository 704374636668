import { Icon } from "@combateafraude/react";
import { FormInput, InputTypes } from "@components/Form";

const Number = ({
  id,
  icon,
  maxLength,
  label,
  placeholder,
  required = false,
  onChange,
  goForwardButtonWasClicked,
  value,
  validity,
  onBlur,
}) => (
  <FormInput
    id={id}
    className="!m-0"
    maxLength={maxLength}
    type="number"
    inputType={InputTypes.NUMBER}
    label={label}
    placeholder={placeholder}
    icon={!!icon && <Icon icon={icon} />}
    value={value}
    onChange={onChange}
    required={required}
    onBlur={onBlur}
    valid={required ? validity : undefined}
    showRequiredMessage={required && goForwardButtonWasClicked && !validity}
    onKeyPress={(event) => {
      if (/e|-/i.test(event.key)) {
        event.preventDefault();
      }
    }} // disable keys "e", "-"
    onPaste={(e) => {
      e.preventDefault();
      return false;
    }} // disable paste
  />
);

export default Number;
