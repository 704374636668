import AccessibilityDropdown, { Props as AssessibilityProps } from "@components/AccessibilityDropdown";
import { LoaderDefaultLogo } from "@components/Loader/LoaderDefault";
import HelpButton from "@components/HelpButton";
import useWindowDimensions from "@hooks/useWindowDimensions";

import type { HighContrastTheme, MonochromeTheme } from "@hooks/useTheme";

import { observer } from "mobx-react-lite";
import type { TemplateData } from "@hooks/useTemplateData";

interface Props {
  children: React.ReactNode;
  isVisible?: boolean;
  showAccessibilityDropdown?: boolean;
  showHelpButtonLink?: boolean;
  showLoaderLogo?: boolean;
  monochromeTheme: MonochromeTheme;
  highContrastTheme: HighContrastTheme;
  accessibilityDropdownProps: AssessibilityProps;
  clientOptions?: TemplateData["template"];
  isLoading?: boolean;
}

export default observer(function LayoutDefault(props: Props) {
  const {
    highContrastTheme,
    monochromeTheme,
    isVisible = true,
    showAccessibilityDropdown = true,
    showHelpButtonLink = true,
    showLoaderLogo = true,
    isLoading = false,
  } = props;

  const { height } = useWindowDimensions();

  return (
    <div className={`${!isVisible ? "hidden overflow-hidden" : ""}`}>
      {(showAccessibilityDropdown || showHelpButtonLink) && (
        <div className="absolute top-2 sm:top-5 right-2 sm:right-5 flex flex-row items-center justify-center gap-3">
          {showHelpButtonLink && !!props?.clientOptions?.helpButtonLink && (
            <HelpButton helpButtonLink={props?.clientOptions?.helpButtonLink} />
          )}
          {showAccessibilityDropdown && <AccessibilityDropdown {...props.accessibilityDropdownProps} />}
        </div>
      )}
      <div
        className="w-screen h-full fixed bg-cover bg-center overflow-y-auto overflow-x-hidden"
        style={
          monochromeTheme === "monochrome"
            ? {
                backgroundImage:
                  !props?.clientOptions?.background?.startsWith("#") && highContrastTheme === "light"
                    ? "url(" + props?.clientOptions?.background + ")"
                    : "",
              }
            : {
                backgroundImage:
                  !props?.clientOptions?.background?.startsWith("#") && highContrastTheme === "light"
                    ? "url(" + props?.clientOptions?.background + ")"
                    : "",
                backgroundColor:
                  props?.clientOptions?.background?.startsWith("#") && highContrastTheme === "light"
                    ? props?.clientOptions?.background
                    : highContrastTheme === "contrast"
                    ? "#000000"
                    : "",
              }
        }
      >
        <div className="relative h-full mx-auto flex flex-col items-center xs:px-3 z-20">
          {isLoading && !props?.clientOptions?.logo && showLoaderLogo ? (
            <LoaderDefaultLogo />
          ) : props?.clientOptions?.logo ? (
            <img
              src={props?.clientOptions?.logo}
              className="my-2 xs:my-4 sm:my-10 h-10 xs:h-12 sm:h-16 px-10 max-w-full xs:max-w-xs xs:px-0 sm:max-w-sm object-scale-down"
              alt="Logo"
            />
          ) : (
            <span className="block my-2 xs:my-4 sm:my-10 h-10 xs:h-12 sm:h-16 px-10 max-w-full xs:max-w-xs xs:px-0 sm:max-w-sm object-scale-down"></span>
          )}
          {props.children}
          {props?.clientOptions?.footer && height > 800 && (
            <div className="bottom-0 w-full min-h-16 max-h-16 z-10 invisible sm:visible overflow-hidden">
              {props?.clientOptions?.footer.background && (
                <>
                  <img
                    className="relative z-0 object-fill min-h-16 max-h-16 -bottom-1"
                    style={{ minWidth: "1920px" }}
                    src={props?.clientOptions?.footer.background}
                    alt=""
                  />
                </>
              )}
              {(props?.clientOptions?.footer.logo || props?.clientOptions?.footer.text) && (
                <div className="left-0 top-0 w-full h-full flex justify-center items-center">
                  {props?.clientOptions?.footer.logo && (
                    <img className="h-8 mr-4 z-10" src={props?.clientOptions?.footer.logo} alt="" />
                  )}
                  {props?.clientOptions?.footer.text && (
                    <span
                      className="font-semibold z-10"
                      style={
                        props?.clientOptions?.footer.textColor ? { color: props?.clientOptions?.footer.textColor } : {}
                      }
                    >
                      {props?.clientOptions?.footer.text}
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
