import { useEffect } from "react";
import { toJS } from "mobx";
import store, { RootStore } from "@store/index";
import { convertMillisecondsToHours } from "@utils/index";
import mergeWith from "lodash.mergewith";
import SimpleCrypto from "simple-crypto-js";

const simpleCrypto = new SimpleCrypto(process.env.REACT_APP_CRYPTO_SECRET);

const encrypt = (data: string) => simpleCrypto.encrypt(data);

const decrypt = <T,>(data: string) => simpleCrypto.decrypt(data) as T;

interface Props {
  enabled?: boolean;
  apiToken: string;
}

interface SavedData {
  store?: Omit<RootStore, "navigation">;
  millisecondsOfLastSave: number;
  onboardingToken: string;
  variables?: Record<string, any>;
}

const supportedBrowsers = ["Internet Explorer", "Microsoft Edge", "Safari", "Chrome", "Firefox", "Opera"];

const localStorageKey = "savedOnboardingData";

export function clearSavedData() {
  localStorage.removeItem(localStorageKey);
}

export default function useSavedData(props: Props) {
  const { browserName } = store.user.metadata;

  const saveData = () => {
    const millisecondsOfLastSave = new Date().getTime();

    if (supportedBrowsers.includes(browserName))
      localStorage.setItem(
        localStorageKey,
        encrypt(
          JSON.stringify(
            {
              store: toJS(store),
              millisecondsOfLastSave,
              onboardingToken: props.apiToken,
            },
            (key, value) => {
              // Recursive keys in the store need to be added here
              if (key === "rootStore" || key === "variablesStore" || key === "userStore") return undefined;

              // Stores that shouldn't be saved
              if (key === "navigation" || key === "ui") return undefined;

              return value;
            },
          ),
        ),
      );
  };

  const updateData = () => {
    const savedData = localStorage.getItem(localStorageKey);

    if (savedData) {
      const data = decrypt<SavedData>(savedData);

      const currentDateInHours = convertMillisecondsToHours(new Date().getTime());
      const lastSaveInHours = convertMillisecondsToHours(data.millisecondsOfLastSave);
      const hoursIn45Days = 45 * 24;

      if (currentDateInHours - lastSaveInHours >= hoursIn45Days || data.onboardingToken !== props.apiToken) {
        localStorage.removeItem(localStorageKey);
      } else {
        // suport for old saved data
        if (data.variables) {
          store.variables.updateVariables(data.variables);
        } else {
          mergeWith(store, data.store);
        }
      }
    }
  };

  useEffect(() => {
    if (props.enabled) {
      window.onbeforeunload = saveData;
      updateData();
    }
  }, [props.enabled]);
}
