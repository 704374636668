import { useState } from "react";
import { useAnalytics } from "@contexts/Analytics";
import useRudderAnalytics from "@contexts/RudderAnalytics/useRudderAnalytics";
import { Logger } from "@utils/logging";
import useFaceAuth from "@steps/FA/hooks/useFaceAuth";
import usePassiveFaceLiveness from "@steps/PFL/hooks/usePassiveFaceLiveness";
import useDocumentDetector from "@steps/DD/hooks/useDocumentDetector";

const useGetCameraAccess = () => {
  const { logAnalyticsEventInfo, analyticsEvents } = useAnalytics();
  const { setRudderTrackData, RudderStackEvents, RudderStackPermissionUpdateStatus } = useRudderAnalytics();
  const faceAuth = useFaceAuth();
  const passiveFaceLiveness = usePassiveFaceLiveness();
  const documentDetector = useDocumentDetector();

  const [permissionsGranted, setPermissionsGranted] = useState(false);
  const [permissionsRefused, setPermissionsRefused] = useState(false);
  const [permissionsError, setPermissionsError] = useState(false);
  const [gettingPermissions, setGettingPermissions] = useState(false);

  const getPermissions = async () => {
    setGettingPermissions(true);
    let permission = false;

    try {
      if (navigator.mediaDevices.getUserMedia) {
        if (faceAuth.isOnTemplate()) {
          const faceAuthPermission = await faceAuth.initialize();
          permission = permission || faceAuthPermission;
        }

        if (passiveFaceLiveness.isOnTemplate()) {
          const passiveFaceLivenessPermission = await passiveFaceLiveness.initialize();
          permission = permission || passiveFaceLivenessPermission;
        }

        if (documentDetector.isOnTemplate()) {
          const documentDetectorPermission = await documentDetector.initialize();
          permission = permission || documentDetectorPermission;
        }

        if (!permission) {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          stream.getTracks().forEach((track) => track.stop());
          permission = true;
        }

        if (permission) {
          setRudderTrackData(RudderStackEvents.CAMERA_PERMISSION_UPDATED, {
            action: RudderStackPermissionUpdateStatus.ALLOWED,
          });
        }
      } else {
        throw new Error("unsupported");
      }
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't get Camera Access permissions.",
      });
      const errorMessage = String(error).toLowerCase();
      if (errorMessage.includes("unsupported")) {
        logAnalyticsEventInfo(analyticsEvents.CAMERA_ACCESS_UNSUPPORTED, {
          error: error.message,
          originError: error,
        });
        setRudderTrackData(RudderStackEvents.CAMERA_PERMISSION_UPDATED, {
          status: RudderStackPermissionUpdateStatus.DISMISSED,
        });
      }

      if (errorMessage.includes("denied")) {
        logAnalyticsEventInfo(analyticsEvents.CAMERA_ACCESS_DENIED, {
          error: error.message,
          originError: error,
        });
        setRudderTrackData(RudderStackEvents.CAMERA_PERMISSION_UPDATED, {
          action: RudderStackPermissionUpdateStatus.DENIED,
        });
        setPermissionsRefused(true);
      } else {
        logAnalyticsEventInfo(analyticsEvents.CAMERA_ACCESS_ERROR, {
          error: error.message,
          originError: error,
        });
        setRudderTrackData(RudderStackEvents.CAMERA_PERMISSION_UPDATED, {
          status: RudderStackPermissionUpdateStatus.DISMISSED,
        });
        setPermissionsError(true);
      }
    }

    if (permission) {
      setPermissionsGranted(true);
    }
    logAnalyticsEventInfo(analyticsEvents.PERMISSIONS_RESULT, { permissionAccepted: !!permission });
    setGettingPermissions(false);

    return permission;
  };

  return { getPermissions, gettingPermissions, permissionsGranted, permissionsRefused, permissionsError };
};

export default useGetCameraAccess;
