import { useEffect, useState, useCallback, useMemo, useRef } from "react";

import { shadeColor } from "@utils/index";
import { Logger } from "@utils/logging";
import type { TemplateData } from "./useTemplateData";

export type HighContrastTheme = "contrast" | "light";
export type MonochromeTheme = "monochrome" | "light";

export default function useTheme(template: TemplateData["template"] | undefined) {
  const highFontSize = "18px";
  const defaultFontSize = "16px";

  const [highContrastTheme, _setHighContrastTheme] = useState<HighContrastTheme>();
  const [monochromeTheme, _setMonochromeTheme] = useState<MonochromeTheme>();

  const [fontSize, _setFontSize] = useState(defaultFontSize);

  const monochromeColor = useMemo(
    () =>
      template?.configurations?.accessibility?.monochromeColor ?? localStorage.getItem("monochromeColor") ?? "#E21B45",
    [template],
  );

  const isHighContrastMode = useMemo(
    () => highContrastTheme === "contrast" && monochromeTheme === "light",
    [highContrastTheme, monochromeTheme],
  );

  const isMonochromeMode = useMemo(
    () => monochromeTheme === "monochrome" && highContrastTheme === "light",
    [highContrastTheme, monochromeTheme],
  );

  const defaultFont = useMemo(() => template?.font, [template]);
  const isDefaultFontSize = useMemo(() => fontSize === defaultFontSize, [fontSize]);

  const colors = useMemo(() => template?.colors, [template]);

  const fontSourceElementRef = useRef<HTMLLinkElement | null>(null);

  const root = document.documentElement;
  const head = document.getElementsByTagName("head")[0];

  const appliesMonochromeColor = () => {
    try {
      const monochromeThemeLink = document.createElement("link");

      monochromeThemeLink.rel = "stylesheet";
      monochromeThemeLink.type = "text/css";
      monochromeThemeLink.href = "styles/themes/monochrome.css";
      monochromeThemeLink.id = "monochrome-theme-css";

      head.appendChild(monochromeThemeLink);

      root.style.setProperty("--color-primary", monochromeColor);
      root.style.setProperty("--color-primary-light", shadeColor(monochromeColor, 0.9));

      root.style.setProperty("--color-secondary", shadeColor(monochromeColor, -0.3));
      root.style.setProperty("--color-secondary-light", shadeColor(monochromeColor, 0.9));

      root.style.setProperty("--color-illustrations", monochromeColor);
      root.style.setProperty("--color-illustrations-dark", shadeColor(monochromeColor, -0.3));

      root.style.setProperty("--color-back-button-icon", monochromeColor);
      root.style.setProperty("--color-back-button", monochromeColor);
      root.style.setProperty("--color-back-button-border", monochromeColor);

      root.style.setProperty("--color-tips-icon", monochromeColor);
      root.style.setProperty("--color-tips-text", monochromeColor);
      root.style.setProperty("--color-tips-background", monochromeColor);

      root.style.setProperty("--color-headings", monochromeColor);
      root.style.setProperty("--color-progressbar", monochromeColor);

      (window as any).less?.modifyVars({
        "@color-primary": monochromeColor,
        "@color-secondary": monochromeColor,
      });
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't change theme monochrome colors.",
      });
    }
  };

  const appliesTemplateColors = () => {
    try {
      const stylesheetMonochrome = document.getElementById("monochrome-theme-css");
      const stylesheetContrast = document.getElementById("contrast-theme-css");
      if (!!stylesheetMonochrome) stylesheetMonochrome.remove();
      if (!!stylesheetContrast) stylesheetContrast.remove();

      if (colors?.primary) {
        root.style.setProperty("--color-primary", colors?.primary);
        root.style.setProperty("--color-primary-light", shadeColor(colors?.primary, 0.9));
      }
      if (colors?.secondary) {
        root.style.setProperty("--color-secondary", colors?.secondary);
        root.style.setProperty("--color-secondary-light", shadeColor(colors?.secondary, 0.9));
      }
      if (colors?.illustrations) {
        root.style.setProperty("--color-illustrations", colors?.illustrations);
        root.style.setProperty("--color-illustrations-dark", shadeColor(colors?.illustrations, -0.3));
      }
      if (colors?.headings) {
        root.style.setProperty("--color-headings", colors?.headings);
      }
      if (colors?.backButtonIcon) {
        root.style.setProperty("--color-back-button-icon", colors?.backButtonIcon);
      }
      if (colors?.backButton) {
        root.style.setProperty("--color-back-button", colors?.backButton);
      }
      if (colors?.backButtonBorder) {
        root.style.setProperty("--color-back-button-border", colors?.backButtonBorder);
      }
      if (colors?.tipsIcon) {
        root.style.setProperty("--color-tips-icon", colors?.tipsIcon);
      }
      if (colors?.tipsBackground) {
        root.style.setProperty("--color-tips-background", colors?.tipsBackground);
      }
      if (colors?.tipsText) {
        root.style.setProperty("--color-tips-text", colors?.tipsText);
      }
      if (colors?.progressbar) {
        root.style.setProperty("--color-progressbar", colors?.progressbar);
      }
      (window as any).less?.modifyVars({
        "@color-primary": colors?.primary,
        "@color-secondary": colors?.secondary,
      });
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't change theme colors.",
      });
    }
  };

  const fontUrlFromName = (name = "") =>
    name &&
    `https://fonts.googleapis.com/css2?family=${name.trim().replace(" ", "+")}:wght@400;500;600;700&display=swap`;

  const changeFont = (name: string, source?: string) => {
    localStorage.setItem("fontFamily", JSON.stringify({ name, source }));

    root.style.setProperty("--font-family", name);

    if (fontSourceElementRef.current) {
      document.head.removeChild(fontSourceElementRef.current);
    }

    const linkElement = document.createElement("link");

    linkElement.rel = "stylesheet";
    linkElement.href = source ? source : fontUrlFromName(name);

    document.head.appendChild(linkElement);

    fontSourceElementRef.current = linkElement;
  };

  const setMonochromeTheme = (theme: MonochromeTheme) => {
    localStorage.setItem("monochromeTheme", theme);
    _setMonochromeTheme(theme);

    if (theme === "monochrome") {
      setHighContrastTheme("light");
      appliesMonochromeColor();
    } else {
      appliesTemplateColors();
    }
  };

  const setHighContrastTheme = (theme: HighContrastTheme) => {
    localStorage.setItem("highContrastTheme", theme);
    _setHighContrastTheme(theme);

    if (theme === "contrast") {
      setMonochromeTheme("light");

      const contrastThemeLink = document.createElement("link");

      contrastThemeLink.rel = "stylesheet";
      contrastThemeLink.type = "text/css";
      contrastThemeLink.href = "styles/themes/contrast.css";
      contrastThemeLink.id = "contrast-theme-css";

      head.appendChild(contrastThemeLink);
    } else {
      appliesTemplateColors();
    }
  };

  const setFontSize = (size: string) => {
    _setFontSize(size);
    localStorage.setItem("fontSize", size);

    const fontWeights = size === defaultFontSize ? "400px" : "900px";

    root.style.setProperty("--font-weight", fontWeights);
    root.style.setProperty("--font-size", size);
  };

  const toggleHighContrastTheme = useCallback(() => {
    if (highContrastTheme === "contrast") {
      setHighContrastTheme("light");
    } else {
      setHighContrastTheme("contrast");
    }
  }, [highContrastTheme, setHighContrastTheme]);

  const toggleMonochromeTheme = useCallback(() => {
    if (monochromeTheme === "monochrome") {
      setMonochromeTheme("light");
    } else {
      setMonochromeTheme("monochrome");
    }
  }, [monochromeTheme, setMonochromeTheme]);

  const toggleFontSize = useCallback(() => {
    if (fontSize === defaultFontSize) {
      setFontSize(highFontSize);
    } else {
      setFontSize(defaultFontSize);
    }
  }, [fontSize]);

  const getFontPropertiesFromLocalStorage = (propName: string, fallback?: string) =>
    !!localStorage.getItem("fontFamily") ? JSON.parse(localStorage.getItem("fontFamily") ?? "")[propName] : fallback;

  useEffect(() => {
    const monochromeTheme = localStorage.getItem("monochromeTheme") as MonochromeTheme;
    const highContrastTheme = localStorage.getItem("highContrastTheme") as HighContrastTheme;
    const initialFontSize = localStorage.getItem("fontSize") ?? defaultFontSize;

    const fontFamilyName = getFontPropertiesFromLocalStorage("name");
    const fontFamilySource = getFontPropertiesFromLocalStorage("source");

    if (monochromeTheme === "monochrome") setMonochromeTheme(monochromeTheme);
    else if (highContrastTheme === "contrast") setHighContrastTheme(highContrastTheme);
    else {
      setHighContrastTheme("light");
      setMonochromeTheme("light");
      appliesTemplateColors();
    }

    setFontSize(initialFontSize);

    if (fontFamilyName) {
      changeFont(fontFamilyName, fontFamilySource);
    }
  }, [colors]);

  useEffect(() => {
    if (defaultFont?.name && isDefaultFontSize) {
      changeFont(defaultFont.name, defaultFont?.source);
    } else {
      changeFont("Roboto");
    }
  }, [defaultFont, isDefaultFontSize]);

  useEffect(() => {
    const favicon = template?.favicon;
    try {
      if (favicon && favicon.length) {
        Logger.console("Changing favicon:", favicon);
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.setAttribute("rel", "icon");
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.setAttribute("href", favicon);
      }
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't change theme favicon.",
      });
    }
  }, [template]);

  useEffect(() => {
    localStorage.setItem("monochromeColor", monochromeColor);
  }, [monochromeColor]);

  return {
    toggleHighContrastTheme,
    toggleMonochromeTheme,
    toggleFontSize,
    isHighContrastMode,
    isMonochromeMode,
    highContrastTheme,
    monochromeTheme,
    isDefaultFontSize,
  };
}
