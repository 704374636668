import { useEffect, useMemo } from "react";
import Bowser from "bowser";
import AccessibilityDropdown from "@components/AccessibilityDropdown";
import { OnlyMobile, Onboarding, NotFound, ProfileApproved, LinkAccessed } from "@pages/index";
import LayoutDefault from "@components/LayoutDefault";
import useTheme from "@hooks/useTheme";
import { useAnalytics } from "@contexts/Analytics";
import { getIPAddress } from "@utils/index";
import { Logger } from "@utils/logging";
import useSavedData from "@hooks/useSavedData";
import useGoogleTagManager from "@hooks/googleTagManager";
import { observer } from "mobx-react-lite";
import { isMobile } from "@utils/index";
import store from "@store/index";
import Card from "@components/Card";
import Cookies from "js-cookie";
import useTemplateData from "@hooks/useTemplateData";

let timer = performance.now();

function App() {
  const userStore = store.user;
  const generalVariablesStore = store.variables.general;

  const { isTemplateValid, templateData, onboardingStatus, oneTimeLinkAcessed } = useTemplateData();
  const {
    toggleHighContrastTheme,
    toggleMonochromeTheme,
    toggleFontSize,
    isHighContrastMode,
    isMonochromeMode,
    highContrastTheme,
    monochromeTheme,
    isDefaultFontSize,
  } = useTheme(templateData?.template);
  const {
    logAnalyticsEventInfo,
    logAnalyticsActionInfo,
    logAnalyticsStepInfo,
    analyticsActions,
    analyticsEvents,
    analyticsSteps,
    isAnalyticsInitializing,
  } = useAnalytics();
  const isFetchingTemplate = useMemo(() => isTemplateValid === undefined, [isTemplateValid]);

  const isLoading = useMemo(
    () => isFetchingTemplate || isAnalyticsInitializing,
    [isFetchingTemplate, isAnalyticsInitializing],
  );

  useGoogleTagManager(templateData?.template?.configurations?.googleTagManagerId);
  useSavedData({
    enabled: templateData?.template?.configurations?.dataReload,
    apiToken: generalVariablesStore.token,
  });

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const userAgentData = window.navigator?.userAgentData;

    const browser = Bowser.getParser(userAgent);

    const browserName = browser.getBrowserName();
    const browserVersion = browser.getBrowserVersion();

    userStore.metadata.setBrowserName(browserName);
    userStore.metadata.setBrowserVersion(browserVersion);
    userStore.metadata.setUserAgent(userAgent);

    if (userAgentData) {
      userStore.metadata.setUserAgentData(userAgentData);
    }
  }, []);

  useEffect(() => {
    const lastTimeAcessed = Cookies.get("lastTimeAcessed");
    const lastTimeAcessedStep = Cookies.get("lastTimeAcessedStep");

    if (lastTimeAcessed) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - lastTimeAcessed;

      if (timeDifference < 60000) {
        logAnalyticsActionInfo(analyticsActions.REFRESH_PAGE, {}, lastTimeAcessedStep);
      }
    }
  }, [logAnalyticsActionInfo]);

  useEffect(() => {
    const logInitialData = async () => {
      if (!isFetchingTemplate) {
        try {
          logAnalyticsStepInfo(analyticsSteps.STEP_NEW_ACCESS);
          logAnalyticsEventInfo(analyticsEvents.NEW_ACCESS, {
            ip: await getIPAddress(),
            timeMs: performance.now() - timer,
          });
        } catch (err) {
          Logger.error(err, "Error to log initial data");
        }
      }
    };

    logInitialData();
  }, [isFetchingTemplate]);

  const accessibilityDropdownProps = {
    isHighContrastMode,
    isMonochromeMode,
    isDefaultFontSize,
    toggleHighContrastTheme,
    toggleMonochromeTheme,
    toggleFontSize,
  };

  if (templateData?.template?.mobileOnly && !isMobile()) {
    return (
      <>
        <div className="absolute top-2 sm:top-5 right-2 sm:right-5 flex flex-row items-center justify-center">
          <AccessibilityDropdown {...accessibilityDropdownProps} />
        </div>
        <OnlyMobile />
      </>
    );
  }

  return (
    <LayoutDefault
      accessibilityDropdownProps={accessibilityDropdownProps}
      highContrastTheme={highContrastTheme}
      monochromeTheme={monochromeTheme}
      showLoaderLogo={isLoading}
      showAccessibilityDropdown={!isLoading}
      showHelpButtonLink={!isLoading}
      clientOptions={templateData?.template}
      isLoading={isLoading}
    >
      {isLoading ? (
        <Card isFetching={isLoading} />
      ) : (
        <>
          {(!isTemplateValid || (!!onboardingStatus && !["PENDING", "COMPLETED"].includes(onboardingStatus))) && (
            <NotFound />
          )}
          {oneTimeLinkAcessed ? (
            <div className="relative flex-grow overflow-y-auto overflow-x-hidden">
              <LinkAccessed />
            </div>
          ) : onboardingStatus === "PENDING" ? (
            <Onboarding />
          ) : (
            onboardingStatus === "COMPLETED" && (
              <div className="relative flex-grow overflow-y-auto overflow-x-hidden">
                <ProfileApproved />
              </div>
            )
          )}
        </>
      )}
    </LayoutDefault>
  );
}

export default observer(App);
