import { useEffect, useState } from "react";
import { Form, Radio, RadioChangeEvent } from "antd";
import { useTranslation } from "react-i18next";

import Card from "@components/Card";
import TextMessageFeedback from "@components/TextMessageFeedback";
import { useAnalytics } from "@contexts/Analytics";

import { hasRule, rules } from "@utils/rules";
import formMessagesValidations from "@utils/formErrors";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import useTemplateData from "@hooks/useTemplateData";
import { QSABehavior } from "types";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

interface Props {
  name: string;
  manual?: boolean;
  heading?: string;
  subheading?: string;
  numberHeading?: string;
  numberSubheading?: string;
  contactHeading?: string;
  contactSubheading?: string;
  contactInfoHeading?: string;
  contactInfoSubheading?: string;
  contactInfoIllustration?: string;
  flow?: string[];
  fromEmployee?: boolean;
  behavior?: QSABehavior;
  disableManualInsertOption?: boolean;
}

const QSA = (props: Props) => {
  const { t } = useTranslation();

  const [shouldValidate, setShouldValidate] = useState(false);

  const { templateData } = useTemplateData();

  const {
    companyQsaSelected,
    companyQsa,
    setCompanyQsaSelected,
    setPartnerOrigin,
    partnerOrigin,
    selectedQsa,
    setCompanyQsaInfo,
    setNameForReferenceCompany,
  } = store.variables.company;
  const { goForward, goBackward, addStep, haveStep, index, removeStep, setSelectedFlow } = store.navigation;

  const { logAnalyticsStepInfo, analyticsSteps, logAnalyticsActionInfo, analyticsActions } = useAnalytics();

  const fromEmployee = props.behavior ? props.behavior === "employee" : props.fromEmployee;

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_QSA);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    if (props.manual || fromEmployee) {
      customGoForward({ disabled: false });
    }
  }, []);

  const customGoBackward = () => {
    setCompanyQsaSelected(null);
    goBackward();
  };

  const customGoForward = ({ disabled }: { disabled: boolean }) => {
    if (disabled) {
      setShouldValidate(true);
      return;
    }

    removeStep("QSA_CONTACT_INFO");
    removeStep("QSA_CONTACT");

    const isManual = partnerOrigin === "manual" || props.manual;
    if (isManual && !haveStep("QSA_NUMBER")) {
      addStep(
        {
          name: "QSA_NUMBER",
          props: {
            name: "QSA_NUMBER",
            fromEmployee,
            heading: props.numberHeading,
            subheading: props.numberSubheading,
            flow: props.flow,
            contactHeading: props.contactHeading,
            contactSubheading: props.contactSubheading,
            contactInfoHeading: props.contactInfoHeading,
            contactInfoSubheading: props.contactInfoSubheading,
            contactInfoIllustration: props.contactInfoIllustration,
          },
        },
        index + 1,
      );
    } else {
      if (partnerOrigin === "data" && haveStep("QSA_NUMBER")) {
        removeStep("QSA_NUMBER");
      }

      const templateRules = templateData?.template?.rules || [];

      if (
        hasRule(rules.SET_FLOW_WHEN_USER_IS_A_ADMINISTRATOR_PARTNER.id, templateRules) &&
        !props.manual &&
        !fromEmployee &&
        companyQsa.length
      ) {
        const rule = templateRules.find((rule) => rule.id === rules.SET_FLOW_WHEN_USER_IS_A_ADMINISTRATOR_PARTNER.id);
        if (!!rule) {
          if (selectedQsa?.isLegalRepresentative) {
            setSelectedFlow(rule.flow);
          } else {
            setSelectedFlow(rule.else_flow);
          }
        }
      }

      const ruleAdministratorPartnerDontNeedInformPartnersDataActive =
        hasRule(rules.ADMINISTRATOR_PARTNER_DONT_NEED_INFORM_PARTNERS_DATA.id, templateRules) &&
        selectedQsa?.isLegalRepresentative;
      const partnerBehaviorWithOneCompanyQsa = !fromEmployee && companyQsa.length === 1;
      const onePartnerBehavior =
        ruleAdministratorPartnerDontNeedInformPartnersDataActive || partnerBehaviorWithOneCompanyQsa;

      if (onePartnerBehavior && !fromEmployee) {
        setCompanyQsaInfo([
          {
            main: true,
            cpf: selectedQsa?.cpf ?? "",
            name: selectedQsa?.name ?? "",
            email: selectedQsa?.email ?? "",
            phone: selectedQsa?.phone ?? "",
          },
        ]);
      }

      if (!onePartnerBehavior || fromEmployee) {
        addStep(
          {
            name: "QSA_CONTACT_INFO",
            props: {
              name: "QSA_CONTACT_INFO",
              heading: props.contactInfoHeading,
              subheading: props.contactInfoSubheading,
              flow: props.flow,
              illustration: props.contactInfoIllustration,
            },
          },
          index + 1,
        );

        let addedSteps = 0;
        companyQsa.forEach((qsa, i) => {
          if (i !== companyQsaSelected) {
            addedSteps++;
            addStep(
              {
                name: "QSA_CONTACT",
                props: {
                  name: `QSA_CONTACT-${addedSteps}`,
                  heading: props.contactHeading,
                  subheading: props.contactSubheading,
                  qsaIndex: i,
                  flow: props.flow,
                },
              },
              index + 1 + addedSteps,
            );
          }
        });
      }
    }

    if (props.manual || !companyQsa.length || fromEmployee) {
      removeStep("QSA");
    } else {
      goForward();
    }
  };

  const handleQsaSelectChange = (event: RadioChangeEvent) => {
    logAnalyticsActionInfo(analyticsActions.SELECT_QSA, {}, props.name);

    const index = event.target.value;

    if (index === companyQsa.length) {
      setPartnerOrigin("manual");
    } else {
      setPartnerOrigin("data");
    }

    setNameForReferenceCompany(companyQsa?.[index]?.name);
    setCompanyQsaSelected(index);
  };

  if (props.manual || !companyQsa) return null;

  return (
    <Card>
      <Card.Body className="qsa-step">
        <Card.Heading text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.qsa.heading`, "Quem é você?")} />
        <Card.Subheading
          text={
            props.subheading ?? t(`${I18N_BASE_PATH}.uniqueSteps.qsa.subheading`, "Selecione seu nome na lista abaixo.")
          }
        />
        <Form layout="vertical">
          <Radio.Group className="w-full" value={companyQsaSelected} onChange={handleQsaSelectChange}>
            {companyQsa.map((person, index) => (
              <QSAOption
                key={`QSA_${index}`}
                isLegalRepresentative={person?.isLegalRepresentative}
                value={index}
                label={person.name}
                selected={companyQsaSelected === index}
              />
            ))}
            {props?.disableManualInsertOption ? (
              <></>
            ) : (
              <QSAOption
                value={companyQsa.length}
                key={`QSA_${companyQsa.length}`}
                label={t("general.label.nonePartner", "Nenhum (inserir manualmente)")}
                selected={companyQsaSelected === companyQsa.length}
              />
            )}
          </Radio.Group>
          <TextMessageFeedback
            isVisible={!companyQsaSelected && companyQsaSelected !== 0 && shouldValidate}
            messages={{
              required: { type: "alert", text: t(formMessagesValidations.required.alert) },
            }}
            validations={{ required: !companyQsaSelected && companyQsaSelected !== 0 && shouldValidate }}
          />
        </Form>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!companyQsaSelected && companyQsaSelected !== 0}
        goBackward={customGoBackward}
        goForward={customGoForward}
      />
    </Card>
  );
};

interface QSAOptionProps {
  isLegalRepresentative?: boolean;
  selected: boolean;
  label: string;
  value: number;
}

const QSAOption = ({ isLegalRepresentative, selected, label, value }: QSAOptionProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col justify-center px-4 mb-2 cursor-pointer w-full h-11 transition-all border rounded-md ${
        selected ? "company-qsa-partner-selected bg-primary-light border-primary" : "border-gray-300"
      }`}
      data-testid={`qsa-partner-${value}`}
    >
      <Radio value={value}>{label}</Radio>
      {isLegalRepresentative && (
        <>
          <span className="text-gray-400 text-xs ml-6">
            {t("general.label.legalRepresentative", "Sócio-administrador")}
          </span>
        </>
      )}
    </div>
  );
};

export default observer(QSA);
