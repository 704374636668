import { useEffect, useMemo, useState } from "react";
import { Form, Select } from "antd";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { FormInput, FormSelect } from "@components/Form";
import { useAnalytics } from "@contexts/Analytics";
import { Regex } from "@utils/formatting";
import useZipCodeFormatter from "@hooks/formatters/useZipCodeFormatter";
import formMessagesValidations from "@utils/formErrors";
import useEvents from "@hooks/useEvents";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import i18n from "../../i18n";

const { Option } = Select;

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

const BusinessAddress = (props) => {
  const { t } = useTranslation();
  const { zipCodeMask } = useZipCodeFormatter();

  const [shouldValidate, setShouldValidate] = useState(false);

  const { logAnalyticsActionInfo, analyticsActions, logAnalyticsStepInfo, analyticsSteps } = useAnalytics();
  const { emitEvent, error: zipCodeFailed, isLoading: isFetching } = useEvents();

  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const { isImmutableVariable, company: companyStore } = store.variables;
  const { goForward } = store.navigation;

  const isBRAddress = useMemo(
    () => companyStore.companyCountry === "BR" || i18n.language === "pt-BR",
    [companyStore.companyCountry],
  );

  useEffect(() => {
    if (companyStore.companyAddressZipCode && isBRAddress) {
      if (
        !companyStore.companyAddressState ||
        !companyStore.companyAddressCity ||
        !companyStore.companyAddressStreet ||
        !companyStore.companyAddressNeighborhood ||
        !companyStore.companyAddressNumber
      ) {
        fetchZipCode();
      }
    }
  }, [companyStore.companyAddressZipCode]);

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_BUSINESS_ADDRESS);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    if (companyStore.companyAddressStateUf) {
      emitEvent({
        code: "SELECT_COMPANY_STATE",
      });
    }
  }, [companyStore.companyAddressStateUf]);

  const fetchZipCode = async () => {
    if (companyStore.companyAddressZipCode && Regex.cep?.test(companyStore.companyAddressZipCode)) {
      startLoader({ heading: t("general.message.loader.address", "Buscando endereço...") });
      await emitEvent({
        code: "INSERT_COMPANY_CEP",
      });
      stopLoader();
    }
  };

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      setShouldValidate(true);
    } else {
      goForward();
    }
  };

  const handleBlur = (field) => {
    logAnalyticsActionInfo(
      analyticsActions.FIELD_CHANGE,
      {
        field,
      },
      props.name,
    );
  };

  const specialFields = {
    zipCodeSearching: ({ label, placeholder, icon, valid, required }) => (
      <FormInput
        id="companyAddressZipCode"
        label={label ?? t(`general.fields.zipCode.label`, "CEP")}
        placeholder={placeholder ?? t(`general.fields.zipCode.placeholder`, "Digite o seu CEP")}
        icon={<Icon icon={icon ?? "home"} />}
        mask={zipCodeMask}
        type="tel"
        required={required}
        showRequiredMessage={shouldValidate && !zipCodeFailed && !valid}
        error={
          zipCodeFailed ||
          (!!companyStore.companyAddressZipCode?.length > 0 && !Regex.cep?.test(companyStore.companyAddressZipCode))
        }
        isToValidateErrorWithinFocus={!zipCodeFailed}
        errorMessage={
          zipCodeFailed
            ? props.errors?.cepNotFound?.text ??
              t(
                `${I18N_BASE_PATH}.uniqueSteps.companyAddress.errors.cepNotFound`,
                "Ocorreu um erro ao buscar o CEP. Por favor, preencha os dados manualmente.",
              )
            : t(formMessagesValidations.valid_cep)
        }
        valid={valid}
        value={companyStore.companyAddressZipCode}
        onChange={companyStore.setCompanyAddressZipCode}
        disabled={isImmutableVariable("companyAddressZipCode")}
        onBlur={() => handleBlur("companyAddressZipCode")}
      />
    ),
    zipCodeTyping: ({ label, placeholder, icon, valid, required }) => (
      <FormInput
        cleanInitialEmptySpace
        id="companyAddressZipCode"
        label={label ?? t(`general.fields.zipCode.label`, "Rua")}
        placeholder={`${placeholder ?? t(`general.fields.zipCode.placeholder`, "Digite a rua")}`}
        icon={<Icon icon={icon ?? "map_pin"} />}
        required={required}
        showRequiredMessage={shouldValidate && !valid}
        valid={valid}
        value={companyStore.companyAddressZipCode}
        onChange={companyStore.setCompanyAddressZipCodeInternational}
        disabled={isImmutableVariable("companyAddressZipCode")}
        allowSpecialCharacteres
        onBlur={() => handleBlur("companyAddressZipCode")}
      />
    ),
    stateSelect: ({ label, placeholder, icon, valid, required }) => (
      <FormSelect
        id="companyAddressState"
        label={label ?? t(`general.fields.state.label`, "Estado")}
        placeholder={
          isFetching && !companyStore.companyAddressZipCode
            ? t(`general.fields.zipCode.waitingForZipCode`, "Aguardando CEP")
            : `${placeholder ?? t(`general.fields.state.placeholder`, "Selecione seu estado")}`
        }
        showSearch
        allowClear
        required={required}
        valid={valid}
        showRequiredMessage={shouldValidate && !valid}
        prefixIcon={icon ?? "home"}
        onChange={companyStore.setCompanyAddressState}
        value={companyStore.companyAddressState}
        disabled={isImmutableVariable("companyAddressState")}
        onBlur={() => handleBlur("companyAddressState")}
      >
        {companyStore.companyStatesList.map((state) => (
          <Option key={state.name}>{state.name}</Option>
        ))}
      </FormSelect>
    ),
    stateTyping: ({ label, placeholder, icon, valid, required }) => (
      <FormInput
        cleanInitialEmptySpace
        id="companyAddressState"
        label={label ?? t(`general.fields.state.label`, "Estado")}
        placeholder={placeholder ?? t(`general.fields.state.placeholder`, "Selecione seu estado")}
        icon={<Icon icon={icon ?? "map_pin"} />}
        required={required}
        valid={valid}
        showRequiredMessage={shouldValidate && !valid}
        onChange={companyStore.setCompanyAddressStateInternational}
        disabled={isImmutableVariable("companyAddressState")}
        onBlur={() => handleBlur("companyAddressState")}
        value={companyStore.companyAddressState}
      />
    ),
    citySelect: ({ label, placeholder, icon, valid, required }) => (
      <FormSelect
        id="companyAddressCity"
        label={label ?? t(`general.fields.city.label`, "Cidade")}
        placeholder={
          isFetching && !companyStore.companyAddressZipCode
            ? t(`general.fields.zipCode.waitingForZipCode`, "Aguardando CEP")
            : `${placeholder ?? t(`general.fields.city.placeholder`, "Selecione sua cidade")}`
        }
        showSearch
        allowClear
        valid={valid}
        required={required}
        showRequiredMessage={shouldValidate && !valid}
        prefixIcon={icon ?? "home"}
        value={companyStore.companyAddressCity}
        onChange={companyStore.setCompanyAddressCity}
        disabled={isImmutableVariable("companyAddressCity")}
        onBlur={() => handleBlur("companyAddressCity")}
      >
        {companyStore.companyCitiesList.map((city) => (
          <Option key={city.name}>{city.name}</Option>
        ))}
      </FormSelect>
    ),
    cityTyping: ({ label, placeholder, icon, valid, required }) => (
      <FormInput
        cleanInitialEmptySpace
        id="companyAddressCity"
        label={label ?? t(`general.fields.city.label`, "Cidade")}
        placeholder={placeholder ?? t(`general.fields.city.placeholder`, "Selecione sua cidade")}
        icon={<Icon icon={icon ?? "map_pin"} />}
        required={required}
        valid={valid}
        showRequiredMessage={shouldValidate && !valid}
        value={companyStore.companyAddressCity}
        onChange={companyStore.setCompanyAddressCityInternational}
        disabled={isImmutableVariable("companyAddressCity")}
        onBlur={() => handleBlur("companyAddressCity")}
      />
    ),
  };

  const allFields = {
    zipCode: {
      get validity() {
        return ({ required }) =>
          isBRAddress
            ? !!Regex.cep?.test(companyStore.companyAddressZipCode)
            : !!required && !!companyStore.companyAddressZipCode;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          const ComponentRendering = isBRAddress
            ? specialFields.zipCodeSearching({ label, placeholder, icon, valid, required })
            : specialFields.zipCodeTyping({ label, placeholder, icon, valid, required });
          return ComponentRendering;
        };
      },
    },
    state: {
      get validity() {
        return ({ required }) => !!companyStore.companyAddressState || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          const ComponentRendering = isBRAddress
            ? specialFields.stateSelect({ label, placeholder, icon, valid, required })
            : specialFields.stateTyping({ label, placeholder, icon, valid, required });

          return ComponentRendering;
        };
      },
    },
    city: {
      get validity() {
        return ({ required }) => !!companyStore.companyAddressCity || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          const ComponentRendering = isBRAddress
            ? specialFields.citySelect({ label, placeholder, icon, valid, required })
            : specialFields.cityTyping({ label, placeholder, icon, valid, required });
          return ComponentRendering;
        };
      },
    },
    street: {
      get validity() {
        return ({ required }) => !!companyStore.companyAddressStreet || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return (
            <FormInput
              cleanInitialEmptySpace
              id="companyAddressStreet"
              label={label ?? t(`general.fields.street.label`, "Rua")}
              placeholder={
                isFetching && !companyStore.companyAddressZipCode
                  ? t(`general.fields.zipCode.waitingForZipCode`, "Aguardando CEP")
                  : `${placeholder ?? t(`general.fields.street.placeholder`, "Digite a rua")}`
              }
              icon={<Icon icon={icon ?? "home"} />}
              required={required}
              showRequiredMessage={shouldValidate && !valid}
              valid={valid}
              value={companyStore.companyAddressStreet}
              onChange={companyStore.setCompanyAddressStreet}
              disabled={isImmutableVariable("companyAddressStreet")}
              allowSpecialCharacteres
              onBlur={() => handleBlur("companyAddressStreet")}
            />
          );
        };
      },
    },
    neighborhood: {
      get validity() {
        return ({ required }) => !!companyStore.companyAddressNeighborhood || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return (
            <FormInput
              id="companyAddressNeighborhood"
              label={label ?? t(`general.fields.neighborhood.label`, "Bairro")}
              placeholder={
                isFetching && !companyStore.companyAddressZipCode
                  ? t(`general.fields.zipCode.waitingForZipCode`, "Aguardando CEP")
                  : `${placeholder ?? t(`general.fields.neighborhood.placeholder`, "Digite o bairro")}`
              }
              icon={<Icon icon={icon ?? "home"} />}
              required={required}
              showRequiredMessage={shouldValidate && !valid}
              valid={valid}
              value={companyStore.companyAddressNeighborhood}
              onChange={companyStore.setCompanyAddressNeighborhood}
              disabled={isImmutableVariable("companyAddressNeighborhood")}
              onBlur={() => handleBlur("companyAddressNeighborhood")}
            />
          );
        };
      },
    },
    number: {
      get validity() {
        return ({ required }) => !!companyStore.companyAddressNumber || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return (
            <FormInput
              id="companyAddressNumber"
              label={label ?? t(`general.fields.number.label`, "Número")}
              placeholder={placeholder ?? t(`general.fields.number.placeholder`, "Digite o número")}
              icon={<Icon icon={icon ?? "home"} />}
              type="tel"
              required={required}
              showRequiredMessage={shouldValidate && !valid}
              valid={valid}
              value={companyStore.companyAddressNumber}
              onChange={companyStore.setCompanyAddressNumber}
              disabled={isImmutableVariable("companyAddressNumber")}
              onBlur={() => handleBlur("companyAddressNumber")}
            />
          );
        };
      },
    },
    complement: {
      get validity() {
        return ({ required }) => (required ? !!companyStore.companyAddressComplement : true);
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return (
            <FormInput
              id="companyAddressComplement"
              label={label ?? t(`general.fields.complement.label`, "Complemento")}
              placeholder={placeholder ?? t(`general.fields.complement.placeholder`, "Digite o complemento (opcional)")}
              icon={<Icon icon={icon ?? "home"} />}
              valid={valid}
              value={companyStore.companyAddressComplement}
              onChange={companyStore.setCompanyAddressComplement}
              disabled={isImmutableVariable("companyAddressComplement")}
              allowSpecialCharacteres
              onBlur={() => handleBlur("companyAddressComplement")}
            />
          );
        };
      },
    },
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.companyAddress.heading`, "Endereço da empresa")}
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(`${I18N_BASE_PATH}.uniqueSteps.companyAddress.subheading`, "Informe o endereço físico da empresa.")
          }
        />
        <Form layout="vertical" requiredMark="optional">
          {props?.fields?.map(({ field, icon, label, placeholder, required }, index) => ({
            ...allFields[field].component({
              icon,
              label,
              placeholder,
              required,
            }),
            key: index,
          }))}
        </Form>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!props.fields?.every(({ field, required }) => allFields[field].validity({ required }))}
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(BusinessAddress);
