import { useEffect, useMemo } from "react";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";
import { illustrations } from "@utils/illustrations";
import Button from "@components/Button";
import Card from "@components/Card";
import { useAnalytics } from "@contexts/Analytics";
import useRudderAnalytics from "@contexts/RudderAnalytics/useRudderAnalytics";
import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";
import useGetCameraAccess from "./hooks/useGetCameraAccess";
import { observer } from "mobx-react-lite";
import store from "@store/index";

const I18N_BASE_PATH = "src.pages.onboarding.steps.cameraAccess";

const CameraAccess = (props) => {
  const { t, i18n } = useTranslation();

  const userStore = store.user;

  const {
    logAnalyticsStepInfo,
    logAnalyticsActionInfo,
    logAnalyticsEventInfo,
    analyticsActions,
    analyticsSteps,
    analyticsEvents,
  } = useAnalytics();
  const { setRudderTrackData, RudderStackEvents, RudderStackPermissionUpdateStatus } = useRudderAnalytics();

  const { getPermissions, gettingPermissions, permissionsGranted, permissionsRefused, permissionsError } =
    useGetCameraAccess();

  const permissionDefaultText = useMemo(() => {
    return props.buttonPermissionText || t(`${I18N_BASE_PATH}.hooks.permissionDefaultText`, "Habilitar acesso");
  }, [props.buttonPermissionText, i18n?.language]);

  const permissionGrantedText = useMemo(() => {
    return props.feedback?.textSuccess || t(`${I18N_BASE_PATH}.hooks.permissionGrantedText`, "Acesso concedido");
  }, [props.feedback?.textSuccess, i18n?.language]);

  const permissionRefusedText = useMemo(() => {
    return props.feedback?.textError || t(`${I18N_BASE_PATH}.hooks.permissionRefusedText`, "Acesso negado");
  }, [props.feedback?.textError, i18n?.language]);

  const permissionButtonType = useMemo(() => {
    if (!props.buttonPermissionText || !props.feedback) {
      return permissionsRefused ? "danger" : "secondary";
    } else {
      return permissionsRefused || permissionsGranted ? "default" : "secondary";
    }
  }, [props.buttonPermissionText, permissionsRefused, permissionsGranted]);

  useEffect(() => {
    if (props.shouldGetUserLocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          setRudderTrackData(RudderStackEvents.LOCATION_PERMISSION_UPDATED, {
            action: RudderStackPermissionUpdateStatus.ALLOWED,
          });

          userStore.metadata.setLocation(location);
        },
        (error) => {
          logAnalyticsEventInfo(analyticsEvents.GEO_LOCATION_ERROR, error.message);

          if (error.code === error.PERMISSION_DENIED) {
            setRudderTrackData(RudderStackEvents.LOCATION_PERMISSION_UPDATED, {
              action: RudderStackPermissionUpdateStatus.DENIED,
            });
          } else {
            setRudderTrackData(RudderStackEvents.LOCATION_PERMISSION_UPDATED, {
              action: RudderStackPermissionUpdateStatus.DISMISSED,
            });
          }
        },
      );
    }
  }, [props.shouldGetUserLocation]);

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_CAMERA_ACCESS);
  }, [logAnalyticsStepInfo]);

  const handleGetPermissions = () => {
    logAnalyticsActionInfo(analyticsActions.GET_CAMERA_PERMISSIONS, {}, props.name);
    getPermissions();
  };

  return (
    <Card>
      <Card.Body center>
        <Card.Illustration
          alt={t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.CAMERA]) || ""}
          src={props.illustration ?? illustrations.CAMERA}
        />
        <Card.Heading text={props.heading ?? t(`${I18N_BASE_PATH}.heading`, "Acesso à câmera")} />
        <Card.Subheading
          text={props.subheading ?? t(`${I18N_BASE_PATH}.subheading`, "É necessário permitir acesso à sua câmera.")}
        />
        <Button
          data-testid="camera-access-button"
          type={permissionButtonType}
          loading={gettingPermissions}
          outlined={props.feedback?.border === false ? false : permissionsGranted || permissionsRefused}
          falseDisabled={permissionsGranted || permissionsRefused}
          icon={permissionsGranted ? <Icon icon="check_d" /> : permissionsRefused ? <Icon icon="alert-triangle" /> : ""}
          onClick={handleGetPermissions}
          className={
            (props.buttonPermissionText || props.feedback) && permissionsGranted
              ? "!text-secondary"
              : permissionsRefused && "!text-red-500"
          }
        >
          {permissionsGranted
            ? permissionGrantedText
            : permissionsRefused
            ? permissionRefusedText
            : permissionDefaultText}
        </Button>
        {permissionsError && (
          <Card.Text
            className="mt-4"
            small
            text={
              props.errors?.couldNotGetPermissions?.text ??
              t(
                `${I18N_BASE_PATH}.errors.couldNotGetPermissions`,
                "Ocorreu um erro ao obter as permissões de câmera. Por favor, refaça o Onboarding. Caso o problema persistir, entre em contato com o suporte.",
              )
            }
          />
        )}
        {!permissionsError && permissionsRefused && (
          <Card.Text
            className="mt-4"
            small
            text={
              props.errors?.permissionsRefused?.text ??
              t(
                `${I18N_BASE_PATH}.errors.permissionsRefused`,
                "Por favor, acesse as configurações do seu navegador para permitir o acesso à câmera para este site.",
              )
            }
          />
        )}
      </Card.Body>
      <Card.NavBar disabledGoForward={!permissionsGranted} />
    </Card>
  );
};

export default observer(CameraAccess);
