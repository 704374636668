import { useState } from "react";
import { Divider, Form, Radio, RadioChangeEvent } from "antd";
import { useTranslation } from "react-i18next";

import Card from "@components/Card";
import TextMessageFeedback from "@components/TextMessageFeedback";

import formMessagesValidations from "@utils/formErrors";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { QSABehavior } from "types";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

interface Props {
  name: string;
  manual?: boolean;
  heading?: string;
  subheading?: string;
  numberHeading?: string;
  numberSubheading?: string;
  contactHeading?: string;
  contactSubheading?: string;
  contactInfoHeading?: string;
  contactInfoSubheading?: string;
  contactInfoIllustration?: string;
  flow?: string[];
  disableManualInsertOption?: boolean;
  behavior?: QSABehavior;
}

const Attorney = (props: Props) => {
  const { t } = useTranslation();
  const [shouldValidate, setShouldValidate] = useState(false);

  const {
    companyRepresentativeAttorneySelected,
    companyQsa,
    setPartnerOrigin,
    partnerOrigin,
    selectedQsa,
    setCompanyQsaInfo,
    setReferenceAttorney,
    setCompanyRepresentativeAttorneySelected,
  } = store.variables.company;
  const { goForward, goBackward, addStep, haveStep, index, removeStep } = store.navigation;
  const { setPersonName, setPersonAttorney } = store.variables.person;

  const customGoBackward = () => {
    setCompanyRepresentativeAttorneySelected(null);
    goBackward();
  };

  const customGoForward = ({ disabled }: { disabled: boolean }) => {
    if (disabled) {
      setShouldValidate(true);
      return;
    }

    removeStep("QSA_CONTACT_INFO");
    removeStep("QSA_CONTACT");
    setPersonName("Procurador");

    if (partnerOrigin === "company") {
      removeStep("QSA_NUMBER");
      goForward();
      return;
    }

    const isManual = partnerOrigin === "manual";

    if (isManual && !haveStep("QSA_NUMBER")) {
      addStep(
        {
          name: "QSA_NUMBER",
          props: {
            name: "QSA_NUMBER",
            heading: props.numberHeading,
            subheading: props.numberSubheading,
            flow: props.flow,
            contactHeading: props.contactHeading,
            contactSubheading: props.contactSubheading,
            contactInfoHeading: props.contactInfoHeading,
            contactInfoSubheading: props.contactInfoSubheading,
            contactInfoIllustration: props.contactInfoIllustration,
            isAttorney: true,
          },
        },
        index + 1,
      );
    } else {
      if (partnerOrigin === "data" && haveStep("QSA_NUMBER")) {
        removeStep("QSA_NUMBER");
      }

      const partnerBehaviorWithOneCompanyQsa = companyQsa.length === 1;

      if (partnerBehaviorWithOneCompanyQsa) {
        setCompanyQsaInfo([
          {
            main: true,
            attorney: true,
            cpf: selectedQsa?.cpf ?? "",
            name: selectedQsa?.name ?? "",
            email: selectedQsa?.email ?? "",
            phone: selectedQsa?.phone ?? "",
          },
        ]);
      }

      if (!partnerBehaviorWithOneCompanyQsa) {
        addStep(
          {
            name: "QSA_CONTACT_INFO",
            props: {
              name: "QSA_CONTACT_INFO",
              heading: props.contactInfoHeading,
              subheading: props.contactInfoSubheading,
              flow: props.flow,
              illustration: props.contactInfoIllustration,
            },
          },
          index + 1,
        );

        let addedSteps = 0;
        companyQsa.forEach((qsa, i) => {
          if (i !== companyRepresentativeAttorneySelected) {
            addedSteps++;
            addStep(
              {
                name: "QSA_CONTACT",
                props: {
                  name: `QSA_CONTACT-${addedSteps}`,
                  heading: props.contactHeading,
                  subheading: props.contactSubheading,
                  qsaIndex: i,
                  flow: props.flow,
                },
              },
              index + 1 + addedSteps,
            );
          }
        });
      }
    }

    if (props.manual || !companyQsa.length) {
      removeStep("QSA");
    } else {
      goForward();
    }
  };

  const handleQsaSelectChange = (event: RadioChangeEvent) => {
    const index = event.target.value;

    if (index === companyQsa.length) {
      setPartnerOrigin("manual");
    } else if (index === companyQsa.length + 1) {
      setPartnerOrigin("company");
    } else {
      setPartnerOrigin("data");
    }

    setReferenceAttorney({ name: companyQsa?.[index]?.name, cpf: companyQsa?.[index]?.cpf });
    setCompanyRepresentativeAttorneySelected(index);
    setPersonAttorney(true);
  };

  return (
    <Card>
      <Card.Body className="qsa-step">
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.attorney.heading`, "Quem você representa?")}
        />
        <Card.Subheading
          text={
            props.subheading ?? t(`${I18N_BASE_PATH}.uniqueSteps.attorney.subheading`, "Selecione na lista abaixo.")
          }
        />
        <Form layout="vertical">
          <Radio.Group
            className="w-full"
            value={companyRepresentativeAttorneySelected}
            onChange={handleQsaSelectChange}
          >
            {companyQsa?.length > 0 ? (
              companyQsa.map((person, index) => (
                <AttorneyOption
                  key={`QSA_${index}`}
                  value={index}
                  label={person.name}
                  selected={companyRepresentativeAttorneySelected === index}
                />
              ))
            ) : (
              <Card.Text
                className="flex flex-col justify-center px-4 mb-2 cursor-pointer w-full h-11 transition-all border rounded-md border-gray-300"
                small
                text={t("general.label.withoutPartner", "Não encontramos sócios na busca realizada.")}
              />
            )}
            {props?.disableManualInsertOption ? (
              <></>
            ) : (
              <AttorneyOption
                value={companyQsa.length}
                key={`QSA_${companyQsa.length}`}
                label={t("general.label.nonePartner", "Nenhum (inserir manualmente)")}
                selected={companyRepresentativeAttorneySelected === companyQsa.length}
              />
            )}
            <Divider className="mt-3 mb-3" />
            <AttorneyOption
              value={companyQsa.length + 1}
              key={`QSA_${companyQsa.length + 1}`}
              label={t("general.label.attorneyPJ", "Atuo em nome da empresa")}
              selected={companyRepresentativeAttorneySelected === companyQsa.length + 1}
            />
          </Radio.Group>
          <TextMessageFeedback
            isVisible={
              !companyRepresentativeAttorneySelected && companyRepresentativeAttorneySelected !== 0 && shouldValidate
            }
            messages={{
              required: { type: "alert", text: t(formMessagesValidations.required.alert) },
            }}
            validations={{
              required:
                !companyRepresentativeAttorneySelected && companyRepresentativeAttorneySelected !== 0 && shouldValidate,
            }}
          />
        </Form>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!companyRepresentativeAttorneySelected && companyRepresentativeAttorneySelected !== 0}
        goBackward={customGoBackward}
        goForward={customGoForward}
      />
    </Card>
  );
};

interface AttorneyOptionProps {
  selected: boolean;
  label: string;
  value: number;
}

const AttorneyOption = ({ selected, label, value }: AttorneyOptionProps) => {
  return (
    <div
      className={`flex flex-col justify-center px-4 mb-2 cursor-pointer w-full h-11 transition-all border rounded-md ${
        selected ? "company-qsa-partner-selected bg-primary-light border-primary" : "border-gray-300"
      }`}
      data-testid={`qsa-partner-${value}`}
    >
      <Radio value={value}>{label}</Radio>
    </div>
  );
};

export default observer(Attorney);
