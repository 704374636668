import { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

import Card from "@components/Card";
import { FormSelect, FormCheckbox } from "@components/Form";
import useValidate from "@hooks/useValidate";
import useIpApi from "./hooks/useIpApi";

import countries from "@utils/countries";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { useAnalytics } from "@contexts/Analytics";

const { Option } = Select;

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps.uniqueSteps";

const DocumentIssuedOrigin = (props) => {
  const [shouldValidate, setShouldValidate] = useState(false);

  const { t } = useTranslation();
  const { getUserLocation } = useIpApi();
  const { analyticsActions, logAnalyticsActionInfo, logAnalyticsStepInfo, analyticsSteps } = useAnalytics();

  const { document: documentStore, isImmutableVariable } = store.variables;
  const { goForward } = store.navigation;

  const handleChangeCountry = (value) => {
    logAnalyticsActionInfo(
      analyticsActions.SELECT_COUNTRY,
      {
        country: value,
      },
      props.name,
    );
    documentStore.setDocumentIssuedCountry(value);
  };

  const allFields = {
    countries: {
      validity: useValidate(documentStore.documentIssuedCountry),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormSelect
            label={label ?? t("general.label.country", "País")}
            placeholder={placeholder ?? t("general.label.documentOrigin", "País de emissão do seu documento")}
            showSearch
            allowClear
            required
            showRequiredMessage={shouldValidate && !this.validity}
            prefixIcon={icon !== "" && icon ? icon : "worldIcon"}
            value={documentStore.documentIssuedCountry}
            onChange={handleChangeCountry}
            disabled={isImmutableVariable("documentIssuedCountry")}
            validity={this.validity}
            optionFilterProp="children"
          >
            {countries?.map(({ code, name }) => (
              <Option key={code}>{t(`countries.${code}`, name)}</Option>
            ))}
          </FormSelect>
        );
      },
    },
  };

  useEffect(() => {
    const findUserCountryAutomatically = async () => {
      const properties = await getUserLocation();

      documentStore.setDocumentIssuedCountry(properties.country);
    };

    if (!documentStore.documentIssuedCountry) findUserCountryAutomatically();
  }, []);

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_DOCUMENT_ISSUED_COUNTRY);
  }, [logAnalyticsStepInfo]);

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      setShouldValidate(true);
    } else {
      goForward();
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={
            props.heading ??
            t(`${I18N_BASE_PATH}.documentIssuedCountry.heading`, "Qual o país de origem do seu documento?")
          }
        />
        <Form layout="vertical" requiredMark="optional" className={"pt-5"}>
          {props.fields?.map((item, index) =>
            item.field && allFields[item.field]
              ? { ...allFields[item.field].component({ ...item }), key: index }
              : null,
          )}
          <div className="flex-col justify-center justify-items-center w-full mt-5">
            <h4 className="!mb-3 mt-4 text-gray-500 text-sm font-bold">{props?.checkDescription}</h4>
            <div className="flex w-full space-x-8 mt-4">
              {props?.documentSides?.map((documentSide) => (
                <FormCheckbox
                  id={documentSide?.id}
                  multiple={false}
                  required
                  showRequiredMessage={shouldValidate && documentStore.doesDocumentHaveFrontAndBackSides === null}
                  validity={documentStore.doesDocumentHaveFrontAndBackSides !== null}
                  key={documentSide?.id}
                  value={documentStore.doesDocumentHaveFrontAndBackSides === documentSide?.value}
                  onChange={() => documentStore.setDoesDocumentHaveFrontAndBackSides(documentSide?.value)}
                >
                  <label>{documentSide?.label}</label>
                </FormCheckbox>
              ))}
            </div>
          </div>
        </Form>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={
          !documentStore.documentIssuedCountry ||
          (props?.documentSides?.length > 0 && documentStore.doesDocumentHaveFrontAndBackSides === null)
        }
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(DocumentIssuedOrigin);
