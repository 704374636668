import { useEffect } from "react";
import run from "./scripts/run";

type GTMId = string;

export default function useGoogleTagManager(id?: GTMId) {
  const buildIframeTag = (gtmId: string) => {
    const iframeTag = document.createElement("iframe");
    const scr = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;

    iframeTag.src = scr;
    iframeTag.style.width = "0";
    iframeTag.style.height = "0";
    iframeTag.style.display = "none";
    iframeTag.style.visibility = "hidden";

    return iframeTag;
  };

  const addNoscriptTagInsideHTMLBody = (gtmId: GTMId, elementId: string) => {
    if (gtmId) {
      const noscriptTag = document.getElementById(elementId);

      if (noscriptTag && !noscriptTag?.firstChild) {
        const iframeTag = buildIframeTag(gtmId);
        noscriptTag.appendChild(iframeTag);
      }
    }
  };

  useEffect(() => {
    if (id) {
      run(window, document, "script", "dataLayer", id);
      addNoscriptTagInsideHTMLBody(id, "google-tag-manager-noscript-customer");
    }
  }, [!!id]);
}
