import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { useAnalytics } from "@contexts/Analytics";
import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";
import { observer } from "mobx-react-lite";
import { illustrations } from "@utils/illustrations";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

const Custom = (props) => {
  const { t } = useTranslation();

  const { logAnalyticsStepInfo, analyticsSteps } = useAnalytics();

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_CUSTOM);
  }, [logAnalyticsStepInfo]);

  return (
    <Card>
      <Card.Body center>
        <Card.Illustration
          alt={t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.PEOPLE]) || ""}
          src={props.illustration ?? illustrations.PEOPLE}
        />
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.duplicableSteps.custom.heading`, "Etapa customizada")}
        />
        <Card.Subheading
          text={props.subheading ?? t(`${I18N_BASE_PATH}.duplicableSteps.custom.subheading`, "Modifique como quiser.")}
        />
      </Card.Body>
      <Card.NavBar />
    </Card>
  );
};

export default observer(Custom);
