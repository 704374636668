import { Icon } from "@combateafraude/react";
import { FormInput, InputTypes } from "@components/Form";
import { Mask, Regex } from "@utils/formatting";
import { useTranslation } from "react-i18next";
import formMessagesValidations from "@utils/formErrors";
import { validateCNPJ } from "@utils/validations";
import { removeSpecialChars } from "@utils/formatting";

const CNPJ = ({
  id,
  icon,
  label,
  placeholder,
  required = false,
  onChange,
  goForwardButtonWasClicked,
  value,
  onBlur,
  validity,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormInput
        id={id}
        className="!m-0"
        type="tel"
        mask={Mask.cnpj}
        inputType={InputTypes.NUMBER}
        label={label}
        placeholder={placeholder}
        error={(!Regex.cnpj.test(value) || !validateCNPJ(removeSpecialChars(value))) && !!value?.length > 0}
        isToValidateErrorWithinFocus
        errorMessage={t(formMessagesValidations.valid_cnpj)}
        icon={!!icon && <Icon icon={icon} />}
        value={value}
        onChange={onChange}
        required={required}
        valid={validity}
        onBlur={onBlur}
        showRequiredMessage={required && goForwardButtonWasClicked && !validity}
      />
    </>
  );
};

export default CNPJ;
