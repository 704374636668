import { useEffect, useState } from "react";
import { Form, Radio } from "antd";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import TextMessageFeedback from "@components/TextMessageFeedback";
import { useAnalytics } from "@contexts/Analytics";
import formMessagesValidations from "@utils/formErrors";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import type { Qsa } from "@store/variables/company";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

interface Props {
  name: string;
  heading?: string;
  subheading?: string;
}

const Partner = (props: Props) => {
  const [partnerSelected, setPartnerSelected] = useState<Qsa>();
  const [shouldValidate, setShouldValidate] = useState(false);

  const { t } = useTranslation();
  const { logAnalyticsStepInfo, analyticsSteps } = useAnalytics();

  const { companyQsa, setCompanyQsaInfo, setNameForReferenceCompany } = store.variables.company;
  const { goForward } = store.navigation;

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_PARTNER);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    if (!!companyQsa.length && !!partnerSelected) {
      setCompanyQsaInfo([
        {
          main: true,
          name: partnerSelected.name,
          cpf: partnerSelected.cpf,
        },
      ]);
      setNameForReferenceCompany(partnerSelected.name);
    }
  }, [partnerSelected]);

  const customGoForward = ({ disabled }: { disabled: boolean }) => {
    if (disabled) {
      setShouldValidate(true);
    } else {
      goForward();
    }
  };

  return (
    <Card>
      <Card.Body className="qsa-step">
        <Card.Heading text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.partner.heading`, "Quem é você?")} />
        <Card.Subheading
          text={
            props.subheading ??
            t(`${I18N_BASE_PATH}.uniqueSteps.partner.subheading`, "Selecione seu nome na lista abaixo.")
          }
        />
        <Form layout="vertical">
          <Radio.Group className="w-full" value={partnerSelected}>
            {companyQsa.map((person, index) => (
              <div
                className={`flex flex-col justify-center px-4 mb-2 cursor-pointer w-full h-11 transition-all border rounded-md ${
                  partnerSelected?.name === person?.name
                    ? "company-qsa-partner-selected bg-primary-light border-primary"
                    : "border-gray-300"
                }`}
                onClick={() => setPartnerSelected(person)}
                key={`QSA_${index}`}
              >
                <Radio value={person}>{person.name}</Radio>
                {person?.isLegalRepresentative && (
                  <>
                    <span className="text-gray-400 text-xs ml-6">
                      {t("general.label.legalRepresentative", "Sócio-administrador")}
                    </span>
                  </>
                )}
              </div>
            ))}
          </Radio.Group>
          <TextMessageFeedback
            isVisible={!partnerSelected && shouldValidate}
            messages={{
              required: { type: "alert", text: t(formMessagesValidations.required.alert) },
            }}
            validations={{
              required: !partnerSelected && shouldValidate,
            }}
          />
        </Form>
      </Card.Body>
      <Card.NavBar disabledGoForward={!partnerSelected} goForward={customGoForward} />
    </Card>
  );
};

export default observer(Partner);
