import { useEffect } from "react";
import { Icon } from "@combateafraude/react";
import Card from "@components/Card";
import { useAnalytics } from "@contexts/Analytics";
import { isUrlOrIconIllustration } from "@utils/illustrations";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import useTemplateData from "@hooks/useTemplateData";

const InstructionItem = ({ icon, text }) => {
  const { templateData } = useTemplateData();

  return (
    <div className="flex items-center mb-5">
      <div className="relative p-3 mr-5 min-w-13 rounded-full overflow-hidden text-secondary">
        <span
          className={`tips-icon block absolute -m-3 w-full h-full z-0 ${
            templateData?.template?.colors.tipsBackground ? "bg-tipsbackground" : "bg-secondary opacity-10"
          }`}
        />
        {isUrlOrIconIllustration(icon) ? (
          <img src={icon} alt={text} className={`relative z-20 w-7 h-7`} />
        ) : (
          <Icon
            className={`relative z-20 ${templateData?.template?.colors.tipsIcon ? "text-tipsicon" : ""}`}
            icon={icon}
            size="lg"
          />
        )}
      </div>
      <Card.Text
        className={`!mb-0 ${templateData?.template?.colors.tipsText ? `!text-tipstext` : ``}`}
        small
        text={text}
      />
    </div>
  );
};

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

const Instructions = (props) => {
  const { t } = useTranslation();
  const { logAnalyticsStepInfo, analyticsSteps } = useAnalytics();

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_INSTRUCTIONS);
  }, [logAnalyticsStepInfo]);

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.duplicableSteps.instructions.heading`, "Prepare sua verificação")}
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(`${I18N_BASE_PATH}.duplicableSteps.instructions.subheading`, "Siga atentamente as dicas abaixo.")
          }
        />
        {props.tips?.map((tip, i) => (
          <InstructionItem key={i} icon={tip.icon} text={tip.text} />
        ))}
      </Card.Body>
      <Card.NavBar />
    </Card>
  );
};

export default observer(Instructions);
