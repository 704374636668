export async function requestAction(action, onboardingId, attributes) {
  const url = `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/onboardings/${onboardingId}/actions/${action}`;
  const data = {
    attributes: {
      ...attributes,
    },
  };

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
  };

  return await fetch(url, requestOptions);
}
