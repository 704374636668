import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import Card from "@components/Card";
import { FormCheckbox } from "@components/Form";
import OptionButton from "@components/OptionButton";
import { useAnalytics } from "@contexts/Analytics";
import { Regex, applyVariable } from "@utils/formatting";
import defaultDocumentValues from "@utils/defaultDocumentTypes";
import { isValidUrl } from "@utils/";
import { replaceVariables } from "@utils/markdown";
import useDocumentDetector from "@steps/DD/hooks/useDocumentDetector";

import { observer } from "mobx-react-lite";
import store from "@store/index";
import { buildTermIdGenerator } from "@store/user";
import useTemplateData from "@hooks/useTemplateData";

const I18N_BASE_PATH = "src.pages.onboarding.steps";

const DocumentType = (props) => {
  const { t, i18n } = useTranslation();

  const termPrefix = "DocumentType";
  const getTermId = buildTermIdGenerator(termPrefix);

  const { templateData } = useTemplateData();

  const {
    logAnalyticsStepInfo,
    logAnalyticsEventInfo,
    logAnalyticsActionInfo,
    analyticsSteps,
    analyticsEvents,
    analyticsActions,
  } = useAnalytics();
  const documentDetector = useDocumentDetector();
  const { goForward, setSelectedFlow } = store.navigation;

  const [modalTerm, setModalTerm] = useState();
  const [haveSmartChoice, setHaveSmartChoice] = useState(
    templateData.template.steps.find(
      (step) => step.name.split("-")[0] === "SMART_CHOICE" && step?.ruleType === "DOCUMENT_TYPE",
    ) !== undefined,
  );

  const documentStore = store.variables.document;
  const userStore = store.user;

  const terms = useMemo(() => {
    if (!props.terms && !props.useTerms) return [];

    if (props.useTerms) {
      return [
        {
          name: t(`${I18N_BASE_PATH}.documentType.hooks.defaultTerm.name`, "Termos de Uso e Políticas de Privacidade"),
          label: t(
            `${I18N_BASE_PATH}.documentType.hooks.defaultTerm.label`,
            `Sim, quero verificar minha identidade. Eu li e aceito os [termos de uso e políticas de privacidade]${
              isValidUrl(props.useTerms) ? `(${props.useTerms})` : `<${props.useTerms}>`
            }.`,
            { termType: isValidUrl(props.useTerms) ? `(${props.useTerms})` : `<${props.useTerms}>` },
          ),
          required: true,
        },
      ];
    }

    return props.terms;
  }, [props.terms, props.useTerms]);

  const areAllRequiredTermsAccepted =
    Object.keys(userStore.acceptedTerms).filter((k) => k.includes(termPrefix) && userStore.acceptedTerms[k].required)
      .length === terms.filter((term) => term.required).length;

  const onAcceptedTermsChange = (term, index, checked) => {
    notification.close("NOTIFICATION_ACCEPT_TERMS");

    const termId = getTermId(term, index);

    if (checked) {
      userStore.addAcceptanceTerm({ ...term, id: termId });
    } else {
      userStore.removeAcceptanceTerm(termId);
    }

    logAnalyticsActionInfo(
      analyticsActions.TERM_ACCEPTED,
      {
        term: termId,
      },
      props.name,
    );
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_DOCUMENT_TYPE);
    if (haveSmartChoice) setSelectedFlow(documentStore?.selectedDocumentType?.toUpperCase());
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    setHaveSmartChoice(
      templateData.template.steps.find(
        (step) => step.name.split("-")[0] === "SMART_CHOICE" && step?.ruleType === "DOCUMENT_TYPE",
      ) !== undefined,
    );

    if (!!documentStore.selectedDocumentType)
      logAnalyticsEventInfo(analyticsEvents.DOCUMENT_TYPE_SELECTED, {
        type: documentStore.selectedDocumentType,
      });
  }, [documentStore.selectedDocumentType]);

  const onDocumentTypeChange = (doc) => {
    documentDetector.resetResult();
    documentStore.setSelectedDocumentType(doc.id);
    if (haveSmartChoice) {
      documentStore.setSelectedSendDocumentType();
      setSelectedFlow(doc.type.toUpperCase());
    }
    logAnalyticsActionInfo(
      analyticsActions.DOCUMENT_TYPE_SELECTED,
      {
        type: doc.id,
      },
      props.name,
    );
  };

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      if (!areAllRequiredTermsAccepted) {
        notification.open({
          key: "NOTIFICATION_ACCEPT_TERMS",
          message:
            props.errors?.didntAcceptTerms?.text ??
            t(
              `${I18N_BASE_PATH}.allSteps.uniqueSteps.documentType.errors.didntAcceptTerms`,
              "Para continuar, leia e aceite todos os termos",
            ),
          duration: 8,
          style: {
            marginTop: "calc(100vh - 250px)",
          },
          onClick: () => {
            notification.close("NOTIFICATION_ACCEPT_TERMS");
          },
        });
      }
    } else {
      notification.close("NOTIFICATION_ACCEPT_TERMS");
      goForward();
    }
  };

  const transformTermTextIntoElement = useCallback(
    ({ term, index }) => {
      // removing spaces and break lines
      const termLabelFormatted = term?.label?.replace(/[\r\n]+/gm, "")?.replace(/\s/g, "");

      if (term?.label?.match(Regex.urlLink)) {
        return applyVariable(term?.label, true, store.variables.flatten());
      } else if (termLabelFormatted?.match(Regex.modalLink)) {
        const termLabelSplitted = term?.label?.split("]<");
        const termTextModalFormatted = termLabelSplitted?.[1]
          ?.slice(0, -1)
          .replace(/[\r\n]+/gm, "")
          ?.replace(/\s/g, "");

        // reformatting the term label that contains the text, the link and the text to the modal
        const termLabelSplittedFormatted = `${term?.label?.split("]<")?.[0]}]<${termTextModalFormatted}>`;

        return replaceVariables(
          applyVariable(termLabelSplittedFormatted, true, store.variables.flatten()),
          /\[(.*?)>/gm,
          (_, _i) => {
            const textModal = termLabelSplitted[1].slice(0, -1);
            const textTerm = termLabelSplitted[0]?.split("[")[1];

            const termFormatted = {
              name: term?.name,
              text: textModal,
            };

            return (
              <a
                key={`TERM_${index}_A_${_i}`}
                className="underline text-secondary"
                {...{
                  href: "#",
                  onClick: (e) => {
                    e.preventDefault();
                    setModalTerm(termFormatted);
                  },
                }}
              >
                {textTerm}
              </a>
            );
          },
        );
      } else {
        return term?.label;
      }
    },
    [replaceVariables, Regex, setModalTerm],
  );

  const [documentTypes, setDocumentTypes] = useState();
  useEffect(() => {
    if (templateData?.template && Array.isArray(templateData?.template.documentTypes)) {
      // Support for old documentTypes format (string)
      setDocumentTypes(
        templateData.template.documentTypes?.map((doc) =>
          typeof doc === "string" ? { ...defaultDocumentValues[doc] } : { ...defaultDocumentValues[doc.type], ...doc },
        ),
      );
    }
  }, [templateData, i18n?.language]);

  return (
    <Card>
      <Card.Body>
        {!!modalTerm ? (
          <>
            <Card.Heading text={modalTerm.name} />
            <Card.Text small text={modalTerm.text} />
          </>
        ) : (
          <>
            <Card.Heading
              text={
                props.heading ?? t(`${I18N_BASE_PATH}.allSteps.uniqueSteps.documentType.heading`, "Qual seu documento?")
              }
            />
            <Card.Subheading
              text={
                props.subheading ??
                t(
                  `${I18N_BASE_PATH}.allSteps.uniqueSteps.documentType.subheading`,
                  "Você precisa tê-lo em mãos para prosseguir com o cadastro.",
                )
              }
            />
            <div className="flex flex-col space-y-3 mb-4">
              {!documentTypes?.filter((doc) => doc.id)?.length && (
                <Card.Text
                  small
                  className="!mb-0 text-center"
                  text={t(
                    `${I18N_BASE_PATH}.documentType.components.noDocumentTypesAvailable`,
                    "Nenhum tipo de documento disponível.",
                  )}
                />
              )}
              {documentTypes
                ?.filter((doc) => doc.id)
                ?.map((doc, i) => (
                  <OptionButton
                    key={`DOCUMENT_TYPE_${doc.id}_${i}`}
                    icon={doc.icon}
                    title={t(doc.title, doc.title)}
                    description={t(doc.description, doc.description)}
                    onClick={() => onDocumentTypeChange(doc)}
                    selected={documentStore.selectedDocumentType === doc.id}
                    data-testid={`DOCUMENT_TYPE_${doc.id}`}
                  />
                ))}
            </div>
            <div className="flex flex-col space-y-3">
              {terms?.map((term, i) => (
                <FormCheckbox
                  id={`TERM_${i}`}
                  key={`TERM_${i}`}
                  value={!!userStore.acceptedTerms[getTermId(term, i)]}
                  className={"flex items-center p-0.5 shadow-md animate-pulse-custom"}
                  onChange={(checked) => onAcceptedTermsChange(term, i, checked)}
                >
                  <label htmlFor={`TERM_${i}`}>{transformTermTextIntoElement({ term, index: i })}</label>
                </FormCheckbox>
              ))}
            </div>
          </>
        )}
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!documentStore.selectedDocumentType || (terms?.length && !areAllRequiredTermsAccepted)}
        goForward={customGoForward}
        onClose={!!modalTerm ? () => setModalTerm(null) : undefined}
      />
    </Card>
  );
};

export default observer(DocumentType);
