import { useEffect, useRef, useState } from "react";
import { Logger } from "@utils/logging";
import { observer } from "mobx-react-lite";
import useIProovLiveness from "./hooks/useIProovLiveness";
import store from "@store/index";
import { useAnalytics } from "@contexts/Analytics";
import { useTranslation } from "react-i18next";
import { parseStep } from "@utils/onboarding";
import { Props as LivenessIProovPreviewProps } from "./LivenessIProovPreview";
import Card from "@components/Card";
import { illustrations } from "@utils/illustrations";
import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";
import { Skeleton } from "antd";

export interface Props {
  name: string;
  heading?: string;
  subheading?: string;
  illustration?: string;
  takeSelfieButtonText?: string;

  preview: boolean;
  previewHeading?: string;
  previewSubheading?: string;
  previewRetryText?: string;
}

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps.uniqueSteps.livenessIProov";

const LivenessIProov = (props: Props) => {
  const [error, setError] = useState(false);
  const [sdkLoading, setSdkLoading] = useState(true);
  const [disableGoForward, setDisableGoForward] = useState(true);
  const passiveFaceLiveness = useIProovLiveness();

  const containerRef = useRef(null);
  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const { goForward, addStep, index, haveStep } = store.navigation;

  const { t, i18n } = useTranslation();
  const { logAnalyticsStepInfo, analyticsSteps, analyticsEvents, logAnalyticsEventInfo } = useAnalytics();

  const customGoForward = () => {
    const livenessIProovPreview = passiveFaceLiveness.getImagePreview();

    if (!livenessIProovPreview) {
      setError(true);
    } else {
      setDisableGoForward(false);
      goForward();
    }
  };

  const capture = (buttonText?: string) => {
    document.addEventListener("sdk-button-ready", () => {
      setSdkLoading(false);
    });
    document.addEventListener(
      "permission",
      () => {
        setSdkLoading(false);
      },
      { once: true },
    );
    Logger.console("PFLStep capture");
    passiveFaceLiveness.capture(containerRef.current, {
      onStartLoader: (loaderMessage: string) => {
        startLoader({
          heading: t(loaderMessage, "Processando sua foto, aguarde um momento..."),
        });
      },
      onGetResults: (result: any) => {
        logAnalyticsEventInfo(analyticsEvents.LIVENESS_IPROOV_INFO, {
          result,
          step: "onGetResults",
        });

        if (!result.isAlive || !result.imageUrl) {
          setError(true);
          setSdkLoading(true);
          capture(t("general.message.loader.buttonText", "Tentar novamente"));
        } else {
          setError(false);
          stopLoader();
          customGoForward();
        }
      },
      onInitialize: () => {
        stopLoader();
      },
      onError: () => {
        stopLoader();
        setError(true);
      },
      onRetry: () => {
        setSdkLoading(true);
        capture();
      },
      buttonText,
      language: i18n?.language ?? "pt_BR",
    });
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_LIVENESS_IPROOV);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    const stepIndex = parseStep(props.name)[1];

    if (props.preview && !haveStep("LIVENESS_IPROOV_PREVIEW")) {
      addStep(
        {
          name: `LIVENESS_IPROOV_PREVIEW-${stepIndex}`,
          props: {
            name: `LIVENESS_IPROOV_PREVIEW-${stepIndex}`,
            heading: props.previewHeading,
            retryText: props.previewRetryText,
            subheading: props.previewSubheading,
          } as LivenessIProovPreviewProps,
        },
        index + 1,
      );
    }

    capture();
  }, []);

  return (
    <Card>
      <Card.Body center>
        <Card.Illustration
          alt={
            error
              ? t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.CIRCLE_ERROR])
              : t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.PHONE])
          }
          src={error ? illustrations.CIRCLE_ERROR : props.illustration ?? illustrations.PHONE}
        />
        <Card.Heading
          text={
            error
              ? t(`${I18N_BASE_PATH}.error.title`, "Oops, algo deu errado")
              : props.heading ?? t(`${I18N_BASE_PATH}.heading`, "Hora da selfie!")
          }
        />
        <Card.Subheading
          text={
            error
              ? t(`${I18N_BASE_PATH}.error.description`, "Não foi possível realizar sua captura, tente novamente.")
              : props.subheading ??
                t(`${I18N_BASE_PATH}.subheading`, "Clique no botão abaixo quando estiver pronto para tirar a selfie.")
          }
        />
        <div ref={containerRef} id="pfl-container" className="h-full w-full flex items-center justify-center" />
        {sdkLoading && <Skeleton.Button active={true} shape="round" size="large" />}
      </Card.Body>
      <Card.NavBar disabledGoForward={disableGoForward} />
    </Card>
  );
};

export default observer(LivenessIProov);
