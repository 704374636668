/**
 * Object keys: field names from international OCR
 *
 * Object values: field names that the "Data Confirmation" step expects
 */
const textFieldsMapping: { [key: string]: string } = {
  name: "personName",
  cpf: "personCpf",
  documentNumber: "personRg",
  birthDate: "personBirthDate",
  motherName: "personMotherName",
  fatherName: "personFatherName",
  issuingDate: "personRgIssueDate",
  issuingAuthority: "personRgIssuingAuthority",
  issuingCountry: "personRgIssueState",
};

/**
 * Converts international OCR field names to field names the "Data Confirmation" step expects, in order to correctly show the OCR data on the screen
 */
export function mapInternationalTextFields(internationalTextFieldsData: { [key: string]: string }) {
  let mappedTextFields: {
    [key: string]: string;
  } = {};

  for (const key of Object.keys(internationalTextFieldsData)) {
    if (Object.keys(textFieldsMapping).includes(key)) {
      mappedTextFields[textFieldsMapping[key]] = internationalTextFieldsData[key];
    }
  }

  return mappedTextFields;
}
