const IPROOV_EVENTS = [
  "passed",
  "permission",
  "permission_denied",
  "error",
  "failed",
  "canceled",
  "multiple_cameras",
  "no_camera",
  "unsupported",
];

// Events that are not being used:
// "connecting",
// "connected",
// "progress",
// "ready",
// "started",
// "streaming",
// "streamed",

export default IPROOV_EVENTS;
