import { useState, useEffect, useMemo } from "react";
import { Form, Select } from "antd";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { FormInput, FormSelect, InputTypes } from "@components/Form";
import useValidate from "@hooks/useValidate";
import { Mask, Regex } from "@utils/formatting";
import { validateEmail, validateCPF } from "@utils/validations";
import { removeSpecialChars } from "@utils/formatting";
import usePhoneFormatter from "@hooks/formatters/usePhoneFormatter";
import useDateFormatter from "@hooks/formatters/useDateFormatter";
import { brazilUFs } from "@utils/constants";
import formMessagesValidations from "@utils/formErrors";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { useAnalytics } from "@contexts/Analytics";
import { countriesWithPersonId } from "./utils";
import useTemplateData from "@hooks/useTemplateData";

const { Option } = Select;

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

/**
 *
 * Data confirmation step, the fields are filled with data coming from the OCR
 * of the document, if the field is filled in, the user only needs to confirm, otherwise
 * must fill in the field
 *
 */

const DataConfirmation = (props) => {
  const { t } = useTranslation();
  const [shouldValidate, setShouldValidate] = useState(false);
  const { templateData } = useTemplateData();
  const { dateValidation, verifyOcrMaskWithTemplateMask } = useDateFormatter();
  const { phoneValidation } = usePhoneFormatter();

  const { analyticsActions, logAnalyticsActionInfo, logAnalyticsStepInfo, analyticsSteps } = useAnalytics();

  const { isImmutableVariable, person: personStore, document: documentStore, general: generalStore } = store.variables;

  const { goForward } = store.navigation;

  const handleBlur = (field) => {
    logAnalyticsActionInfo(
      analyticsActions.FIELD_CHANGE,
      {
        field,
      },
      props.name,
    );
  };
  useEffect(() => {
    if (!templateData?.template?.configurations?.immutableAttributes) {
      personStore.setPersonBirthDate(verifyOcrMaskWithTemplateMask(personStore.personBirthDate));
    }
  }, [verifyOcrMaskWithTemplateMask]);

  const fieldsFiltered = useMemo(() => {
    return props.fields?.filter((f) =>
      countriesWithPersonId[documentStore?.documentIssuedCountry] ? f?.field : f?.field !== "person_id",
    );
  }, []);

  const allFields = {
    name: {
      validity: useValidate(personStore.personName),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.name.label", "Nome")}
            id={id}
            placeholder={placeholder ?? t("general.fields.name.placeholder", "Digite seu nome")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            uppercase
            required
            showRequiredMessage={shouldValidate && !this.validity}
            value={personStore.personName}
            disabled={isImmutableVariable("personName")}
            onChange={personStore.setPersonName}
            valid={this.validity}
            onBlur={() => handleBlur(id)}
            textOnly
          />
        );
      },
    },
    cpf: {
      validity: useValidate(
        personStore.personCpf,
        (cpf) => Regex.cpf.test(cpf) && validateCPF(removeSpecialChars(cpf)),
      ),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.cpf.label", "CPF")}
            id={id}
            placeholder={placeholder ?? t("general.fields.cpf.placeholder", "Digite seu CPF")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            mask={Mask.cpf}
            type="tel"
            required
            showRequiredMessage={shouldValidate && !personStore.personCpf?.length > 0 && !this.validity}
            error={
              (!Regex.cpf.test(personStore.personCpf) || !validateCPF(removeSpecialChars(personStore.personCpf))) &&
              !!personStore.personCpf.length > 0
            }
            isToValidateErrorWithinFocus
            errorMessage={t(formMessagesValidations.valid_cpf)}
            value={personStore.personCpf}
            disabled={isImmutableVariable("personCpf")}
            onChange={personStore.setPersonCpf}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
          />
        );
      },
    },
    rg: {
      validity: useValidate(personStore.personRg),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.rg.label", "RG")}
            id={id}
            placeholder={placeholder ?? t("general.fields.rg.placeholder", "Digite seu RG")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            maxLength="10"
            type="text"
            required
            showRequiredMessage={shouldValidate && !this.validity}
            value={personStore.personRg}
            disabled={isImmutableVariable("personRg")}
            onChange={personStore.setPersonRg}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
          />
        );
      },
    },
    birthdate: {
      validity: useValidate(personStore.personBirthDate, (date) => dateValidation(date, { isBirthDate: true })),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.birthdate.label", "Data de nascimento")}
            id={id}
            placeholder={placeholder ?? t("general.fields.birthdate.placeholder", "Digite sua data de nascimento")}
            icon={icon !== "" && <Icon icon={icon || "calendar"} />}
            required
            inputType={InputTypes.DATE}
            showRequiredMessage={shouldValidate && !personStore.personBirthDate.length > 0 && !this.validity}
            error={!this.validity && !!personStore.personBirthDate.length > 0}
            isToValidateErrorWithinFocus
            errorMessage={t(formMessagesValidations.valid_birth_date)}
            type="tel"
            value={personStore.personBirthDate}
            disabled={isImmutableVariable("personBirthDate")}
            onChange={personStore.setPersonBirthDate}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
          />
        );
      },
    },
    mother_name: {
      validity: useValidate(personStore.personMotherName),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.motherName.label", "Nome da mãe")}
            id={id}
            placeholder={placeholder ?? t("general.fields.motherName.placeholder", "Digite o nome da sua mãe")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            uppercase
            required
            showRequiredMessage={shouldValidate && !this.validity}
            value={personStore.personMotherName}
            disabled={isImmutableVariable("personMotherName")}
            onChange={personStore.setPersonMotherName}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
            textOnly
          />
        );
      },
    },
    father_name: {
      validity: useValidate(personStore.personFatherName),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.fatherName.label", "Nome do pai")}
            id={id}
            placeholder={placeholder ?? t("general.fields.fatherName.placeholder", "Digite o nome do seu pai")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            uppercase
            required
            showRequiredMessage={shouldValidate && !this.validity}
            value={personStore.personFatherName}
            disabled={isImmutableVariable("personFatherName")}
            onChange={personStore.setPersonFatherName}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
            textOnly
          />
        );
      },
    },
    email: {
      validity: useValidate(personStore.personEmail, validateEmail),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.email.label", "E-mail")}
            id={id}
            placeholder={placeholder ?? t("general.fields.email.placeholder", "Digite o seu email")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            type="email"
            required
            showRequiredMessage={shouldValidate && !personStore?.personEmail?.length > 0 && !this.validity}
            error={!this.validity && !!personStore?.personEmail?.length > 0}
            isToValidateErrorWithinFocus
            errorMessage={t(formMessagesValidations.valid_email)}
            value={personStore.personEmail}
            disabled={isImmutableVariable("personEmail")}
            onChange={personStore.setPersonEmail}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
          />
        );
      },
    },
    phone_number: {
      validity: useValidate(personStore.personPhoneNumber, phoneValidation),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.phoneNumber.label", "Telefone")}
            id={id}
            placeholder={placeholder ?? t("general.fields.phoneNumber.placeholder", "Digite o seu telefone")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            type="tel"
            inputType={InputTypes.PHONE}
            required
            showRequiredMessage={shouldValidate && !personStore.personPhoneNumber.length > 0 && !this.validity}
            error={!!personStore.personPhoneNumber.length > 0 && !this.validity}
            isToValidateErrorWithinFocus
            errorMessage={t(formMessagesValidations.valid_phone_number)}
            value={personStore.personPhoneNumber}
            disabled={isImmutableVariable("personPhoneNumber")}
            onChange={personStore.setPersonPhoneNumber}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
          />
        );
      },
    },
    rg_issue_date: {
      validity: useValidate(personStore.personRgIssueDate, dateValidation),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.rgIssueDate.label", "Data de emissão (RG)")}
            id={id}
            placeholder={
              placeholder ?? t("general.fields.rgIssueDate.placeholder", "Digite a data de emissão do seu RG")
            }
            icon={icon !== "" && <Icon icon={icon || "calendar"} />}
            required
            showRequiredMessage={shouldValidate && !personStore.personRgIssueDate.length > 0 && !this.validity}
            inputType={InputTypes.DATE}
            error={!this.validity && !!personStore.personRgIssueDate.length > 0}
            isToValidateErrorWithinFocus
            errorMessage={t(formMessagesValidations.valid_default_date)}
            type="tel"
            value={personStore.personRgIssueDate}
            disabled={isImmutableVariable("personRgIssueDate")}
            onChange={personStore.setPersonRgIssueDate}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
          />
        );
      },
    },
    rg_issuing_authority: {
      validity: useValidate(personStore.personRgIssuingAuthority),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            uppercase
            label={label ?? t("general.fields.rgIssuingAuthority.label", "Órgão emissor (RG)")}
            id={id}
            placeholder={
              placeholder ?? t("general.fields.rgIssuingAuthority.placeholder", "Digite o órgão emissor do seu RG")
            }
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            required
            showRequiredMessage={shouldValidate && !this.validity}
            value={personStore.personRgIssuingAuthority}
            disabled={isImmutableVariable("personRgIssuingAuthority")}
            onChange={personStore.setPersonRgIssuingAuthority}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
            allowSpecialCharacteres
          />
        );
      },
    },
    rg_issue_state: {
      validity: useValidate(personStore.personRgIssueState),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormSelect
            label={label ?? t("general.fields.rgIssueState.label", "Estado emissor (RG)")}
            id={id}
            placeholder={
              placeholder ?? t("general.fields.rgIssueState.placeholder", "Digite o estado emissor do seu RG")
            }
            prefixIcon={icon !== "" && (icon || "person")}
            value={personStore.personRgIssueState}
            disabled={isImmutableVariable("personRgIssueState")}
            onChange={personStore.setPersonRgIssueState}
            onBlur={() => handleBlur(id)}
            showSearch
            required
            showRequiredMessage={shouldValidate && !this.validity}
          >
            {brazilUFs.map((state) => (
              <Option key={state}>{state}</Option>
            ))}
          </FormSelect>
        );
      },
    },
    nit: {
      validity: useValidate(personStore.personNit),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.nit.label", "NIT")}
            id={id}
            placeholder={placeholder ?? t("general.fields.nit.placeholder", "Digite seu NIT")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            mask={Mask.nit}
            type="tel"
            required
            showRequiredMessage={shouldValidate && !personStore.personNit.length > 0 && !this.validity}
            error={!this.validity && !!personStore.personNit.length > 0}
            errorMessage={t(formMessagesValidations.valid_nit)}
            value={personStore.personNit}
            disabled={isImmutableVariable("personNit")}
            onChange={personStore.setPersonNit}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
          />
        );
      },
    },
    licensePlate: {
      validity: useValidate(personStore.personLicensePlate, (licensePlate) => Regex.licensePlate.test(licensePlate)),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.licensePlate.label", "Placa")}
            placeholder={placeholder ?? t("general.fields.licensePlate.placeholder", "Digite a placa do seu carro")}
            icon={icon !== "" && <Icon icon={icon || "car_plate"} />}
            uppercase
            mask={Mask.licensePlate}
            required
            showRequiredMessage={shouldValidate && !personStore.personLicensePlate.length > 0 && !this.validity}
            error={!this.validity && !!personStore.personLicensePlate.length > 0}
            errorMessage={t(formMessagesValidations.valid_license_plate)}
            value={personStore.personLicensePlate}
            disabled={isImmutableVariable("personLicensePlate")}
            onChange={personStore.setPersonLicensePlate}
            onBlur={() => handleBlur(id)}
            valid={this.validity}
          />
        );
      },
    },
    person_id: {
      validity: useValidate(personStore.personId),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            label={label ?? t("general.fields.personId.label", "Identificação Pessoal")}
            id={id}
            placeholder={
              t(countriesWithPersonId[documentStore?.documentIssuedCountry]) ??
              placeholder ??
              t("general.fields.personId.placeholder", "RG, CPF, Número do seguro social, etc...")
            }
            icon={icon !== "" && <Icon className="text-primary" icon={icon ?? "person"} />}
            required
            value={personStore.personId}
            disabled={isImmutableVariable("personId")}
            onChange={personStore.setPersonId}
            onBlur={() => handleBlur(id)}
            allowSpecialCharacteres
            valid={this.validity}
          />
        );
      },
    },
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_DATA_CONFIRMATION);
  }, [logAnalyticsStepInfo]);

  const customGoForward = ({ disabled }) => {
    if (!disabled) {
      goForward();
    } else {
      setShouldValidate(true);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.dataConfirmation.heading`, "Confirme seus dados")}
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              `${I18N_BASE_PATH}.uniqueSteps.dataConfirmation.subheading`,
              "Para prosseguir confirme se os seus dados estão corretos.",
            )
          }
        />
        {/* <Card.Text className="!mb-6 font-bold" text="Dados cadastrais" /> */}
        <Form layout="vertical">
          {fieldsFiltered?.map((item, index) =>
            item.field && allFields[item.field]
              ? { ...allFields[item.field].component({ ...item, id: item.field }), key: index }
              : null,
          )}
        </Form>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!fieldsFiltered?.every(({ field }) => allFields[field].validity)}
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(DataConfirmation);
