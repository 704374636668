import Card from "@components/Card";
import { useAnalytics } from "@contexts/Analytics";
import useEvents from "@hooks/useEvents";
import store from "@store/index";
import { Logger } from "@utils/logging";
import { parseStep } from "@utils/onboarding";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props as PreviewProps } from "./DDPreview";
import useDocumentDetector, { documentSides } from "./hooks/useDocumentDetector";
import { mapInternationalTextFields } from "./utils";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps.uniqueSteps.dd";

interface Props {
  name: string;
  heading?: string;
  subheading?: string;
  preview?: boolean;
  previewPDF?: boolean;
  previewButton?: string;
  previewHeading?: string;
  previewRetryText?: string;
  previewSubheading?: string;

  errors?: {
    ddOpeningError?: {
      longDescription: string;
      description: string;
      text: string;
    };
  };
}

const DD = (props: Props) => {
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const {
    logAnalyticsStepInfo,
    analyticsSteps,
    logAnalyticsEventInfo,
    analyticsEvents,
    logAnalyticsActionInfo,
    analyticsActions,
  } = useAnalytics();
  const { emitEvent } = useEvents();
  const documentDetector = useDocumentDetector();

  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const {
    setDocumentUrlBack,
    setDocumentUrlFront,
    documentIssuedCountry,
    doesDocumentHaveFrontAndBackSides,
    textFieldsData,
    selectedSendDocumentType,
  } = store.variables.document;
  const { goForward, goBackward, index, addStep, removeStep, haveStep } = store.navigation;

  const containerRef = useRef(null);
  const [disableGoForward, setDisableGoForward] = useState(true);

  const nationalGoForward = async () => {
    const side = documentDetector.getCurrentSide();
    let frontPreview, backPreview, bothPreview;

    if (side === documentSides.front) {
      frontPreview = documentDetector.getPreview(documentSides.front, true);

      if (frontPreview) {
        setDocumentUrlFront(frontPreview);
        if (props.preview) {
          setDisableGoForward(false);
          goForward();
        } else {
          await documentDetector.changeDocumentSide({
            newSide: documentSides.back,
          });
          capture();
        }
      } else {
        capture();
      }
    }

    if (side === documentSides.back) {
      backPreview = documentDetector.getPreview(documentSides.back, true);

      if (backPreview) {
        setDocumentUrlBack(backPreview);
        if (props.preview) {
          setDisableGoForward(false);
          goForward();
        } else {
          await documentDetector.reset();
          startLoader({ heading: t("general.message.loader.processingDocument", "Processando documento...") });
          await emitEvent({
            code: "DD_CAPTURE_FINISH",
          });
          stopLoader();
          setDisableGoForward(false);
          goForward();
        }
      } else {
        capture();
      }
    }

    if (side === documentSides.both) {
      bothPreview = documentDetector.getPreview(documentSides.both, true);

      if (bothPreview) {
        setDocumentUrlFront(bothPreview);
        if (props.preview) {
          setDisableGoForward(false);
          goForward();
        } else {
          await documentDetector.reset();
          startLoader({ heading: t("general.message.loader.processingDocument", "Processando documento...") });
          await emitEvent({
            code: "DD_CAPTURE_FINISH",
          });
          stopLoader();
          setDisableGoForward(false);
          goForward();
        }
      } else {
        capture();
      }
    }
  };

  const internationalGoForward = async () => {
    Logger.console("DDStep customGoForward");
    const side = documentDetector.getCurrentSide();
    const { pdfPreview, showPdfPreview } = documentDetector.isPdfPreview();
    const frontPreview = documentDetector.getPreview(documentSides.front, true);
    const backPreview = documentDetector.getPreview(documentSides.back, true);
    let bothPreview;

    if (!doesDocumentHaveFrontAndBackSides && side === documentSides.front) {
      if (frontPreview) {
        setDocumentUrlFront(frontPreview);
        if (props.preview) {
          if (textFieldsData) {
            store.variables.updateVariables(mapInternationalTextFields(textFieldsData));
          }
          setDisableGoForward(false);
          goForward();
          return;
        }
      } else {
        capture();
      }
    }

    if (side === documentSides.both) {
      bothPreview = documentDetector.getPreview(documentDetector.getCurrentSide(), true);
    }

    if (
      ((side === documentSides.both || side === documentSides.back) && !props.preview) ||
      (pdfPreview && !showPdfPreview) ||
      (!doesDocumentHaveFrontAndBackSides && !props.preview)
    ) {
      setDocumentUrlFront(!!frontPreview ? frontPreview : bothPreview);
      setDocumentUrlBack(backPreview);
      startLoader({ heading: t("general.message.loader.processingDocument", "Processando documento...") });

      if (textFieldsData) {
        store.variables.updateVariables(mapInternationalTextFields(textFieldsData));
      } else {
        await emitEvent({
          code: "DD_CAPTURE_FINISH",
        });
      }
      stopLoader();
    }

    if (!documentDetector.isBothSideCapture()) {
      if (side === documentSides.back || !doesDocumentHaveFrontAndBackSides) {
        await documentDetector.reset();
        goForward();
        return;
      } else if (side === documentSides.front) {
        // change side to back and call capture
        await documentDetector.changeDocumentSide({
          newSide: documentSides.back,
        });
        capture();
        return;
      } else {
        // keep side as back and continue
        await documentDetector.reset();
        goForward();
      }
    }

    if (props.preview) {
      // document side only changes on preview
      goForward();
    } else {
      // no preview

      if (side === documentSides.front) {
        // change side to back and call capture
        await documentDetector.changeDocumentSide({
          newSide: documentSides.back,
        });
        capture();
      } else {
        // keep side as back and continue
        await documentDetector.reset();
        goForward();
      }
    }
  };

  const customGoForward = async () => {
    try {
      logAnalyticsEventInfo(analyticsEvents.DD_GO_FORWARD, {});

      if (documentIssuedCountry !== "BR") {
        await internationalGoForward();
      } else {
        await nationalGoForward();
      }
    } catch (error) {
      logAnalyticsEventInfo(analyticsEvents.DD_ERROR, {
        message: "DD CustomGoForward Error",
        details: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      });
    }
  };

  const customGoBackward = async () => {
    Logger.console("DDStep customGoBackward");
    const currentSide = documentDetector.getCurrentSide();

    if (documentIssuedCountry !== "BR" && currentSide === documentSides.back) {
      documentDetector.changeDocumentSide({
        newSide: documentSides.front,
      });
      capture();
      return;
    }

    if (props.preview) {
      if (currentSide === documentSides.front || currentSide === documentSides.both) {
        // keep side and results but close sdk
        // await documentDetector.reset();
        documentDetector.changeDocumentSide({
          newSide: documentSides.front,
        });
        goBackward();
      } else if (currentSide === documentSides.back) {
        // keep back results and change side to front
        await documentDetector.changeDocumentSide({
          newSide: documentSides.front,
        });
        goForward();
      }
    } else {
      // no preview

      if (currentSide === documentSides.front || currentSide === documentSides.both) {
        // keep side and results but close sdk

        await documentDetector.changeDocumentSide({
          newSide: documentSides.front,
        });
        // await documentDetector.reset();
        goBackward();
      } else {
        // keep current results and change side to front
        await documentDetector.changeDocumentSide({
          newSide: documentSides.front,
        });
        capture();
      }
    }
  };

  const capture = () => {
    setError(false);

    Logger.console("DDStep capture");
    startLoader({
      heading: t("general.message.loader.initializingDocumentDetector", "Iniciando detector de documentos..."),
    });
    documentDetector.capture(containerRef.current, {
      onGetResults: async (side: string, result: any) => {
        logAnalyticsEventInfo(analyticsEvents.DD_INFO, {
          side,
          result,
          step: "onGetResults",
        });
        if (documentIssuedCountry === "BR") {
          customGoForward();
        } else {
          await internationalOnGetResults(side, result);
        }
      },
      onInitialize: () => {
        stopLoader();
      },
      onCaptureStarted: (type: string) => {
        logAnalyticsActionInfo(analyticsActions.DD_CAPTURE_START, { type }, props.name);
      },
      onCaptureInvalid: (type: string) => {
        logAnalyticsActionInfo(analyticsActions.DD_CAPTURE_FAILED, { type }, props.name);
      },
      onError: () => {
        stopLoader();
        setError(true);
      },
    });
  };

  const internationalOnGetResults = async (side: string, result: any) => {
    if (side === documentSides.front) {
      setTimeout(
        () => {
          customGoForward();
        },
        selectedSendDocumentType === "upload" ? 0 : 3000,
      );
      return;
    }

    if (side === documentSides.back) {
      const backResult = result[documentSides.back];
      const imagesValid = backResult.imagesResult.length > 0 && backResult.assetParams.valid;

      if (!imagesValid) {
        await documentDetector.changeDocumentSide({
          newSide: documentSides.front,
        });
        setDocumentUrlFront("");
        setDocumentUrlBack("");
        documentDetector.resetResult();
        capture();
        return;
      }
    }

    customGoForward();
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_DD);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    const { showPdfPreview, pdfPreview } = documentDetector.isPdfPreview();

    if (pdfPreview && !showPdfPreview) {
      removeStep("DD_PREVIEW");
    } else {
      const stepIndex = parseStep(props.name)[1];

      if (props.preview && !haveStep("DD_PREVIEW")) {
        addStep(
          {
            name: `DD_PREVIEW-${stepIndex}`,
            props: {
              name: `DD_PREVIEW-${stepIndex}`,
              button: props.previewButton,
              heading: props.previewHeading,
              retryText: props.previewRetryText,
              subheading: props.previewSubheading,
            } as PreviewProps,
          },
          index + 1,
        );
      }
    }

    capture();

    return () => {
      if (process.env.REACT_APP_ENV === "test") {
        // SDK needs to reset to recall webcam stream when testing with cypress
        documentDetector.reset();
      }
    };
  }, []);

  return (
    <Card>
      <Card.Body className="relative">
        <div ref={containerRef} className="h-full w-full flex items-center justify-center">
          {error && (
            <Card.Text
              className="mx-10"
              text={
                props.errors?.ddOpeningError?.text ??
                t(
                  `${I18N_BASE_PATH}.errors.ddOpeningError`,
                  "Ocorreu um erro ao abrir o Detector de Documentos. Por favor, refaça o Onboarding. Caso o problema persistir, entre em contato com o suporte.",
                )
              }
            />
          )}
        </div>
      </Card.Body>
      <Card.NavBar disabledGoForward={disableGoForward} goBackward={customGoBackward} goForward={customGoForward} />
    </Card>
  );
};

export default observer(DD);
