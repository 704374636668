import { Icon } from "@combateafraude/react";
import formMessagesValidations from "@utils/formErrors";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./Phone.css";
import { useState } from "react";

const Phone = ({
  id,
  required,
  label,
  placeholder,
  onChange,
  value,
  validity,
  onBlur,
  messagesValidation = formMessagesValidations.valid_phone_number,
}) => {
  const { t } = useTranslation();
  const [hasClickedInput, setHasClickedInput] = useState(true);

  return (
    <>
      <PhoneInput
        inputProps={{
          id: id,
          "data-testid": `form-input-${id}`,
          required: required,
        }}
        country={"br"}
        specialLabel={label}
        countryCodeEditable={false}
        className={`!m-0 ${!validity && hasClickedInput && value?.length > 3 && "special-label-error"}`}
        defaultErrorMessage={t(messagesValidation)}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        isValid={value?.length > 3 ? validity : true}
        onBlur={() => {
          onBlur();
          setHasClickedInput(!hasClickedInput);
        }}
        onFocus={() => {
          setHasClickedInput(!hasClickedInput);
        }}
      />
      {!validity && hasClickedInput && value?.length > 3 && (
        <span className="text-red-400">{t(messagesValidation)}</span>
      )}
    </>
  );
};

export default observer(Phone);
