import { useEffect, useState } from "react";
import { Form } from "antd";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { FormInput, InputTypes } from "@components/Form";
import { useAnalytics } from "@contexts/Analytics";
import useValidate from "@hooks/useValidate";
import { Mask, Regex } from "@utils/formatting";
import { validateEmail, validateCPF } from "@utils/validations";
import { removeSpecialChars } from "@utils/formatting";
import formMessagesValidations from "@utils/formErrors";
import usePhoneFormatter from "@hooks/formatters/usePhoneFormatter";
import { observer } from "mobx-react-lite";
import store from "@store/index";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

const PFPFData = (props) => {
  const [shouldValidate, setShouldValidate] = useState(false);

  const { t } = useTranslation();
  const { logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions, analyticsSteps } = useAnalytics();
  const { phoneValidation } = usePhoneFormatter();

  const { pfpf: pfpfStore, isImmutableVariable } = store.variables;
  const { goForward } = store.navigation;

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_PFPF_DATA);
  }, [logAnalyticsStepInfo]);

  const handleBlur = (field) => {
    logAnalyticsActionInfo(
      analyticsActions.FIELD_CHANGE,
      {
        field,
      },
      props.name,
    );
  };

  const allFields = {
    cpf: {
      validity: useValidate(pfpfStore.PFPFCpf, (cpf) => Regex.cpf.test(cpf) && validateCPF(removeSpecialChars(cpf))),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormInput
            label={label ?? t("general.fields.qsa.cpf.label", "CPF")}
            placeholder={placeholder ?? t("general.fields.qsa.cpf.placeholder", "Digite o CPF")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            mask={Mask.cpf}
            type="tel"
            required
            showRequiredMessage={shouldValidate && !pfpfStore.PFPFCpf.length > 0 && !this.validity}
            error={
              (!Regex.cpf.test(pfpfStore.PFPFCpf) || !validateCPF(removeSpecialChars(pfpfStore.PFPFCpf))) &&
              !!pfpfStore.PFPFCpf.length > 0
            }
            isToValidateErrorWithinFocus
            errorMessage={t(formMessagesValidations.valid_cpf)}
            value={pfpfStore.PFPFCpf}
            disabled={isImmutableVariable("PFPFCpf")}
            onChange={pfpfStore.setPFPFCpf}
            valid={this.validity}
            onBlur={() => handleBlur("PFPFCpf")}
          />
        );
      },
    },
    name: {
      validity: useValidate(pfpfStore.PFPFName),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormInput
            label={label ?? t("general.fields.qsa.name.label", "Nome")}
            placeholder={placeholder ?? t("general.fields.qsa.name.placeholder", "Digite o nome")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            uppercase
            required
            showRequiredMessage={shouldValidate && !this.validity}
            value={pfpfStore.PFPFName}
            disabled={isImmutableVariable("PFPFName")}
            onChange={pfpfStore.setPFPFName}
            valid={this.validity}
            textOnly
            onBlur={() => handleBlur("PFPFName")}
          />
        );
      },
    },
    email: {
      validity: useValidate(pfpfStore.PFPFEmail, validateEmail),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormInput
            type="email"
            label={label ?? t("general.fields.qsa.email.label", "Email")}
            placeholder={placeholder ?? t("general.fields.qsa.email.placeholder", "Digite o email")}
            icon={icon !== "" && <Icon icon={icon || "mail"} />}
            required
            showRequiredMessage={shouldValidate && !pfpfStore.PFPFEmail.length > 0 && !this.validity}
            error={!this.validity && !!pfpfStore.PFPFEmail.length > 0}
            isToValidateErrorWithinFocus
            errorMessage={t(formMessagesValidations.valid_email)}
            value={pfpfStore.PFPFEmail}
            disabled={isImmutableVariable("PFPFEmail")}
            onChange={pfpfStore.setPFPFEmail}
            valid={this.validity}
            onBlur={() => handleBlur("PFPFEmail")}
          />
        );
      },
    },
    phone_number: {
      validity: useValidate(pfpfStore.PFPFPhoneNumber, phoneValidation),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormInput
            label={label ?? t("general.fields.qsa.phone.label", "Telefone")}
            placeholder={placeholder ?? t("general.fields.qsa.phone.placeholder", "Digite o telefone")}
            icon={icon !== "" && <Icon icon={icon || "contact_"} />}
            type="tel"
            inputType={InputTypes.PHONE}
            required
            showRequiredMessage={shouldValidate && !pfpfStore.PFPFPhoneNumber.length > 0 && !this.validity}
            error={!!pfpfStore.PFPFPhoneNumber.length > 0 && !this.validity}
            isToValidateErrorWithinFocus
            errorMessage={t(formMessagesValidations.valid_phone_number)}
            value={pfpfStore.PFPFPhoneNumber}
            disabled={isImmutableVariable("PFPFPhoneNumber")}
            onChange={pfpfStore.setPFPFPhoneNumber}
            valid={this.validity}
            onBlur={() => handleBlur("PFPFPhoneNumber")}
          />
        );
      },
    },
  };

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      setShouldValidate(true);
    } else {
      goForward();
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={
            props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.pf_pf_data.heading`, "Informações da pessoa relacionada")
          }
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              `${I18N_BASE_PATH}.uniqueSteps.pf_pf_data.subheading`,
              "Informe os dados da pessoa que receberá o link de onboarding.",
            )
          }
        />
        <Form layout="vertical">
          {props.fields.map((item, index) =>
            item.field && allFields[item.field]
              ? { ...allFields[item.field].component({ ...item }), key: `PF_PF_DATA_INPUT_${index}` }
              : null,
          )}
        </Form>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!props.fields.every(({ field }) => allFields[field] && allFields[field].validity)}
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(PFPFData);
