import { useMemo, useCallback } from "react";
import { format, parse, endOfToday, isValid, isBefore, isAfter, isSameDay } from "date-fns";
import { Mask } from "@utils/formatting";
import { CountryCode } from "libphonenumber-js";
import store from "@store/index";
import { Logger } from "@utils/logging";

export default function useDateFormatter() {
  const { documentIssuedCountry: country, documentDateFormat, setDocumentDateFormat } = store.variables.document;

  const dateFormat = dateFormats[country ?? "BR"] ?? "dd/MM/yyyy";

  const verifyOcrMaskWithTemplateMask = useCallback(
    (dateValue: string) => {
      Logger.console("Date format", { country, dateFormat, documentDateFormat });
      if (dateValue && documentDateFormat && dateFormat !== documentDateFormat) {
        const parsedDate = parse(dateValue, documentDateFormat, new Date());
        const formattedDateToExibition = format(parsedDate, dateFormat);
        setDocumentDateFormat(dateFormat);
        return formattedDateToExibition;
      }
      return dateValue;
    },
    [dateFormat, documentDateFormat],
  );

  const dateMask = useMemo(() => {
    if (dateFormat) {
      return format(new Date(), dateFormat).replace(/\d/g, "9");
    }
    return Mask.date;
  }, [dateFormat]);

  const dateValidation = useCallback(
    (date: string, options = { isBirthDate: false }) => {
      if (!date?.length) return false;

      const parsedDate = parse(date, dateFormat, new Date());

      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 120);
      const endDate = endOfToday();

      if (!isValid(parsedDate)) return false;
      if (!isAfter(parsedDate, startDate)) return false;

      if (options?.isBirthDate) return isBefore(parsedDate, endDate);

      return isSameDay(parsedDate, endDate) || isBefore(parsedDate, endDate);
    },
    [dateFormat],
  );

  return {
    dateValidation,
    dateMask,
    verifyOcrMaskWithTemplateMask,
  };
}

type DateFormats = Partial<Record<CountryCode, string>>;

const dateFormats: DateFormats = {
  AL: "yyyy-MM-dd",
  AE: "dd/MM/yyyy",
  AR: "dd/MM/yyyy",
  AU: "dd/MM/yyyy",
  AT: "dd.MM.yyyy",
  BE: "dd/MM/yyyy",
  BG: "yyyy-MM-dd",
  BH: "dd/MM/yyyy",
  BA: "yyyy-MM-dd",
  BY: "dd.MM.yyyy",
  BO: "dd-MM-yyyy",
  BR: "dd/MM/yyyy",
  CA: "dd/MM/yyyy",
  CH: "dd.MM.yyyy",
  CL: "dd-MM-yyyy",
  CN: "yyyy-MM-dd",
  CO: "dd/MM/yyyy",
  CR: "dd/MM/yyyy",
  CY: "dd/MM/yyyy",
  CZ: "dd.MM.yyyy",
  DE: "dd.MM.yyyy",
  DK: "dd-MM-yyyy",
  DO: "MM/dd/yyyy",
  DZ: "dd/MM/yyyy",
  EC: "dd/MM/yyyy",
  EG: "dd/MM/yyyy",
  ES: "dd/MM/yyyy",
  EE: "dd.MM.yyyy",
  FI: "dd.MM.yyyy",
  FR: "dd/MM/yyyy",
  GB: "dd/MM/yyyy",
  GR: "dd/MM/yyyy",
  GT: "dd/MM/yyyy",
  HK: "dd-MM-yyyy",
  HN: "MM-dd-yyyy",
  HR: "dd.MM.yyyy",
  HU: "yyyy.MM.dd",
  ID: "dd/MM/yyyy",
  IN: "dd/MM/yyyy",
  IE: "dd/MM/yyyy",
  IQ: "dd/MM/yyyy",
  IS: "dd.MM.yyyy",
  IL: "dd/MM/yyyy",
  IT: "dd/MM/yyyy",
  JO: "dd/MM/yyyy",
  JP: "yyyy/MM/dd",
  KR: "yyyy.MM.dd",
  KW: "dd/MM/yyyy",
  LB: "dd/MM/yyyy",
  LY: "dd/MM/yyyy",
  LT: "yyyy.MM.dd",
  LU: "dd/MM/yyyy",
  LV: "yyyy.dd.MM",
  MA: "dd/MM/yyyy",
  MX: "dd/MM/yyyy",
  MK: "dd.MM.yyyy",
  MT: "dd/MM/yyyy",
  ME: "dd.MM.yyyy",
  MY: "dd/MM/yyyy",
  NI: "MM-dd-yyyy",
  NL: "dd-MM-yyyy",
  NO: "dd.MM.yyyy",
  NZ: "dd/MM/yyyy",
  OM: "dd/MM/yyyy",
  PA: "MM/dd/yyyy",
  PE: "dd/MM/yyyy",
  PH: "MM/dd/yyyy",
  PL: "dd.MM.yyyy",
  PR: "MM-dd-yyyy",
  PT: "dd-MM-yyyy",
  PY: "dd/MM/yyyy",
  QA: "dd/MM/yyyy",
  RO: "dd.MM.yyyy",
  RU: "dd.MM.yyyy",
  SA: "dd/MM/yyyy",
  SD: "dd/MM/yyyy",
  SG: "MM/dd/yyyy",
  SV: "MM-dd-yyyy",
  RS: "dd.MM.yyyy",
  SK: "dd.MM.yyyy",
  SI: "dd.MM.yyyy",
  SE: "yyyy-MM-dd",
  SY: "dd/MM/yyyy",
  TH: "dd/MM/yyyy",
  TN: "dd/MM/yyyy",
  TR: "dd.MM.yyyy",
  TW: "yyyy/MM/dd",
  UA: "dd.MM.yyyy",
  UY: "dd/MM/yyyy",
  US: "MM/dd/yyyy",
  VE: "dd/MM/yyyy",
  VN: "dd/MM/yyyy",
  YE: "dd/MM/yyyy",
  ZA: "yyyy/MM/dd",
} as const;
