import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import usePassiveFaceLiveness from "./hooks/usePassiveFaceLiveness";
import { Props as PFLPreviewProps } from "./PFLPreview";
import { useAnalytics } from "@contexts/Analytics";
import { Logger } from "@utils/logging";
import Card from "@components/Card";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { parseStep } from "@utils/onboarding";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

interface Props {
  name: string;
  heading?: string;
  subheading?: string;
  preview: boolean;

  previewHeading?: string;
  previewSubheading?: string;
  previewRetryText?: string;

  errors?: {
    pflOpeningError?: {
      text: string;
    };
  };
}

const PFL = (props: Props) => {
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const { goBackward, goForward, addStep, index, haveStep, removeStep } = store.navigation;

  const {
    logAnalyticsStepInfo,
    analyticsSteps,
    analyticsEvents,
    logAnalyticsEventInfo,
    logAnalyticsActionInfo,
    analyticsActions,
  } = useAnalytics();
  const passiveFaceLiveness = usePassiveFaceLiveness();
  const containerRef = useRef(null);

  const customGoBackward = async () => {
    Logger.console("PFLStep customGoBackward");
    await passiveFaceLiveness.reset();
    goBackward();
  };

  const customGoForward = async ({ disabled }: { disabled: boolean }) => {
    if (!disabled) {
      Logger.console("PFLStep customGoForward");
      if (!props.preview) {
        await passiveFaceLiveness.reset();
      }
      goForward();
    }
  };

  const capture = () => {
    setError(false);
    Logger.console("PFLStep capture");
    startLoader({ heading: t("general.message.loader.initializingPassiveFaceLiveness", "Iniciando leitor facial...") });
    passiveFaceLiveness.capture(containerRef.current, {
      onGetResults: (side: string, result: object) => {
        logAnalyticsEventInfo(analyticsEvents.PFL_INFO, {
          side,
          result,
          step: "onGetResults",
        });

        customGoForward({ disabled: false });
      },
      onInitialize: () => {
        stopLoader();
        logAnalyticsActionInfo(analyticsActions.PFL_CAPTURE_START, {}, props.name);
      },
      onCaptureInvalid: (detail: string) => {
        logAnalyticsActionInfo(analyticsActions.PFL_CAPTURE_FAILED, { detail }, props.name);
      },
      onError: (message: string) => {
        if (message === "EXCEEDED_ATTEMPTS") {
          removeStep("PFL_PREVIEW");
          goForward();
        }
        stopLoader();
        setError(true);
      },
    });
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_PFL);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    const stepIndex = parseStep(props.name)[1];

    if (props.preview && !haveStep("PFL_PREVIEW")) {
      addStep(
        {
          name: `PFL_PREVIEW-${stepIndex}`,
          props: {
            name: `PFL_PREVIEW-${stepIndex}`,
            heading: props.previewHeading,
            retryText: props.previewRetryText,
            subheading: props.previewSubheading,
          } as PFLPreviewProps,
        },
        index + 1,
      );
    }

    capture();
  }, []);

  return (
    <Card>
      <Card.Body className="relative">
        <div ref={containerRef} className="h-full w-full flex items-center justify-center">
          {error && (
            <Card.Text
              className="mx-10"
              text={
                props.errors?.pflOpeningError?.text ??
                t(
                  `${I18N_BASE_PATH}.uniqueSteps.pfl.errors.pflOpeningError`,
                  "Ocorreu um erro ao abrir o Detector de Faces. Por favor, refaça o Onboarding. Caso o problema persistir, entre em contato com o suporte.",
                )
              }
            />
          )}
        </div>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!passiveFaceLiveness.hasResults()}
        goBackward={customGoBackward}
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(PFL);
