import { useState, useEffect } from "react";
import { Form, Radio, Select } from "antd";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { FormSelect } from "@components/Form";
import TextMessageFeedback from "@components/TextMessageFeedback";
import { useAnalytics } from "@contexts/Analytics";
import formMessagesValidations from "@utils/formErrors";
import store from "@store/index";
import { observer } from "mobx-react-lite";

const { Option } = Select;

const I18N_BASE_PATH = "src.pages.onboarding.steps.flowChoice";

const FlowChoice = (props) => {
  const { t } = useTranslation();

  const { analyticsSteps, logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions } = useAnalytics();

  const [shouldValidate, setShouldValidate] = useState(false);
  const [_selectedFlow, _setSelectedFlow] = useState(null);

  const { goForward, goBackward, selectedFlow, setSelectedFlow } = store.navigation;

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_FLOW_CHOICE, {
      options: props.options,
    });
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    const availableFlows = props.options.map(({ flow }) => flow);

    if (selectedFlow && availableFlows.includes(selectedFlow)) {
      _setSelectedFlow(selectedFlow);
    }
  }, [selectedFlow, props.options]);

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      setShouldValidate(true);
    } else {
      setSelectedFlow(_selectedFlow);
      _setSelectedFlow(null);
      goForward();
    }
  };

  const customGoBackward = () => {
    setSelectedFlow(null);
    goBackward();
  };

  const handleSelectFlow = (flow) => {
    logAnalyticsActionInfo(
      analyticsActions.SELECT_FLOW,
      {
        flow,
      },
      props.name,
    );
    _setSelectedFlow(flow);
  };

  return (
    <Card>
      <Card.Body className="flow-choice-step">
        <Card.Heading
          text={
            props.heading ?? t(`src.pages.onboarding.steps.allSteps.duplicableSteps.flowChoice.heading`, "Quem é você?")
          }
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              `src.pages.onboarding.steps.allSteps.duplicableSteps.flowChoice.subheading`,
              "Selecione seu cargo na lista abaixo.",
            )
          }
        />
        <Form layout="vertical">
          {props.inputType === "select" ? (
            <FormSelect
              placeholder={t(`${I18N_BASE_PATH}.components.role.placeholder`, "Selecione uma opção")}
              value={_selectedFlow}
              onChange={handleSelectFlow}
              required
              label={t(`${I18N_BASE_PATH}.components.role.label`, "Selecione seu cargo")}
              showRequiredMessage={shouldValidate && !_selectedFlow}
            >
              {props.options?.map((option, index) => (
                <Option data-testid={`flow-choice-option-${option.flow}`} key={index} value={option.flow}>
                  <p>{option.text}</p>
                </Option>
              ))}
            </FormSelect>
          ) : (
            <>
              <Radio.Group className="w-full" onChange={(e) => handleSelectFlow(e.target.value)} value={_selectedFlow}>
                {props.options?.map((option, index) => (
                  <div
                    className={`flex flex-col justify-center px-4 mb-2 cursor-pointer w-full h-11 transition-all border rounded-md ${
                      _selectedFlow === option.flow
                        ? "flow-choice-option-selected bg-primary-light border-primary"
                        : "border-gray-300"
                    }`}
                    onClick={() => handleSelectFlow(option.flow)}
                    key={`QSA_RADIO_${index}`}
                  >
                    <Radio data-testid={`flow-choice-option-${option.flow}`} value={option.flow}>
                      {option.text}
                    </Radio>
                  </div>
                ))}
              </Radio.Group>
              <TextMessageFeedback
                isVisible={shouldValidate && !_selectedFlow}
                messages={{
                  required: {
                    type: "alert",
                    text: t(formMessagesValidations.required.alert),
                  },
                }}
                validations={{ required: shouldValidate && !_selectedFlow }}
              />
            </>
          )}
        </Form>
      </Card.Body>
      <Card.NavBar disabledGoForward={!_selectedFlow} goForward={customGoForward} goBackward={customGoBackward} />
    </Card>
  );
};

export default observer(FlowChoice);
