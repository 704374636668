import classNames from "classnames";
import { useTranslation } from "react-i18next";

const BlankCompany = ({ id, isSelected, handleSelectCompany }) => {
  const { t: translate } = useTranslation();

  return (
    <div
      role="button"
      className={classNames(
        "w-full p-4 bg-white rounded-lg flex border border-gray-300 mt-2 cursor-pointer h-min",
        `${isSelected ? "option-button-selected !bg-blue-100 !border-blue-800" : "option-button !border-gray-200"}`,
      )}
      onClick={() => {
        handleSelectCompany({ id });
      }}
    >
      <aside className="h-full flex flex-col w-full">
        <div className="flex justify-between w-full">
          <span className="font-bold text-sm leading-4 text-black uppercase flex-1 w-full ">
            {translate(
              "src.pages.onboarding.steps.companySearch.components.blankCompany.notFound",
              "EMPRESA NÃO ENCONTRADA",
            )}
          </span>
          <div className="w-7 flex p-1 bg-gray-400 rounded-2xl items-center flex-col text-white text-xs mr-5 h-6">
            -
          </div>
        </div>
        <div className="flex mt-1 gap-1">
          <span className="text-xs text-gray-800">
            {translate("src.pages.onboarding.steps.companySearch.components.blankCompany.description")}
          </span>
        </div>
      </aside>
    </div>
  );
};

export default BlankCompany;
