import axios from "axios";
import type { TemplateData, TemplateDataError } from "@hooks/useTemplateData";
import { fetchWithRetry } from "@utils/index";

export async function requestCompanyData(cnpj: string, token: string) {
  return await axios({
    url: `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/onboardings/${token}/actions/REQUEST_COMPANY_DATA`,
    method: "POST",
    params: {},
    data: { attributes: { cnpj } },
  });
}

export async function getCompanyData(cnpj: string, token: string) {
  const companyDataRequest = await fetchWithRetry(
    `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/onboardings/${token}?cnpj=${cnpj}`,
  );

  const companyDataResponse = (await companyDataRequest.json()) as TemplateData | TemplateDataError;

  if ("message" in companyDataResponse) {
    throw new Error(companyDataResponse.message);
  }

  if (!companyDataResponse) {
    throw new Error("Template not found");
  }

  return companyDataResponse;
}
