import { useEffect, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { Icon } from "@combateafraude/react";
import Card from "@components/Card";
import { FormInput } from "@components/Form";
import { parseStep } from "@utils/onboarding";
import useValidate from "@hooks/useValidate";

import { observer } from "mobx-react-lite";
import store from "@store/index";
import { useAnalytics } from "@contexts/Analytics";

const I18N_BASE_PATH = "src.pages.onboarding.steps";

const CompanySearch = (props) => {
  const { t } = useTranslation();
  const { logAnalyticsStepInfo, analyticsSteps } = useAnalytics();

  const [shouldValidate, setShouldValidate] = useState(false);

  const { company: companyStore } = store.variables;
  const { goForward, addStep, haveStep, index } = store.navigation;

  const allFields = {
    companyName: {
      validity: useValidate(companyStore.companyName),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormInput
            cleanInitialEmptySpace
            allowSpecialCharacteres
            label={label ?? t("general.fields.companyName.label", "Company name")}
            placeholder={placeholder ?? t("general.fields.companyName.placeholder", "")}
            icon={icon !== "" && <Icon icon={icon || "company"} />}
            required={!companyStore.companyNumber}
            showRequiredMessage={shouldValidate && !this.validity}
            value={companyStore.companyName}
            onChange={companyStore.setCompanyName}
            valid={this.validity}
            id="companyName"
          />
        );
      },
    },
    companyNumber: {
      validity: useValidate(companyStore.companyNumber),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormInput
            cleanInitialEmptySpace
            allowSpecialCharacteres
            id="companyNumber"
            label={label ?? t("general.fields.companyNumber.label", "Company number")}
            placeholder={placeholder ?? t("general.fields.companyNumber.placeholder", "")}
            icon={icon !== "" && <Icon icon={icon || "tag"} />}
            required={!companyStore.companyName}
            showRequiredMessage={shouldValidate && !this.validity}
            value={companyStore.companyNumber}
            onChange={companyStore.setCompanyNumber}
            valid={this.validity}
          />
        );
      },
    },
  };

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      setShouldValidate(true);
    } else {
      goForward();
    }
  };

  useEffect(() => {
    const stepIndex = parseStep(props.name)[1];

    if (!haveStep("COMPANY_LISTING")) {
      addStep(
        {
          name: `COMPANY_LISTING-${stepIndex}`,
          props: {
            name: `COMPANY_LISTING-${stepIndex}`,
            heading: t(`${I18N_BASE_PATH}.allSteps.uniqueSteps.companySearch.head`, "Choose your company"),
          },
        },
        index + 1,
      );
    }
  }, []);

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_COMPANY_SEARCH);
  }, [logAnalyticsStepInfo]);

  const disabledGoForward = () => {
    return companyStore.companyName?.length > 0 || companyStore.companyNumber?.length > 0;
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={
            props.heading ?? t(`${I18N_BASE_PATH}.allSteps.uniqueSteps.companySearch.heading`, "Informações da Empresa")
          }
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              `${I18N_BASE_PATH}.allSteps.uniqueSteps.companySearch.subheading`,
              "Para pesquisar uma empresa, você precisa fornecer o nome da empresa ou o número da empresa.",
            )
          }
        />
        <Form layout="vertical" className={`transition-all `}>
          {props.fields.map((item, index) =>
            item.field && allFields[item.field]
              ? { ...allFields[item.field].component({ ...item }), key: index }
              : null,
          )}
        </Form>
      </Card.Body>
      <Card.NavBar disabledGoForward={!disabledGoForward()} customGoForward={customGoForward} />
    </Card>
  );
};

export default observer(CompanySearch);
