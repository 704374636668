import { useTranslation } from "react-i18next";
import useDocumentDetector, { documentSides } from "./hooks/useDocumentDetector";
import Card from "@components/Card";
import { useEffect } from "react";
import { useAnalytics } from "@contexts/Analytics";
import { Logger } from "@utils/logging";
import { getIllustration } from "@utils/illustrations";
import Button from "@components/Button";
import Carousel from "@components/Carousel";
import { ReactSVG } from "react-svg";

import useEvents from "@hooks/useEvents";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { mapInternationalTextFields } from "./utils";

const I18N_BASE_PATH = "src.pages.onboarding.steps";

export interface Props {
  name: string;
  heading?: string;
  subheading?: string;
  retryText?: string;
  button?: string;
}

const DDPreview = (props: Props) => {
  const { t } = useTranslation();

  const { logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions, analyticsSteps } = useAnalytics();
  const documentDetector = useDocumentDetector();
  const side = documentDetector.getCurrentSide();
  const imagePreview = documentDetector.getPreview(side);
  const { pdfPreview } = documentDetector.isPdfPreview();
  const { emitEvent } = useEvents();

  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const { goBackward, goForward } = store.navigation;
  const {
    setDocumentUrlBack,
    setDocumentUrlFront,
    documentIssuedCountry,
    doesDocumentHaveFrontAndBackSides,
    textFieldsData,
  } = store.variables.document;

  const defaultIllustrationPDFDocument = getIllustration("illustration_PDFDocument");

  const nationalGoForward = async () => {
    const currentSide = documentDetector.getCurrentSide();
    Logger.console("DDPreviewStep customGoForward");
    if (currentSide === documentSides.both || currentSide === documentSides.back) {
      startLoader({ heading: t("general.message.loader.processingDocument", "Processando documento...") });
      await emitEvent({
        code: "DD_CAPTURE_FINISH",
      });
      stopLoader();
    }

    if (currentSide === documentSides.front) {
      // change side to back and keep front results
      await documentDetector.changeDocumentSide({
        newSide: documentSides.back,
      });
      goBackward();
    } else {
      // back or both
      // keep side and results but close sdk
      await documentDetector.reset();
      goForward();
    }
  };

  const internationalGoForward = async () => {
    const currentSide = documentDetector.getCurrentSide();
    Logger.console("DDPreviewStep customGoForward");
    if (
      currentSide === documentSides.both ||
      currentSide === documentSides.back ||
      !doesDocumentHaveFrontAndBackSides
    ) {
      startLoader({ heading: t("general.message.loader.processingDocument", "Processando documento...") });

      if (documentIssuedCountry !== "BR" && textFieldsData) {
        store.variables.updateVariables(mapInternationalTextFields(textFieldsData));
      } else {
        await emitEvent({
          code: "DD_CAPTURE_FINISH",
        });
      }

      stopLoader();
    }

    // when doc has one side
    if (!doesDocumentHaveFrontAndBackSides && !documentDetector.isBothSideCapture()) {
      await documentDetector.reset();
      goForward();
      return;
    }

    if (currentSide === documentSides.front) {
      // change side to back and keep front results
      await documentDetector.changeDocumentSide({
        newSide: documentSides.back,
      });
      goBackward();
    } else {
      // back or both
      // keep side and results but close sdk
      await documentDetector.reset();
      goForward();
    }
  };

  const customGoForward = async () => {
    if (documentIssuedCountry !== "BR") {
      await internationalGoForward();
    } else {
      await nationalGoForward();
    }
  };

  const customGoBackward = () => {
    Logger.console("DDPreviewStep customGoBackward");
    if (documentIssuedCountry !== "BR") {
      documentDetector.setCurrentSideToFront();
    }
    goBackward();
  };

  const handleRetry = () => {
    logAnalyticsActionInfo(analyticsActions.DD_PREVIEW_RETRY, {}, props.name);

    if (documentIssuedCountry !== "BR") {
      documentDetector.setCurrentSideToFront();
      setDocumentUrlFront("");
      setDocumentUrlBack("");
      documentDetector.resetResult();
    }

    customGoBackward();
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_DD_PREVIEW);
  }, [logAnalyticsStepInfo]);

  const getImageTemplate = () => {
    if (imagePreview && pdfPreview) {
      return (
        <div className={"w-full flex flex-col items-center space-y-5 py-4 pb-10"}>
          <ReactSVG src={defaultIllustrationPDFDocument} role="img" className="themed-svg" />
          <Button
            id="preview-pdf"
            className="mb-6"
            type="secondary"
            outlined
            onClick={() => {
              window.open(imagePreview);
            }}
          >
            {props.button ??
              t(`${I18N_BASE_PATH}.allSteps.uniqueSteps.dd.previewStep.button`, "Clique aqui para visualizar")}
          </Button>
        </div>
      );
    } else if (documentIssuedCountry !== "BR" && doesDocumentHaveFrontAndBackSides) {
      return (
        <Carousel
          key={documentDetector.getDocumentType()}
          slides={[
            {
              element: (
                <img
                  src={documentDetector.getPreview(documentSides.front)}
                  alt={t(`${I18N_BASE_PATH}.ddPreview.components.documentImage.alt`, "Foto do seu documento")}
                />
              ),
              label: t(`${I18N_BASE_PATH}.ddPreview.components.documentImage.frontCaption`, "Frente do documento"),
            },
            {
              element: (
                <img
                  src={documentDetector.getPreview(documentSides.back)}
                  alt={t(`${I18N_BASE_PATH}.ddPreview.components.documentImage.alt`, "Foto do seu documento")}
                />
              ),
              label: t(`${I18N_BASE_PATH}.ddPreview.components.documentImage.backCaption`, "Verso do documento"),
            },
          ]}
        />
      );
    } else {
      return (
        <img
          className="h-56 mb-6 object-scale-down rounded-lg"
          src={imagePreview}
          alt={t(`${I18N_BASE_PATH}.ddPreview.components.documentImage.alt`, "Foto do seu documento")}
        />
      );
    }
  };

  return (
    <Card>
      <Card.Body className="dd-preview-step" center>
        {getImageTemplate()}
        <Card.Heading
          small
          text={
            props.heading ??
            t(`${I18N_BASE_PATH}.allSteps.uniqueSteps.dd.previewStep.heading`, "O documento está nítido?")
          }
        />
        <Card.Subheading
          small
          text={
            props.subheading ??
            t(
              `${I18N_BASE_PATH}.allSteps.uniqueSteps.dd.previewStep.subheading`,
              "Verifique se o documento está nítido.",
            )
          }
        />
        <span
          className="text-secondary underline text-base cursor-pointer"
          data-testid="dd-preview-step-retry-button"
          onClick={handleRetry}
        >
          {props.retryText ?? t("general.label.takeAnotherPhoto", "Tirar novamente")}
        </span>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!documentDetector.getResults()}
        goBackward={customGoBackward}
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(DDPreview);
