import { Icon } from "@combateafraude/react";
import classNames from "classnames";

import Card from "@components/Card";

const TextMessageFeedback = ({ isVisible, messages, validations, className = "" }) => {
  return (
    <>
      {!!validations.required ? (
        <>
          {messages.required.type === "text" ? (
            <span className="text-primary text-red-400 text-xs block !mt-1 font-medium">{messages.required.text}</span>
          ) : (
            <div
              data-test-id="required-message"
              className="flex items-center px-2 py-2 !mt-2 w-full bg-red-100 border border-red-400 rounded-md required-field-alert"
            >
              <Icon icon="close" className="text-red-400" />
              <p className="text-sm ml-2">{messages.required.text}</p>
            </div>
          )}
        </>
      ) : !!validations.error ? (
        <Card.Text
          className={classNames(
            `form-error-feedback overflow-y-hidden transition-all ${isVisible ? "max-h-48 mt-1" : "max-h-0 !m-0"}`,
            className,
          )}
          error
          small
          text={messages.error.text}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TextMessageFeedback;
