const I18N_BASE_PATH = "src.pages.onboarding.steps.documentType";

export interface Document {
  type: string;
  id: string;
  icon: string;
  title: string;
  description: string;
}

const defaultDocumentValues: {
  [key: string]: Document;
} = {
  RG: {
    type: "RG",
    id: "RG",
    icon: "faceID",
    title: `${I18N_BASE_PATH}.constants.defaultDocumentValues.rg.title`,
    // => RG
    description: `${I18N_BASE_PATH}.constants.defaultDocumentValues.rg.description`,
    // => Documento de identidade
  },
  /* TODO: ver icones com o design */
  PASSPORT: {
    type: "PASSPORT",
    id: "PASSPORT",
    icon: "passport",
    title: `${I18N_BASE_PATH}.constants.defaultDocumentValues.passport.title`,
    // => Passaporte
    description: `${I18N_BASE_PATH}.constants.defaultDocumentValues.passport.description`,
    // => Documento Brasileiro oficial no exterior
  },
  CTPS: {
    type: "CTPS",
    id: "CTPS",
    icon: "ctps",
    title: `${I18N_BASE_PATH}.constants.defaultDocumentValues.ctps.title`,
    // => CTPS
    description: `${I18N_BASE_PATH}.constants.defaultDocumentValues.ctps.description`,
    // => Carteira de Trabalho e Previdência Social
  },
  CNH: {
    type: "CNH",
    id: "CNH",
    icon: "car",
    title: `${I18N_BASE_PATH}.constants.defaultDocumentValues.cnh.title`,
    // => CNH
    description: `${I18N_BASE_PATH}.constants.defaultDocumentValues.cnh.description`,
    // => Carteira nacional de habilitação
  },
  RNE: {
    type: "RNE",
    id: "RNE",
    icon: "person",
    title: `${I18N_BASE_PATH}.constants.defaultDocumentValues.rne.title`,
    // => RNE/RNM
    description: `${I18N_BASE_PATH}.constants.defaultDocumentValues.rne.description`,
    // => Registro nacional de estrangeiros
  },
  ANY: {
    type: "ANY",
    id: "ANY",
    icon: "person",
    title: `${I18N_BASE_PATH}.constants.defaultDocumentValues.any.title`,
    // => Outros
    description: `${I18N_BASE_PATH}.constants.defaultDocumentValues.any.description`,
    // => Outros documentos
  },
  OTHER: {
    type: "OTHER",
    id: "other",
    icon: "combateafraude",
    title: `${I18N_BASE_PATH}.constants.defaultDocumentValues.other.title`,
    // => Outro
    description: `${I18N_BASE_PATH}.constants.defaultDocumentValues.other.description`,
    // => Nenhum documento acima
  },
};

export default defaultDocumentValues;
