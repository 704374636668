import { Logger } from "@utils/logging";

interface UserLocation {
  country: string | null;
}

type GetUserLocation = () => Promise<UserLocation>;

export default function useIpApi() {
  const getUserLocation: GetUserLocation = async () => {
    try {
      const data = await fetch(`https://ipapi.co/json`).then((response) => response.json());

      const propertiesMatched = ["country"].map((property) => {
        if (data[property]) {
          return { [property]: data[property] };
        }
        return { [property]: null };
      });
      const allPropertiesInObject = Object.assign({}, ...propertiesMatched);
      return allPropertiesInObject;
    } catch (error) {
      Logger.console("We have just gotten error fetching user location by IP", error);
    }
  };

  return {
    getUserLocation,
  };
}
