import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import VerificationCodeInput from "@components/VerificationCodeInput/index";
import { useAnalytics } from "@contexts/Analytics";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { requestAction } from "../services";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

const EmailValidation = (props) => {
  const { t } = useTranslation();

  const [personEmailValidated, setPersonEmailValidated] = useState(false);
  const [validationCode, setValidationCode] = useState("");
  const [isValidCode, setIsValidCode] = useState(false);
  const [missingDigits, setMissingDigits] = useState(6);
  const [canResendCode, setCanResendCode] = useState(false);
  const [message, setMessage] = useState(
    t("general.message.codeValidation.states.message", "Validando código, por favor aguarde..."),
  );
  const [startResendCodeCounter, setStartResendCodeCounter] = useState(false);
  const [resendCodeCounter, setResendCodeCounter] = useState(0);

  const { analyticsSteps, logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions } = useAnalytics();

  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const {
    person,
    general: { token },
  } = store.variables;

  useEffect(() => {
    if (token && person.personEmail && !personEmailValidated) sendCode();
  }, [token, person.personEmail]);

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_EMAIL_VALIDATION);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    if (startResendCodeCounter) {
      setTimeout(() => {
        if (resendCodeCounter === 60) {
          setResendCodeCounter(0);
          setCanResendCode(true);
          setStartResendCodeCounter(false);
        } else {
          setResendCodeCounter(resendCodeCounter + 1);
        }
      }, 1000);
    }
  }, [startResendCodeCounter, resendCodeCounter]);

  const sendCode = () => {
    setMessage(t("general.message.codeValidation.methods.sendCode.message", "Informe o código no campo acima"));
    setValidationCode("");
    setCanResendCode(false);
    setIsValidCode(false);
    startLoader({ heading: t("general.message.loader.sendingCodeValidation", "Enviando código de validação...") });
    requestAction("REQUEST_EMAIL", token, {
      email: person.personEmail,
      cpf: person.personCpf,
    }).then((res) => {
      stopLoader();
    });
  };

  const verifyCode = (code = "") => {
    if (code?.length && !personEmailValidated) {
      startLoader({
        heading: t("general.message.loader.checkingCodeValidation", "Verificando código de validação..."),
      });
      requestAction("VERIFY_EMAIL", token, {
        code,
        cpf: person.personCpf,
      })
        .then((res) => {
          if (res.status === 200) {
            setIsValidCode(true);
            setCanResendCode(false);
            setMessage(t("general.message.success.codeValidated", "Código validado com sucesso!"));
            setPersonEmailValidated(true);
            // Reset counter
            setStartResendCodeCounter(false);
            setResendCodeCounter(0);
          } else {
            setIsValidCode(false);
            setStartResendCodeCounter(true);
            setMessage(
              t(
                "general.message.error.codeValidationFailed",
                "Houve um problema ao validar o código, tente novamente!",
              ),
            );
          }
          stopLoader();
        })
        .catch((err) => {
          setIsValidCode(false);
          setStartResendCodeCounter(true);
          setMessage(
            t("general.message.error.codeValidationFailed", "Houve um problema ao validar o código, tente novamente!"),
          );
          stopLoader();
        });
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.emailValidation.heading`, "Confirme sua identidade")}
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              `${I18N_BASE_PATH}.uniqueSteps.emailValidation.subheading`,
              "Enviamos um e-mail com o código de acesso. Por favor insira abaixo o código recebido.",
            )
          }
        />
        <span className="mb-3 text-base font-bold text-black">
          {t("general.message.codeValidation.components.heading", "Código de validação")}
        </span>
        <div className="relative w-full">
          <VerificationCodeInput
            disabled={personEmailValidated}
            values={validationCode.split("")}
            onChange={(value) => {
              setMissingDigits(6 - Number(value?.length));
              setValidationCode(value);
            }}
            onComplete={(value) => {
              logAnalyticsActionInfo(analyticsActions.FILLED_EMAIL_CODE, {}, props.name);
              verifyCode(value);
            }}
          />
        </div>
        <span className="block text-center m-6 text-primary text-lg font-bold">
          {missingDigits === 0
            ? message
            : `${
                missingDigits === 1
                  ? t("general.message.codeValidation.components.missingDigitsOne", "1 Dígito restante")
                  : t(
                      "general.message.codeValidation.components.missingDigitsPlural",
                      `${missingDigits} Dígitos restantes`,
                      {
                        missingDigits,
                      },
                    )
              }`}
        </span>
        <a
          disabled={!canResendCode}
          className="m-6 text-center text-base text-primary"
          onClick={() => {
            if (canResendCode && !personEmailValidated) {
              logAnalyticsActionInfo(analyticsActions.RESEND_EMAIL_CODE, {}, props.name);
              sendCode();
            }
          }}
        >
          {startResendCodeCounter
            ? t(
                "general.message.codeValidation.components.resendCodeCounter.counterInitialized",
                `Reenviar código (aguarde ${(60 - resendCodeCounter).toString().padStart(2, "0")}s).`,
                { time: (60 - resendCodeCounter).toString().padStart(2, "0") },
              )
            : t("general.message.codeValidation.components.resendCodeCounter.default", "Reenviar código")}
        </a>
      </Card.Body>
      <Card.NavBar disabledGoForward={!isValidCode} />
    </Card>
  );
};

export default observer(EmailValidation);
