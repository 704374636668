import { useEffect, useMemo } from "react";
import IntlCurrencyInput from "react-intl-currency-input";
import Card from "@components/Card";
import { useAnalytics } from "@contexts/Analytics";
import store from "@store/index";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import useTemplateData from "@hooks/useTemplateData";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

const Income = (props) => {
  const { t, i18n } = useTranslation();
  const { logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions, analyticsSteps } = useAnalytics();

  const { templateData } = useTemplateData();

  const { companyIncome, setCompanyIncome, setCompanyIncomeFrequency } = store.variables.company;
  const { personIncome, setPersonIncome, setPersonIncomeFrequency } = store.variables.person;

  const isPJ = useMemo(() => {
    const onboardingType = templateData?.template?.type;
    return ["PJ", "PJ_QSA"].includes(onboardingType);
  }, [templateData]);

  const canGoForward = useMemo(() => {
    let value = isPJ ? parseFloat(companyIncome) : parseFloat(personIncome);
    let canGoForward = true;
    canGoForward = ((props.max && value <= props.max) || !props.max) && canGoForward;
    canGoForward = ((props.min && value >= props.min) || !props.min) && canGoForward;

    return canGoForward;
  }, [props.max, props.min, companyIncome, personIncome, isPJ]);

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_INCOME);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    if (props.default) {
      if (!companyIncome) setCompanyIncome(props.default);
      if (!personIncome) setPersonIncome(props.default);
    }
  }, [props.default]);

  useEffect(() => {
    const incomeFrequencyValue = props.frequency ? props.frequency : "monthly";
    if (isPJ) {
      setCompanyIncomeFrequency(incomeFrequencyValue);
    } else {
      setPersonIncomeFrequency(incomeFrequencyValue);
    }
  }, [props.frequency, isPJ]);

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: i18n?.language === "pt-BR" ? "currency" : "decimal",
          currency: "BRL",
          maximumFractionDigits: 2,
        },
      },
    },
  };

  return (
    <Card>
      <Card.Body className="income-step h-full">
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.income.heading`, "Qual seu faturamento mensal?")}
        />
        <Card.Subheading
          text={
            props.subheading ?? t(`${I18N_BASE_PATH}.uniqueSteps.income.subheading`, "Informe um valor aproximado.")
          }
        />
        <div className="flex flex-col items-center justify-center h-full">
          <IntlCurrencyInput
            type="tel"
            className="w-80 border-dotted outline-none text-3xl text-center border-b-4 pb-4 mb-8 text-primary font-bold border-primary bg-white"
            currency="BRL"
            config={currencyConfig}
            data-testid="income-input"
            value={isPJ ? companyIncome || props.default : personIncome || props.default}
            onKeyDown={(event) => {
              if (event.ctrlKey === true && event.keyCode === 65) event.preventDefault();
            }}
            onBlur={(event, value, maskedValue) => {
              logAnalyticsActionInfo(
                analyticsActions.FILLED_INCOME_INPUT,
                {
                  value,
                },
                props.name,
              );
            }}
            onChange={(_, value) => {
              if (isPJ) {
                parseFloat(value) && setCompanyIncome(value);
              } else {
                parseFloat(value) && setPersonIncome(value);
              }
            }}
          />
        </div>
      </Card.Body>
      <Card.NavBar disabledGoForward={!canGoForward} />
    </Card>
  );
};

export default observer(Income);
