import { cloneElement, useState, useMemo } from "react";
import { Input, Radio, Select, Switch, Checkbox as AntCheckbox } from "antd";
import { Checkbox as CafCheckbox, Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import InputMask from "react-input-mask";
import { Regex } from "../../utils/formatting";

import TextMessageFeedback from "@components/TextMessageFeedback";
import formMessagesValidations from "@utils/formErrors";
import useDateFormatter from "@hooks/formatters/useDateFormatter";
import usePhoneFormatter from "@hooks/formatters/usePhoneFormatter";
import { observer } from "mobx-react-lite";

export const InputTypes = {
  TEXT: "text",
  EMAIL: "email",
  PASSWORD: "password",
  NUMBER: "number",
  PHONE: "phone",
  DATE: "date",
  FREE: "free",
};

export const FormInput = observer(
  ({
    label = "",
    icon,
    valid,
    password = false,
    maxLength = undefined,
    inputType = InputTypes.TEXT,
    validate,
    error,
    // validate field errors when the input is not focused
    isToValidateErrorWithinFocus = false,
    errorMessage,
    required,
    // when to show required message (text or alert)
    showRequiredMessage,
    className,
    uppercase,
    id,
    touched,
    disabled: isDisabled,
    onChange,
    allowSpecialCharacteres = false,
    textOnly = false,
    isVisible,
    mask,
    maskChar,
    value,
    onBlur,
    cleanInitialEmptySpace = false,
    ...rest
  }) => {
    const { t } = useTranslation();

    const [isFocused, setIsFocused] = useState(false);
    const { dateMask, verifyOcrMaskWithTemplateMask } = useDateFormatter();
    const { formatPhone, getDefaultCountryCallingCode } = usePhoneFormatter();

    const hasSpecialCharacters = Boolean(
      inputType === InputTypes.EMAIL ||
        inputType === InputTypes.PASSWORD ||
        inputType === InputTypes.DATE ||
        inputType === InputTypes.PHONE ||
        rest.type === "email" ||
        allowSpecialCharacteres ||
        mask ||
        InputTypes.FREE,
    );

    const isValid = useMemo(() => {
      if (!required && value?.length === 0) return;

      if (typeof valid === "boolean") return valid;
      else if (typeof validate === "function") return validate(value);
    }, [value, valid, validate]);

    const suffix = useMemo(() => {
      return (
        <div className="relative w-6 h-full">
          <Icon
            className={`absolute right-0 text-red-400 transition-all ${
              (isToValidateErrorWithinFocus && !isFocused && error) || (!isToValidateErrorWithinFocus && error)
                ? "opacity-100"
                : "opacity-0"
            }`}
            icon={"close"}
          />
          <Icon
            className={`absolute right-0 text-secondary transition-all ${
              !error && isValid ? "opacity-100" : "opacity-0"
            }`}
            icon={"check_d"}
          />
        </div>
      );
    }, [error, isToValidateErrorWithinFocus, isFocused, isValid]);

    const onChangeDefault = (event) => {
      let value = event.target.value ?? "";

      if (!!value && inputType === InputTypes.PHONE && (value?.length === 0 || value[0] !== "+")) {
        value = getDefaultCountryCallingCode() + value;
      }

      if (textOnly) {
        value = value.replace(Regex.alphabetical, "");
      } else if (!hasSpecialCharacters) {
        value = value.replace(Regex.alphanumeric, "");
      } else if (inputType === InputTypes.PHONE) {
        value = formatPhone(value);
      }

      if (cleanInitialEmptySpace && value.startsWith(" ")) {
        value = value.trim();
      }

      if (typeof onChange === "function") onChange(value);
    };

    const onChangeWhenInputTypeIsNumber = (event) => {
      if (maxLength) {
        const currentValueLength = String(event.target.value).length;
        if (currentValueLength <= maxLength) onChangeDefault(event);
      } else {
        onChangeDefault(event);
      }
    };

    const selectOnChange = (event) => {
      inputType === InputTypes.NUMBER ? onChangeWhenInputTypeIsNumber(event) : onChangeDefault(event);
    };

    const handleBlur = () => {
      if (typeof onBlur === "function") onBlur();
      setIsFocused(false);
    };

    const renderInput = () => {
      if (inputType === InputTypes.PASSWORD) {
        return (
          <Input.Password
            data-testid={`form-input-${id}`}
            id={id ?? label}
            prefix={
              !!icon &&
              cloneElement(icon, {
                className:
                  (isToValidateErrorWithinFocus && !isFocused && error) || (!isToValidateErrorWithinFocus && error)
                    ? "text-red-400"
                    : "text-primary",
              })
            }
            suffix={suffix}
            className={classNames("!rounded-md p-3.5 !font-medium", {
              "opacity-50": isDisabled,
              uppercase: uppercase,
              "!border !border-red-400 text-red-400": isToValidateErrorWithinFocus ? !isFocused && error : error,
            })}
            onChange={onChangeDefault}
            value={value}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            disabled={isDisabled}
            autoComplete="new-password"
            maxLength={maxLength}
            {...rest}
          />
        );
      } else if (!!mask || inputType === InputTypes.DATE) {
        let inputMask = mask;
        if (inputType === InputTypes.DATE) inputMask = dateMask;
        return (
          <InputMask
            {...rest}
            id={id ?? label}
            data-testid={`form-input-${id}`}
            value={value}
            mask={inputMask}
            maskChar={maskChar}
            onChange={onChangeDefault}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            disabled={isDisabled}
            maxLength={maxLength}
          >
            {(inputProps) => (
              <Input
                {...inputProps}
                prefix={
                  !!icon ? (
                    cloneElement(icon, {
                      className:
                        (isToValidateErrorWithinFocus && !isFocused && error) ||
                        (!isToValidateErrorWithinFocus && error)
                          ? "text-red-400"
                          : "text-primary",
                    })
                  ) : (
                    <></>
                  )
                }
                suffix={suffix}
                className={classNames("!rounded-md p-3.5 !font-medium", {
                  "opacity-50": isDisabled,
                  uppercase: uppercase,
                  "!border !border-red-400": isToValidateErrorWithinFocus ? !isFocused && error : error,
                })}
              />
            )}
          </InputMask>
        );
      } else {
        return (
          <Input
            data-testid={`form-input-${id}`}
            id={id ?? label}
            prefix={
              !!icon &&
              cloneElement(icon, {
                className:
                  (isToValidateErrorWithinFocus && !isFocused && error) || (!isToValidateErrorWithinFocus && error)
                    ? "text-red-400"
                    : "text-primary",
              })
            }
            suffix={suffix}
            className={classNames("!rounded-md p-3.5 !font-medium", {
              "opacity-50": isDisabled,
              uppercase: uppercase,
              "!border !border-red-400 text-red-400": isToValidateErrorWithinFocus ? !isFocused && error : error,
            })}
            onChange={(e) => {
              selectOnChange(e);
            }}
            value={value}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            disabled={isDisabled}
            maxLength={maxLength}
            {...rest}
          />
        );
      }
    };

    return (
      <div
        className={classNames(
          `form-input relative mb-6 ${isFocused && error && "form-input-error-focused"}`,
          className,
        )}
      >
        <label
          htmlFor={label}
          className={classNames("bg-white absolute -top-2.5 left-2 z-10 px-2 font-medium text-pr", {
            "text-red-400": isToValidateErrorWithinFocus ? !isFocused && error : error,
          })}
        >
          {required ? `${label} *` : label}
        </label>
        {renderInput()}
        <TextMessageFeedback
          isVisible={(required && showRequiredMessage) || (isToValidateErrorWithinFocus ? !isFocused && error : error)}
          messages={{
            required: { type: "text", text: t(formMessagesValidations.required.text) },
            error: { text: errorMessage },
          }}
          validations={{ required: required && showRequiredMessage, error }}
        />
      </div>
    );
  },
);

export const FormSelect = ({
  label,
  className,
  prefixIcon,
  multiple,
  children,
  value,
  disabled: isDisabled,
  onChange,
  required,
  showRequiredMessage,
  id,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(`form-select relative w-full mb-6`, className)}>
      <label htmlFor={label} className="bg-white absolute -top-2.5 left-2 z-10 px-2 font-medium text-pr">
        {required ? `${label} *` : label}
      </label>
      {prefixIcon && (
        <Icon className={`absolute left-4 ${multiple ? "top-3" : "top-4"} text-primary z-10`} icon={prefixIcon} />
      )}
      <Select
        data-testid={`form-select-${id}`}
        className={classNames("w-full", { "opacity-50": rest.disabled, "no-icon": !prefixIcon })}
        disabled={isDisabled}
        mode={!!multiple && "multiple"}
        onChange={onChange}
        value={value}
        {...rest}
      >
        {children}
      </Select>
      <TextMessageFeedback
        isVisible={required && showRequiredMessage}
        messages={{
          required: { type: "text", text: t(formMessagesValidations.required.text) },
        }}
        validations={{ required: required && showRequiredMessage }}
      />
    </div>
  );
};

export const FormCheckbox = ({
  id,
  children,
  multiple = false,
  required,
  options,
  disabled: isDisabled,
  showRequiredMessage,
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  const optionsFormatted = useMemo(() => options?.map((option) => ({ label: option, value: option })), [options]);

  return (
    <>
      {multiple ? (
        <div>
          <AntCheckbox.Group
            name={id}
            disabled={isDisabled}
            options={optionsFormatted}
            checked={value}
            onChange={onChange}
          />
          <TextMessageFeedback
            isVisible={required && showRequiredMessage}
            messages={{
              required: {
                type: "alert",
                text: t(formMessagesValidations.required.alert),
              },
            }}
            validations={{ required: required && showRequiredMessage }}
          />
        </div>
      ) : (
        <div className="form-checkbox flex items-center">
          <CafCheckbox id={id} disabled={isDisabled} checked={value} onChange={(e) => onChange(e?.target?.checked)} />
          <label className="pl-2 cursor-pointer" htmlFor={id}>
            {children}
          </label>
        </div>
      )}
    </>
  );
};

export const FormRadioGroup = ({ disabled: isDisabled, onChange, options, required, showRequiredMessage, value }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Radio.Group
        className="!m-0 w-full"
        disabled={isDisabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <div className="flex flex-col space-y-1">
          {options?.map((option, i) => (
            <Radio value={option} key={`OPTION_${option}_${i}`}>
              {option}
            </Radio>
          ))}
        </div>
      </Radio.Group>
      <TextMessageFeedback
        isVisible={required && showRequiredMessage}
        messages={{
          required: { type: "alert", text: t(formMessagesValidations.required.text) },
        }}
        validations={{ required: required && showRequiredMessage }}
      />
    </div>
  );
};

export const FormSwitch = ({
  id,
  disabled: isDisabled,
  label,
  onChange,
  value,
  bold,
  required,
  showRequiredMessage,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Switch
        className="w-12"
        disabled={isDisabled}
        checked={value}
        onChange={(value) => onChange(value)}
        defaultChecked={value}
        {...rest}
      />
      {!!label && (
        <span className={classNames("text-gray-800 ml-3 whitespace-pre-wrap text-base", { "font-bold": bold })}>
          {label}
        </span>
      )}
      <TextMessageFeedback
        isVisible={required && showRequiredMessage}
        messages={{
          required: { type: "alert", text: t(formMessagesValidations.required.text) },
        }}
        validations={{ required: required && showRequiredMessage }}
      />
    </div>
  );
};
