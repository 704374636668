import { useEffect, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { Icon } from "@combateafraude/react";
import Button from "@components/Button";
import Card from "@components/Card";
import { FormInput, InputTypes } from "@components/Form";
import { useAnalytics } from "@contexts/Analytics";
import useValidate from "@hooks/useValidate";
import { Mask, Regex } from "@utils/formatting";
import formMessagesValidations from "@utils/formErrors";
import { validateCNPJ } from "@utils/validations";
import usePhoneFormatter from "@hooks/formatters/usePhoneFormatter";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import useRequestCompanyData from "./hooks/useRequestCompanyData";

const I18N_BASE_PATH = "src.pages.onboarding.steps";

const CompanyData = (props) => {
  const { t } = useTranslation();

  const [shouldValidate, setShouldValidate] = useState(false);

  const { phoneValidation } = usePhoneFormatter();
  const { logAnalyticsStepInfo, analyticsSteps, logAnalyticsActionInfo, analyticsActions } = useAnalytics();
  const getCompanyData = useRequestCompanyData();

  const { isImmutableVariable, company: companyStore } = store.variables;
  const { goForward } = store.navigation;

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_COMPANY_DATA);
  }, [logAnalyticsStepInfo]);

  // If there is a CNPJ but no officialName at the start, start pooling right away
  useEffect(() => {
    if (companyStore.companyCnpj && !companyStore.companyOfficialName && !getCompanyData.error)
      getCompanyData.request();
  }, []);

  const handleBlur = (field) => {
    logAnalyticsActionInfo(
      analyticsActions.FIELD_CHANGE,
      {
        field,
      },
      props.name,
    );
  };

  const allFields = {
    official_name: {
      validity: useValidate(companyStore.companyOfficialName),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormInput
            label={label ?? t("general.fields.companyOfficialName.label", "Razão Social")}
            placeholder={placeholder ?? t("general.fields.companyOfficialName.placeholder", "Digite o nome fantasia")}
            icon={icon !== "" && <Icon icon={icon || "company"} />}
            uppercase
            required
            showRequiredMessage={shouldValidate && !this.validity}
            value={companyStore.companyOfficialName}
            onChange={companyStore.setCompanyOfficialName}
            disabled={isImmutableVariable("companyOfficialName")}
            valid={this.validity}
            allowSpecialCharacteres
            id="companyOfficialName"
            onBlur={() => handleBlur("companyOfficialName")}
          />
        );
      },
    },
    fantasy_name: {
      validity: useValidate(companyStore.companyFantasyName),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormInput
            label={label ?? t("general.fields.companyFantasyName.label", "Nome Fantasia")}
            placeholder={placeholder ?? t("general.fields.companyFantasyName.placeholder", "Digite o nome fantasia")}
            icon={icon !== "" && <Icon icon={icon || "tag"} />}
            uppercase
            required
            showRequiredMessage={shouldValidate && !this.validity}
            value={companyStore.companyFantasyName}
            onChange={companyStore.setCompanyFantasyName}
            disabled={isImmutableVariable("companyFantasyName")}
            valid={this.validity}
            allowSpecialCharacteres
            id="companyFantasyName"
            onBlur={() => handleBlur("companyFantasyName")}
          />
        );
      },
    },
    phone_number: {
      validity: useValidate(companyStore.companyPhoneNumber, (phone) => phoneValidation(phone) || phone.length === 0),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormInput
            label={label ?? t("general.fields.companyPhoneNumber.label", "Telefone de Contato")}
            placeholder={
              placeholder ?? t("general.fields.companyPhoneNumber.placeholder", "Digite o telefone de contato")
            }
            error={!this.validity && companyStore.companyPhoneNumber.length > 0}
            icon={icon !== "" && <Icon icon={icon || "contact_"} />}
            inputType={InputTypes.PHONE}
            type="tel"
            value={companyStore.companyPhoneNumber}
            onChange={companyStore.setCompanyPhoneNumber}
            disabled={isImmutableVariable("companyPhoneNumber")}
            valid={this.validity}
            id="companyPhoneNumber"
            onBlur={() => handleBlur("companyPhoneNumber")}
          />
        );
      },
    },
    company_id: {
      validity: useValidate(companyStore.companyId),
      get component() {
        return ({ label, placeholder, icon }) => (
          <FormInput
            label={label ?? t("general.fields.companyId.label", "Identificador empresárial")}
            placeholder={placeholder ?? t("general.fields.companyId.placeholder", "CNPJ, etc...")}
            icon={icon !== "" && <Icon icon={icon || "company"} />}
            value={companyStore.companyId}
            onChange={companyStore.setCompanyId}
            disabled={isImmutableVariable("companyId")}
            required
            allowSpecialCharacteres
            valid={this.validity}
            onBlur={() => handleBlur("companyId")}
          />
        );
      },
    },
  };

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      setShouldValidate(true);
    } else {
      goForward();
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={
            props.heading ?? t(`${I18N_BASE_PATH}.allSteps.uniqueSteps.companyData.heading`, "Informações da empresa")
          }
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              `${I18N_BASE_PATH}.allSteps.uniqueSteps.companyData.subheading`,
              "Para realizar o cadastro da sua empresa, precisamos de alguns dados.",
            )
          }
        />
        <FormInput
          label={props.fieldCnpj?.label ?? t("general.fields.cnpj.label", "CNPJ")}
          placeholder={props.fieldCnpj?.placeholder ?? t("general.fields.cnpj.placeholder", "Digite o CNPJ")}
          mask={Mask.cnpj}
          type="tel"
          icon={(!props.fieldCnpj || props.fieldCnpj.icon !== "") && <Icon icon={props.fieldCnpj?.icon || "company"} />}
          required
          showRequiredMessage={shouldValidate && !companyStore.companyCnpj > 0}
          error={
            (!!companyStore.companyCnpj > 0 && !Regex.cnpj.test(companyStore.companyCnpj)) ||
            (!!companyStore.companyCnpj > 0 && !validateCNPJ(companyStore.companyCnpj))
          }
          errorMessage={t(formMessagesValidations.valid_cnpj)}
          isToValidateErrorWithinFocus
          value={companyStore.companyCnpj}
          onChange={companyStore.setCompanyCnpj}
          disabled={isImmutableVariable("companyCnpj")}
          validate={(cnpj) => Regex.cnpj.test(cnpj)}
          id="companyCnpj"
          onBlur={() => {
            logAnalyticsActionInfo(
              analyticsActions.CNPJ_FIELD_CHANGE,
              {
                cnpj: companyStore.companyCnpj,
              },
              props.name,
            );
          }}
        />
        {!(companyStore.companyOfficialName || getCompanyData.success || getCompanyData.error) && (
          <Button
            type="primary"
            block
            onClick={getCompanyData.request}
            disabled={
              !Regex.cnpj.test(companyStore.companyCnpj) ||
              !validateCNPJ(companyStore.companyCnpj) ||
              getCompanyData.loading
            }
            data-testid="search-company-data"
            className={
              (!Regex.cnpj.test(companyStore.companyCnpj) ||
                getCompanyData.loading ||
                !validateCNPJ(companyStore.companyCnpj)) &&
              "button-search-company-data-now-allowed"
            }
          >
            {t(`${I18N_BASE_PATH}.companyData.components.button`, "Buscar")}
          </Button>
        )}
        <Form
          layout="vertical"
          className={`transition-all ${
            companyStore.companyOfficialName || getCompanyData.success || getCompanyData.error
              ? "opacity-1"
              : "opacity-0 hidden pointer-events-none"
          }`}
        >
          {props.fields.map((item, index) =>
            item.field && allFields[item.field]
              ? { ...allFields[item.field].component({ ...item }), key: index }
              : null,
          )}
        </Form>
      </Card.Body>
      <Card.NavBar
        goForward={customGoForward}
        disabledGoForward={!props.fields.every(({ field }) => allFields[field].validity)}
      />
    </Card>
  );
};

export default observer(CompanyData);
