import axios from "axios";
import { Logger } from "@utils/logging";
import { fetchWithRetry } from "@utils/index";
import { clearSavedData } from "@hooks/useSavedData";

export async function unifyMultipleFilesKeys(keys) {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/onboardings/unify-files`, {
      keys,
    });

    if (!!data?.key) return data?.key;
  } catch (error) {
    Logger.error("An error occurred while unifying multiple files keys.", error);
  }
}

export async function sendFinalPost(data, token) {
  if (token && data) {
    try {
      const response = await fetchWithRetry(
        `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/onboardings/${token}`,
        {
          method: "POST",
          body: JSON.stringify(data),
        },
      );

      clearSavedData();

      if (response.status !== 200) {
        Logger.error(response, {
          message: "Couldn't send final post (inside).",
        });

        return {
          error: true,
          response: null,
        };
      }

      const json = await response.json();

      Logger.console(`Final post result (${!!data.attributes.cnpj ? "PJ" : "PF"}):`, json);

      return { error: false, response: json };
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't send final post (outside).",
      });

      return {
        error,
        response: null,
      };
    }
  }

  return {
    error: true,
    response: null,
  };
}
