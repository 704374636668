import { Progress } from "antd";
import classNames from "classnames";
import { Card as CafCard, Icon } from "@combateafraude/react";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

import Button from "@components/Button";
import { getIllustration, isIllustration } from "@utils/illustrations";

import { observer } from "mobx-react-lite";
import store from "@store/index";
import { isMobile } from "@utils/index";
import { useAnalytics } from "@contexts/Analytics";
import useTemplateData from "@hooks/useTemplateData";
import Loader from "@components/Loader";

const I18N_BASE_PATH = "src.components.card";

interface CardProps {
  customKey?: string;
  children?: React.ReactNode;
  className?: string;
  isFetching?: boolean;
}

const Card = ({ customKey, children, className, isFetching }: CardProps) => {
  // - "I miss you guys" - Cruz, July 29th 2022
  const mobile = isMobile();

  const { templateData } = useTemplateData();

  return (
    <CafCard
      key={customKey}
      className={classNames(`relative`, className)}
      hoverable={false}
      style={{
        height: mobile ? "95%" : "95%",
        width: mobile ? "100%" : 450,
        margin: "auto",
      }}
      bodyStyle={{
        backgroundColor: templateData?.template?.backgroundSteps,
        maxHeight: "100%",
        maxWidth: "100%",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
      id="accessibility_main-card"
    >
      <Loader component={isFetching ? "loading" : templateData?.template?.loader} />
      {children}
    </CafCard>
  );
};

interface CardBodyProps {
  center?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const CardBody = ({ center, className, children }: CardBodyProps) => {
  const { isLoading } = store.ui.loading;

  return (
    <div
      className={classNames("flex flex-col w-full max-h-full h-full overflow-y-auto px-7 pt-6", className, {
        "items-center justify-center text-center": center,
        hidden: isLoading,
      })}
    >
      {children}
    </div>
  );
};

interface CardTextProps {
  text: string;
  small?: boolean;
  className?: string;
  error?: boolean;
}

const CardText = ({ text, small = false, className = "", error }: CardTextProps) => {
  return text ? (
    <p
      data-test-id={error ? "error-message" : undefined}
      className={classNames(
        `text-gray-800 whitespace-pre-wrap mb-2 xs:mb-3 ${small ? "text-sm" : "text-base xs:text-lg"}`,
        className,
        {
          "!text-red-400": error,
        },
      )}
    >
      {text}
    </p>
  ) : (
    <></>
  );
};

interface CardHeadingProps {
  text: string;
  small?: boolean;
  className?: string;
}

const CardHeading = ({ text, small, className = "" }: CardHeadingProps) => {
  return text ? (
    <h1 className={`text-headings font-bold mb-2 xs:mb-3 ${small ? "text-2xl" : "text-2xl xs:text-3xl"} ${className}`}>
      {text}
    </h1>
  ) : (
    <></>
  );
};

interface CardSubheadingProps {
  text: string;
  small?: boolean;
  className?: string;
}

const CardSubheading = ({ text, small, className = "" }: CardSubheadingProps) => {
  return text ? (
    <p
      className={`!mb-6 text-subheadings whitespace-pre-wrap ${
        small ? "text-sm" : "text-base xs:text-lg"
      } ${className}`}
    >
      {text}
    </p>
  ) : (
    <></>
  );
};

interface CardIllustrationProps {
  src: string;
  alt?: string;
  className?: string;
}

const CardIllustration = ({ src, alt = "", className = "" }: CardIllustrationProps) => {
  return src ? (
    isIllustration(src) ? (
      <ReactSVG
        role="img"
        src={getIllustration(src)}
        aria-label={alt}
        useRequestCache={false}
        className={classNames("themed-svg my-6", className)}
      />
    ) : (
      <img className="my-6" src={src} alt={alt} />
    )
  ) : (
    <></>
  );
};

interface CardNavBarProps {
  disabledGoForward?: boolean;
  goForward?: (props: { disabled: boolean }) => void;
  onClose?: () => void;
  goBackward?: () => void;
}

const CardNavBar = (props: CardNavBarProps) => {
  const { t } = useTranslation();
  const { logAnalyticsActionInfo, analyticsActions } = useAnalytics();
  const { goBackward, goForward, index, percentage } = store.navigation;
  const { isLoading } = store.ui.loading;

  const { templateData } = useTemplateData();

  const handleGoForward = () => {
    if (!props.disabledGoForward) {
      logAnalyticsActionInfo(analyticsActions.GO_FORWARD);
    }

    if (props.goForward) {
      props.goForward({
        disabled: props.disabledGoForward ?? false,
      });
    } else if (!props.disabledGoForward) {
      goForward();
    }
  };

  const handleGoBackward = () => {
    if (props.goBackward) props.goBackward();
    else goBackward();
  };

  return (
    <div
      className={classNames(
        "rounded-lg flex content-between items-center px-6 pt-4 pb-6 transition-all w-full bg-white",
        {
          hidden: isLoading,
        },
      )}
      id="accessibility_nav_background"
    >
      {props.onClose ? (
        <Button
          rounded
          light
          className="!m-0"
          type="secondary"
          onClick={props.onClose}
          shape="circle"
          icon={<Icon icon="close" />}
          children={null}
        />
      ) : (
        <>
          <Button
            rounded
            light
            className={`!m-0 ${index <= 0 && "back-button-not-allowed"}`}
            type="secondary"
            onClick={handleGoBackward}
            shape="circle"
            icon={<Icon icon="arrow_left" />}
            falseDisabled={index <= 0}
            aria-label={t(`${I18N_BASE_PATH}.components.cardNavBar.button.ariaLabel`, "Voltar para a etapa anterior")}
            data-testid={`go-backward-button`}
            children={null}
          />
          <Progress
            className="!mx-4 !my-0 w-full relative"
            percent={percentage}
            strokeColor={
              templateData?.template?.colors?.progressbar ? "var(--color-progressbar)" : "var(--color-secondary)"
            }
            showInfo={false}
          />
          <Button
            data-testid={`go-forward-button`}
            disabled={props.disabledGoForward && process.env.REACT_APP_ENV === "test"}
            className={`!m-0 ${
              props.disabledGoForward
                ? "!border-none !bg-gray-300 !shadow-none !outline-none continue-button-not-allowed"
                : ""
            }`}
            type="primary"
            onClick={handleGoForward}
          >
            <>
              {templateData?.template?.texts?.backButton ??
                t(`${I18N_BASE_PATH}.components.cardNavBar.button.text`, "Continuar")}
            </>
          </Button>
        </>
      )}
    </div>
  );
};

Card.Body = observer(CardBody);
Card.Text = CardText;
Card.Heading = CardHeading;
Card.Subheading = CardSubheading;
Card.Illustration = CardIllustration;
Card.NavBar = observer(CardNavBar);

export default observer(Card);
