const I18N_BASE_PATH = "src.utils.formErrors.constants.formMessagesValidations";

const formMessagesValidations = {
  // PF
  valid_cpf: `${I18N_BASE_PATH}.validCpf`,
  // => "Informe um CPF válido"
  valid_birth_date: `${I18N_BASE_PATH}.validBirthDate`,
  // => "Informe uma data de nascimento válida"
  valid_email: `${I18N_BASE_PATH}.validEmail`,
  // => "Informe um email válido"
  valid_phone_number: `${I18N_BASE_PATH}.validPhoneNumber`,
  // => "Informe um número de telefone válido"
  valid_default_date: `${I18N_BASE_PATH}.validDefaultDate`,
  // => "Informe uma data válida"
  valid_nit: `${I18N_BASE_PATH}.validNit`,

  valid_license_plate: `${I18N_BASE_PATH}.validLicensePlate`,
  // => "Informe uma placa válida"

  // PJ
  valid_cnpj: `${I18N_BASE_PATH}.validCnpj`,
  // => "Informe um CNPJ válido"
  qsa_contact_cpf_duplicated: `${I18N_BASE_PATH}.qsaContactCpfDuplicated`,
  // => "Este CPF já foi informado"
  qsa_contact_email_duplicated: `${I18N_BASE_PATH}.qsaContactEmailDuplicated`,
  // => "Este email já foi informado"
  qsa_contact_phone_number_duplicated: `${I18N_BASE_PATH}.qsaContactPhoneNumberDuplicated`,
  // => "Este telefone já foi informado"

  // Others
  valid_cep: `${I18N_BASE_PATH}.validCep`,
  // => "Informe um CEP válido"
  required: {
    text: `${I18N_BASE_PATH}.required.text`,
    // => "Obrigatório"
    alert: `${I18N_BASE_PATH}.required.alert`,
    // => "Para continuar selecione o campo acima"
  },
};

export default formMessagesValidations;
