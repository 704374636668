import { useCallback, useEffect, useState } from "react";
import { notification } from "antd";

import Card from "@components/Card";
import { applyVariable, Regex } from "@utils/formatting";
import { FormCheckbox } from "@components/Form";
import { useAnalytics } from "@contexts/Analytics";
import { replaceVariables } from "@utils/markdown";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { buildTermIdGenerator } from "@store/user";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps.uniqueSteps";

const UsingTerms = (props) => {
  const [modalTerm, setModalTerm] = useState();

  const { t } = useTranslation();
  const { logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsSteps, analyticsActions } = useAnalytics();

  const termPrefix = "UsingTerms";
  const getTermId = buildTermIdGenerator(termPrefix);

  const userStore = store.user;
  const { goForward } = store.navigation;

  const areAllRequiredTermsAccepted =
    Object.keys(userStore.acceptedTerms).filter((k) => k.includes(termPrefix) && userStore.acceptedTerms[k].required)
      .length === props.terms.filter((term) => term.required).length;

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      if (!areAllRequiredTermsAccepted)
        notification.open({
          key: "NOTIFICATION_ACCEPT_TERMS",
          message:
            props.errors?.didntAcceptTerms?.text ??
            t(
              `${I18N_BASE_PATH}.documentType.errors.didntAcceptTerms`,
              "Para continuar, leia e aceite todos os termos",
            ),
          duration: 8,
          style: {
            marginTop: "calc(100vh - 250px)",
          },
          onClick: () => {
            notification.close("NOTIFICATION_ACCEPT_TERMS");
          },
        });
    } else {
      notification.close("NOTIFICATION_ACCEPT_TERMS");
      goForward();
    }
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_USING_TERMS);
  }, [logAnalyticsStepInfo]);

  const transformTermTextIntoElement = useCallback(
    ({ term, index }) => {
      // removing spaces and break lines
      const termLabelFormatted = term?.label?.replace(/[\r\n]+/gm, "")?.replace(/\s/g, "");

      if (term?.label?.match(Regex.urlLink)) {
        return applyVariable(term?.label, true, store.variables.flatten());
      } else if (termLabelFormatted?.match(Regex.modalLink)) {
        const termLabelSplitted = term?.label?.split("]<");
        const termTextModalFormatted = termLabelSplitted?.[1]
          ?.slice(0, -1)
          .replace(/[\r\n]+/gm, "")
          ?.replace(/\s/g, "");

        // reformatting the term label that contains the text, the link and the text to the modal
        const termLabelSplittedFormatted = `${term?.label?.split("]<")?.[0]}]<${termTextModalFormatted}>`;

        return replaceVariables(
          applyVariable(termLabelSplittedFormatted, true, store.variables.flatten()),
          /\[(.*?)>/gm,
          (_, _i) => {
            const textModal = termLabelSplitted[1].slice(0, -1);
            const textTerm = termLabelSplitted[0]?.split("[")[1];

            const termFormatted = {
              name: term?.name,
              text: textModal,
            };

            return (
              <a
                key={`TERM_${index}_A_${_i}`}
                className="underline text-secondary"
                {...{
                  href: "#",
                  onClick: (e) => {
                    e.preventDefault();
                    setModalTerm(termFormatted);
                  },
                }}
              >
                {textTerm}
              </a>
            );
          },
        );
      } else {
        return term?.label;
      }
    },
    [replaceVariables, setModalTerm, Regex],
  );

  const onAcceptedTermsChange = (term, index, checked) => {
    notification.close("NOTIFICATION_ACCEPT_TERMS");

    const termId = getTermId(term, index);

    if (checked) {
      userStore.addAcceptanceTerm({ ...term, id: termId });
    } else {
      userStore.removeAcceptanceTerm(termId);
    }

    logAnalyticsActionInfo(
      analyticsActions.TERM_ACCEPTED,
      {
        term: termId,
      },
      props.name,
    );
  };

  return (
    <Card>
      <Card.Body>
        {modalTerm ? (
          <>
            <Card.Heading text={modalTerm.name} />
            <Card.Text small text={modalTerm.text} />
          </>
        ) : (
          <>
            <Card.Heading text={props.heading ?? t(`${I18N_BASE_PATH}.usingTerms.heading`, "Qual seu documento?")} />
            <Card.Subheading
              text={
                props.subheading ??
                t(
                  `${I18N_BASE_PATH}.usingTerms.subheading`,
                  "Você precisa tê-lo em mãos para prosseguir com o cadastro.",
                )
              }
            />
            <div className="flex flex-col space-y-3">
              {props.terms?.map((term, i) => (
                <FormCheckbox
                  id={`TERM_${i}`}
                  key={`TERM_${i}`}
                  value={!!userStore.acceptedTerms[getTermId(term, i)]}
                  className={"flex items-center p-0.5 shadow-md animate-pulse-custom"}
                  onChange={(checked) => onAcceptedTermsChange(term, i, checked)}
                >
                  <label htmlFor={`TERM_${i}`}>{transformTermTextIntoElement({ term, index: i })}</label>
                </FormCheckbox>
              ))}
            </div>
          </>
        )}
      </Card.Body>
      <Card.NavBar
        goForward={customGoForward}
        disabledGoForward={props.terms?.length && !areAllRequiredTermsAccepted}
        onClose={!!modalTerm ? () => setModalTerm(null) : undefined}
      />
    </Card>
  );
};

export default observer(UsingTerms);
