import { useTranslation } from "react-i18next";

import Card from "@components/Card";
import { illustrations } from "@utils/illustrations";
import { observer } from "mobx-react-lite";
import store from "@store/index";

export default observer(function PageLoader() {
  const { t } = useTranslation();
  const { isLoading, isPageLoading } = store.ui.loading;

  if (isLoading && isPageLoading)
    return (
      <>
        <Card.Illustration src={illustrations.ONBOARDING} />
        <Card.Heading text={t("general.label.loading", "Carregando...")} small={false} />
        <Card.Subheading text={t("general.label.waitAMoment", "Aguarde um instante.")} small={false} />
      </>
    );

  return null;
});
