import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useIProovLiveness from "./hooks/useIProovLiveness";
import store from "@store/index";
import { useAnalytics } from "@contexts/Analytics";
import Card from "@components/Card";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps.uniqueSteps.livenessIProov";

export interface Props {
  name: string;
  heading?: string;
  subheading?: string;
  retryText?: string;
}

const LivenessIProovPreview = (props: Props) => {
  const [imagePreview, setImagePreview] = useState();
  const [disableGoForward, setDisableGoForward] = useState(true);

  const { t } = useTranslation();

  const passiveFaceLiveness = useIProovLiveness();
  const { logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions, analyticsSteps } = useAnalytics();

  const { goBackward } = store.navigation;

  const handleRetry = () => {
    logAnalyticsActionInfo(analyticsActions.PFL_PREVIEW_RETRY, {}, props.name);
    const retryEvent = new CustomEvent("retryFromPreview", { detail: { name: props.name } });
    document.dispatchEvent(retryEvent);
    goBackward();
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_LIVENESS_IPROOV_PREVIEW);
    setImagePreview(passiveFaceLiveness.getImagePreview());
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    if (imagePreview) {
      setDisableGoForward(false);
    }
  }, [imagePreview]);

  return (
    <Card>
      <Card.Body className="pfl-preview-step" center>
        <img
          className="h-56 mb-6 rounded-lg"
          src={imagePreview}
          alt={t(`${I18N_BASE_PATH}.previewStep.components.faceImage.alt`, "Foto do seu rosto")}
        />
        <Card.Heading small text={props.heading ?? t(`${I18N_BASE_PATH}.previewStep.heading`, "A foto ficou boa?")} />
        <Card.Subheading
          small
          text={props.subheading ?? t(`${I18N_BASE_PATH}.previewStep.subheading`, "Verifique se a face está nítida")}
        />
        <span
          className="text-secondary underline text-base cursor-pointer"
          onClick={handleRetry}
          data-testid="pfl-preview-step-retry-button"
        >
          {props.retryText ?? t("general.label.takeAnotherPhoto", "Tirar novamente")}
        </span>
      </Card.Body>
      <Card.NavBar disabledGoForward={disableGoForward} />
    </Card>
  );
};

export default observer(LivenessIProovPreview);
