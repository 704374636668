import { Icon } from "@combateafraude/react";
import { FormInput, InputTypes } from "@components/Form";
import formMessagesValidations from "@utils/formErrors";
import { useTranslation } from "react-i18next";

const Email = ({
  id,
  icon = "mail",
  maxLength,
  label,
  placeholder,
  onChange,
  goForwardButtonWasClicked,
  value,
  validity,
  onBlur,
  required,
  messagesValidation = formMessagesValidations.valid_email,
}) => {
  const { t } = useTranslation();

  return (
    <FormInput
      id={id}
      icon={<Icon icon={icon} />}
      type="email"
      label={label}
      className="!m-0"
      inputType={InputTypes.EMAIL}
      maxLength={maxLength}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      isToValidateErrorWithinFocus
      error={!validity && !!value?.length > 0}
      showRequiredMessage={goForwardButtonWasClicked && !value && !validity}
      required={required}
      errorMessage={t(messagesValidation, messagesValidation)}
      valid={validity}
      onBlur={onBlur}
    />
  );
};

export default Email;
