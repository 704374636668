import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePassiveFaceLiveness from "./hooks/usePassiveFaceLiveness";
import Card from "@components/Card";
import { useAnalytics } from "@contexts/Analytics";
import { Logger } from "@utils/logging";
import { observer } from "mobx-react-lite";
import store from "@store/index";

const I18N_BASE_PATH = "src.pages.onboarding.steps";

export interface Props {
  name: string;
  heading?: string;
  subheading?: string;
  retryText?: string;
}

const PFLPreview = (props: Props) => {
  const [imagePreview, setImagePreview] = useState();

  const { t } = useTranslation();

  const passiveFaceLiveness = usePassiveFaceLiveness();
  const { logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions, analyticsSteps } = useAnalytics();

  const { goBackward, goForward } = store.navigation;

  const customGoForward = async () => {
    Logger.console("PFLPreviewStep customGoForward");
    await passiveFaceLiveness.reset();
    goForward();
  };

  const handleRetry = () => {
    logAnalyticsActionInfo(analyticsActions.PFL_PREVIEW_RETRY, {}, props.name);
    goBackward();
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_PFL_PREVIEW);
    setImagePreview(passiveFaceLiveness.getImagePreview());
  }, []);

  return (
    <Card>
      <Card.Body className="pfl-preview-step" center>
        <img
          className="h-56 mb-6 rounded-lg"
          src={imagePreview}
          alt={t(`${I18N_BASE_PATH}.pflPreview.components.faceImage.alt`, "Foto do seu rosto")}
        />
        <Card.Heading
          small
          text={
            props.heading ?? t(`${I18N_BASE_PATH}.allSteps.uniqueSteps.pfl.previewStep.heading`, "A foto ficou boa?")
          }
        />
        <Card.Subheading
          small
          text={
            props.subheading ??
            t(`${I18N_BASE_PATH}.allSteps.uniqueSteps.pfl.previewStep.subheading`, "Verifique se a face está nítida")
          }
        />
        <span
          className="text-secondary underline text-base cursor-pointer"
          onClick={handleRetry}
          data-testid="pfl-preview-step-retry-button"
        >
          {props.retryText ?? t("general.label.takeAnotherPhoto", "Tirar novamente")}
        </span>
      </Card.Body>
      <Card.NavBar goForward={customGoForward} />
    </Card>
  );
};

export default observer(PFLPreview);
