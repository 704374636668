const I18N_BASE_PATH = "src.utils.accessibility.constants.defaultOnboardingIllustrationsAlternativeText";

export const defaultOnboardingIllustrationsAlternativeText: {
  [key: string]: string;
} = {
  illustration_camera: `${I18N_BASE_PATH}.illustrationCamera`,
  illustration_dataset: `${I18N_BASE_PATH}.illustrationDataset`,
  illustration_notFound: `${I18N_BASE_PATH}.illustrationNotFound`,
  illustration_onboarding: `${I18N_BASE_PATH}.illustrationOnboarding`,
  illustration_people: `${I18N_BASE_PATH}.illustrationPeople`,
  illustration_success: `${I18N_BASE_PATH}.illustrationSuccess`,
  illustration_phone: `${I18N_BASE_PATH}.illustrationPhone`,
  illustration_networkError: `${I18N_BASE_PATH}.illustrationNetworkError`,
};
