import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";

import Button from "@components/Button";

const I18N_BASE_PATH = "src.components.file.uploadMultipleFiles.multipleFileItem";

export const MultipleFileItem = ({ file, fileIcon, handleRemoveFile }) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex justify-between w-full items-center rounded-md border border-solid border-primary border-gray-200 px-2 py-1"
      key={file?.key}
    >
      <div className="flex items-center w-11/12">
        {fileIcon({ type: file?.extension, size: 24 })}
        <h3 className="ml-2 truncate">{file?.name}</h3>
      </div>
      <Button
        className="!m-0 !p-0 remove-file-button"
        data-testid={`remove-file-${file?.name.replace(".", "-")}`}
        title={t(`${I18N_BASE_PATH}.components.button.title`, "Remover arquivo")}
        onClick={() => handleRemoveFile(file?.key)}
        icon={
          <Icon danger size="sm" className="cursor-pointer !mb-1 hover:opacity-75 transition-opacity" icon="trash" />
        }
      />
    </div>
  );
};
