import { DocumentDetectorSdk } from "@combateafraude/document-detector";
import { useAnalytics, useAnalyticsContext } from "@contexts/Analytics";
import useEvents from "@hooks/useEvents";
import useTemplateData from "@hooks/useTemplateData";
import store from "@store/index";
import { removeSpecialCharsAndNumbers } from "@utils/formatting";
import { blobToImage } from "@utils/index";
import { Logger } from "@utils/logging";
import { mapperLanguageToSDKFormat } from "@utils/string";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const documentSides = {
  front: "front",
  back: "back",
  both: "both",
};

let documentDetector,
  _result = {},
  _currentSide = documentSides.front;

// const { DocumentDetectorSdk } = window["@combateafraude/document-detector"];

export default function useDocumentDetector() {
  const { i18n } = useTranslation();
  const { templateData, sdkToken } = useTemplateData();
  const { analyticsTrackingId } = useAnalyticsContext();
  const { logAnalyticsEventInfo, analyticsEvents } = useAnalytics();
  const { emitEvent } = useEvents();

  const documentStore = store.variables.document;
  const {
    documentIssuedCountry,
    selectedDocumentFileDD,
    selectedDocumentType,
    selectedSendDocumentType,
    doesDocumentHaveFrontAndBackSides,
  } = documentStore;

  const ddStep = useMemo(
    () =>
      templateData?.template?.steps.find((step) => {
        const stepName = removeSpecialCharsAndNumbers(step.name);
        return stepName === "DD";
      }),
    [templateData],
  );

  const sdkOptions = {
    ...ddStep?.sdkOptions?.initialize,
    analyticsSettings: {
      trackingId: analyticsTrackingId,
    },
    token: sdkToken,
    language: mapperLanguageToSDKFormat(i18n?.language),
    environment: process.env.REACT_APP_SDK_ENV,
    environmentSettings: {
      disableVisibilityChangeSecurity: true,
      disableFaceDetectionSecurity: true,
    },
    uploadSettings: {
      appearenceSettings: {
        backgroundColor: templateData?.template?.colors?.secondary,
      },
    },
    textSettings: { title: "", ...ddStep?.sdkOptions?.initialize?.textSettings },
  };

  if (ddStep?.sdkOptions?.playAudioDescription) {
    sdkOptions.playAudioDescription = ddStep.sdkOptions.playAudioDescription;
  }

  const initialize = async (customOptions = undefined) => {
    if (!_currentSide) {
      _result = {};
      _currentSide = documentSides.front;
      documentStore.setDocumentUrlFront("");
      documentStore.setDocumentUrlBack("");
    }

    if (isOnTemplate()) {
      Logger.console("DD initialize", {
        ddStep,
        options: {
          ...sdkOptions,
          ...customOptions,
        },
      });

      sdkOptions.finalizeImageCaptureEvenWithError =
        documentIssuedCountry !== "BR" && doesDocumentHaveFrontAndBackSides;

      const documentDetectionType = documentIssuedCountry === "BR" ? 0 : 1;
      documentDetector = new DocumentDetectorSdk({ ...sdkOptions, ...customOptions, documentDetectionType });
    }

    if (!documentDetector?.getIsInitialized()) {
      const permission = await documentDetector.initPermissions();
      const suported = documentDetector.isSupported();

      return permission && suported;
    }

    return true;
  };
  const setCurrentSideToFront = () => {
    _currentSide = documentSides.front;
  };

  const reset = async () => {
    try {
      if (documentDetector?.getIsInitialized()) {
        await documentDetector.close();
        await documentDetector.dispose();
      }
    } catch (error) {
      Logger.error("DD reset", error);
    }
  };

  const setDocumentUrl = (imageUrl) => {
    if (_currentSide === documentSides.both || _currentSide === documentSides.front) {
      documentStore.setDocumentUrlFront(imageUrl);
    } else {
      documentStore.setDocumentUrlBack(imageUrl);
    }
  };

  const handleSdkResultNational = async (sdkResult, options) => {
    const detectedDocumentSide = sdkResult?.assetParams?.documentSide?.toLowerCase();
    const isValidDetectedSide =
      selectedDocumentType &&
      selectedDocumentType?.toUpperCase() !== "ANY" &&
      Object.values(documentSides).includes(detectedDocumentSide);

    /**
     * Considera o side detectado pelo SDK apenas se um tipo de documento conhecido for selecionado na etapa "Tipo de documento",
     * pois esse tipo será enviado ao SDK e atualmente ele só valida o side (se é frente, verso ou both) se for de um tipo de documento conhecido.
     */
    if (isValidDetectedSide) {
      _result[detectedDocumentSide] = sdkResult;
    } else {
      _result[_currentSide] = sdkResult;
    }

    Logger.console("DD capture results (national)", {
      _currentSide,
      _result,
      captured: _result[_currentSide],
    });
    logAnalyticsEventInfo(analyticsEvents.DD_CLOSE, { side: _currentSide, result: _result });

    if (_result[_currentSide]?.imageUrl) {
      setDocumentUrl(_result[_currentSide].imageUrl);
    } else if (_result[detectedDocumentSide]?.imageUrl) {
      setDocumentUrl(_result[detectedDocumentSide].imageUrl);
    }

    await options.onGetResults(_currentSide, _result);
  };

  const handleSdkResultInternational = async (sdkResult, options) => {
    const detectedDocumentSide = sdkResult?.assetParams?.documentSide?.toLowerCase();

    if (!doesDocumentHaveFrontAndBackSides && _currentSide !== documentSides.both) {
      _result[_currentSide] = sdkResult;
      const frontImageResult = sdkResult.imagesResult.find((x) => x.documentSide === documentSides.front);
      _result[documentSides.front].imageUrl = frontImageResult?.imageUrl;
    } else if (_currentSide === documentSides.back) {
      const frontImageResult = sdkResult.imagesResult.find((x) => x.documentSide === documentSides.front);
      _result[documentSides.front].imageUrl = frontImageResult?.imageUrl;

      _result[documentSides.back] = sdkResult;
      const backImageResult = sdkResult.imagesResult.find((x) => x.documentSide === documentSides.front);
      _result[documentSides.back].imageUrl = backImageResult?.imageUrl;
    } else if (!!detectedDocumentSide && Object.values(documentSides).includes(detectedDocumentSide)) {
      _result[detectedDocumentSide] = sdkResult;
    } else {
      _result[_currentSide] = sdkResult;
    }

    Logger.console("DD capture results (international)", {
      _currentSide,
      _result,
      captured: _result[_currentSide],
    });
    logAnalyticsEventInfo(analyticsEvents.DD_CLOSE, { side: _currentSide, result: _result });

    if (sdkResult?.regulaId) {
      documentStore.setRegulaId(sdkResult?.regulaId);
    }

    /**
     * Important: use date-fns format patterns!
     */
    documentStore.setDocumentDateFormat("dd/MM/yyyy"); // TODO: use value returned from sdk when the backend starts returning "documentDateFormat"

    if (sdkResult?.textFieldsData) {
      documentStore.setTextFieldsData(sdkResult?.textFieldsData);
    }

    if (_currentSide !== documentSides.both && sdkResult.imagesResult && sdkResult.imagesResult.length > 0) {
      const frontUrl = sdkResult.imagesResult.find((x) => x.documentSide === documentSides.front).imageUrl;
      documentStore.setDocumentUrlFront(frontUrl);

      const backUrl = sdkResult.imagesResult.find((x) => x.documentSide === documentSides.back)?.imageUrl;
      documentStore.setDocumentUrlBack(backUrl);
    } else if (_result[_currentSide]?.imageUrl) {
      setDocumentUrl(_result[_currentSide].imageUrl);
    } else if (_result[detectedDocumentSide]?.imageUrl) {
      setDocumentUrl(_result[detectedDocumentSide].imageUrl);
    } else if (_currentSide !== documentSides.front && selectedSendDocumentType !== "upload") {
      const message = `The document ${selectedDocumentType?.toLowerCase()} side ${_currentSide} with detected document type ${detectedDocumentSide} -  imageUrl not found`;
      logAnalyticsEventInfo(analyticsEvents.DD_ERROR, message);
    }

    await options.onGetResults(_currentSide, _result);
  };

  const capture = async (containerNode, options) => {
    if (isBothSideCapture()) {
      _currentSide = documentSides.both;
    }

    Logger.console("DD capture", {
      metadata: {
        selectedDocumentType,
        selectedSendDocumentType,
        selectedDocumentFileDD,
        documentIssuedCountry,
        doesDocumentHaveFrontAndBackSides,
        _currentSide,
        _result,
      },
      initialized: documentDetector?.getIsInitialized(),
      hasContainerNode: !!containerNode,
      isOnTemplate: isOnTemplate(),
      sdkOptions: {
        ddStep: ddStep?.sdkOptions,
        options: options?.sdkOptions,
        sdkOptions,
      },
    });

    try {
      if (!documentDetector?.getIsInitialized()) {
        await documentDetector.initialize();
        const ddSdkDispatchedEvents = [
          "capture_invalid",
          "capture_failed",
          "back_capture_started",
          "front_capture_started",
        ];
        ddSdkDispatchedEvents.forEach((type) =>
          documentDetector?.addEventListener(type, (e) => {
            if (e?.type === "back_capture_started" || e?.type === "front_capture_started") {
              options?.onCaptureStarted?.(e.type);
            } else if (e?.type === "capture_invalid" || e?.type === "capture_failed") {
              options?.onCaptureInvalid?.(e.type);
            }

            return emitEvent({ code: `SDK_${type}`.toUpperCase(), detail: e?.detail || {} });
          }),
        );
      } else {
        await documentDetector.close();
      }
      await options.onInitialize();

      if (!!containerNode && isOnTemplate()) {
        logAnalyticsEventInfo(analyticsEvents.DD_START, {
          side: _currentSide,
          type: selectedDocumentType,
          sendType: selectedSendDocumentType,
          result: _result,
        });

        const cleanedSelectedDocumentFileDD = selectedDocumentFileDD.trim().toLowerCase();

        const imageFileType =
          cleanedSelectedDocumentFileDD === "uploadpdf"
            ? "PDF"
            : cleanedSelectedDocumentFileDD === "attachedpicture"
            ? "IMAGE"
            : "";

        const sdkResult = await documentDetector.capture(
          containerNode,
          [
            {
              attempts: 0,
              duration: ddStep?.sdkOptions?.capture?.captureSettings?.automaticCaptureTimeoutInSeconds ?? 30,
              mode:
                selectedSendDocumentType === "upload"
                  ? selectedSendDocumentType
                  : ddStep.sdkOptions?.capture?.captureSettings?.mode ?? "manual",
              imageFileType,
            },
            {
              attempts: 0,
              duration: 0,
              mode: selectedSendDocumentType === "upload" ? "upload" : "manual",
              imageFileType,
            },
          ],
          {
            issuedCountry: documentIssuedCountry,
            captureSettings: {
              ...ddStep.sdkOptions?.capture?.captureSettings,
              ...options?.sdkOptions?.capture?.captureSettings,
            },
            documentType: selectedDocumentType?.toLowerCase(),
            documentSide: _currentSide,
            doesDocumentHaveFrontAndBackSides: doesDocumentHaveFrontAndBackSides && _currentSide !== documentSides.both,
          },
        );

        if (!sdkResult) {
          logAnalyticsEventInfo(analyticsEvents.DD_EMPTY_RESULT, {
            side: _currentSide,
            result: _result,
            type: selectedDocumentType,
            sendType: selectedSendDocumentType,
          });

          return;
        }

        documentStore.setDetectedDocumentType(sdkResult?.assetParams?.documentType ?? selectedDocumentType);

        if (documentIssuedCountry === "BR") {
          handleSdkResultNational(sdkResult, options);
        } else {
          handleSdkResultInternational(sdkResult, options);
        }
      } else {
        Logger.error("DD capture", {
          initialized: documentDetector?.getIsInitialized(),
          containerNode,
        });
        logAnalyticsEventInfo(analyticsEvents.DD_ERROR, {
          message: "DD capture called without container node or not initialized",
          initialized: documentDetector?.getIsInitialized(),
          containerNode,
        });
        options.onError();
      }
    } catch (error) {
      Logger.error("DD capture", error);
      logAnalyticsEventInfo(analyticsEvents.DD_ERROR, {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)).replace(/[^a-z0-9 -,_.?!:{}/]/gi, ""),
      });
      options.onError();
    }
  };

  const isOnTemplate = () => !!ddStep;

  const changeDocumentSide = async (options) => {
    Logger.console("DD changeDocumentSide", {
      options,
      _result,
      _currentSide,
    });
    logAnalyticsEventInfo(analyticsEvents.DD_INFO, {
      result: _result,
      currentSide: _currentSide,
      step: "changeDocumentSide",
      options,
    });

    await documentDetector.close();

    if (!!options?.newSide) _currentSide = options.newSide;
  };

  const getResults = (side = undefined) => _result[side ?? _currentSide];

  const getPreview = (side = "", ignoreBlob = false) => {
    let result;
    if (!side) {
      result = getResults(_currentSide);
      if (result?.both) {
        result = result.both;
      }
    } else if (side && !!_result?.[side]) {
      result = _result[side];
    } else {
      return undefined;
    }

    if (!result) return "";

    const { pdfPreview } = isPdfPreview();

    if (result.blob?.type?.startsWith("image/" || (result.blob && pdfPreview)) && !ignoreBlob)
      return blobToImage(result.blob);

    return result.imageUrl;
  };

  const getResultKey = (side) => {
    logAnalyticsEventInfo(analyticsEvents.DD_INFO, {
      side,
      result: _result,
      currentSide: _currentSide,
      step: "getResultKey",
    });

    let result;

    if (!side) {
      result = getResults();
    } else if (side && !!_result?.[side]) {
      result = _result[side];
    } else {
      return undefined;
    }

    if (!result) return;

    return result.imageKey;
  };

  const getResultBucket = (side) => {
    let result;

    if (!side) {
      result = getResults();
    } else if (side && !!_result?.[side]) {
      result = _result[side];
    } else {
      return undefined;
    }

    if (!result?.bucketParams) return;

    return result.bucketParams.bucket;
  };

  const getCurrentSide = () => _currentSide;

  const getDocumentType = () => {
    let resultSide = _currentSide;
    if (!!_result?.[documentSides.front]) {
      resultSide = documentSides.front;
    } else if (!!_result?.[documentSides.back]) {
      resultSide = documentSides.back;
    } else if (!!_result?.[documentSides.both]) {
      resultSide = documentSides.both;
    }

    return _result?.[resultSide]?.assetParams?.documentType.toUpperCase();
  };

  const isBothSideCapture = () => {
    // Se é fluxo de captura de documentos BR, ou seja, sem etapa de seleção de país ou Brasil como país selecionado
    const isBrazilianFlow = !documentIssuedCountry || documentIssuedCountry === "BR";
    // Documentos que sempre devem enviar apenas uma imagem, pois tem apenas um lado
    const onlyOneSide = ["PASSPORT", "CRLV"].includes(selectedDocumentType.toUpperCase());
    // Documentos que podem ter dois lados, mas que podem ser enviados de forma aberta (ambos lados no mesmo arquivo)
    const supportForBothSides = ["RG", "CNH"].includes(selectedDocumentType.toUpperCase());
    // Documentos que sempre tem frente e verso e não podem ser enviados juntos em apenas um arquivo/imagem (condição do SDK)
    const notHaveBothSideSupport = ["RNE", "RNM", "CTPS"].includes(selectedDocumentType.toUpperCase());
    // Se foi selecionado fluxo de upload de PDF
    const isUploadPdf = selectedDocumentFileDD === "uploadPdf";

    // Nunca habilitar o envio/captura de apenas uma imagem/arquivo quando o documento selecionado for RNE, RNM ou CTPS, pois o SDK não permite
    // Essa verificação não deveria estar no SDK, futuramente quando isso for revisto, essa condicional pode ser removida
    // Essa condicional deve ser prioritária sempre
    if (notHaveBothSideSupport) {
      return false;
    }

    // Envia apenas uma image/arquivo se for marcado que documento não tem frente e verso
    if (doesDocumentHaveFrontAndBackSides === false) {
      return true;
    }
    // Envia apenas uma image/arquivo se documento selecionado tem apenas um lado
    if (onlyOneSide) {
      return true;
    }
    // Envia apenas uma image/arquivo se for upload de PDF e o documento for internacional (não brasileiro)
    // Essa condicional existe pois no internacional valida as duas imagens juntas e não existe preview de dois PDFs
    if (isUploadPdf && !isBrazilianFlow) {
      return true;
    }
    // Envia apenas uma image/arquivo se for upload de PDF e o documento selecionado deve ser enviado com ambos os lados
    // Pensado para documentos escaneados de forma aberta ou documentos digitais, ambos geralmente são PDFs
    if (isUploadPdf && supportForBothSides) {
      return true;
    }
    return false;
  };

  const isPdfPreview = () => {
    const pdfPreview = selectedDocumentFileDD === "uploadPdf";

    const showPdfPreview = ddStep.previewPDF !== false;

    return {
      pdfPreview,
      showPdfPreview,
    };
  };

  const resetResult = () => {
    logAnalyticsEventInfo(analyticsEvents.DD_INFO, {
      result: _result,
      currentSide: _currentSide,
      step: "resetResult",
    });

    _result = {};
  };

  return {
    isOnTemplate,
    isBothSideCapture,
    isPdfPreview,
    initialize,
    reset,
    capture,
    changeDocumentSide,
    getResults,
    getResultKey,
    getResultBucket,
    getPreview,
    getCurrentSide,
    getDocumentType,
    resetResult,
    setCurrentSideToFront,
  };
}
