import { useEffect, useMemo, useCallback, useState } from "react";
import { message, Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";

import TextMessageFeedback from "@components/TextMessageFeedback";
import ViewMore from "@components/ViewMore";
import { MultipleFileItem } from "./MultipleFileItem";
import { observer } from "mobx-react-lite";
import store from "@store/index";

import { getUploadUrl, uploadTempFile } from "../../services";

const CONTENT_TYPE_UNIFY_FILES = "application/pdf";
const MAX_TOTAL_FILES_ACCEPTED = 12;

const filesExtensions = {
  pdf: "pdf-file",
  jpg: "jpg-file",
  jpeg: "jpeg-file",
  png: "png-file",
  file: "file",
};

const I18N_BASE_PATH = "src.components.file.uploadMultipleFiles.uploadMultipleFiles";

const UploadMultipleFiles = ({ id, name, title, acceptedExtensions, maxSize, isRequired }) => {
  const { t } = useTranslation();

  const documentStore = store.variables.document;

  const [filesList, setFilesList] = useState([]);
  const [isDisplayingFullFilesList, setIsDisplayingFullFilesList] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t(`${I18N_BASE_PATH}.states.errorMessage`, "Ocorreu algum erro"));
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const acceptedFileExtensions = useMemo(() => {
    if (Array.isArray(acceptedExtensions) && acceptedExtensions.length > 0) {
      return acceptedExtensions;
    } else {
      return ["pdf", "csv", "txt", "png", "jpeg", "jpg", "svg"];
    }
  }, [acceptedExtensions]);

  // dynamic texts for file upload button
  const filesTitleValue = useMemo(() => {
    if (uploading) return t(`${I18N_BASE_PATH}.hooks.filesTitleValue.uploading`, "Carregando arquivos...");

    if (uploaded && filesList?.length > 1)
      return t(`${I18N_BASE_PATH}.hooks.filesTitleValue.uploadedOne`, "Arquivos anexados");
    if (uploaded && filesList?.length === 1)
      return t(`${I18N_BASE_PATH}.hooks.filesTitleValue.uploadedPlural`, "Arquivo anexado");

    return title;
  }, [uploading, uploaded, title, filesList, t]);

  // dynamic icons for the file upload button
  const fileIcon = useCallback(
    ({ type }) => {
      return <Icon className="text-secondary !text-2xl" icon={filesExtensions[type] || filesExtensions["file"]} />;
    },
    [filesExtensions],
  );

  useEffect(() => {
    if (!uploaded) return;

    const newFilesInStore = documentStore.multipleUploadedFiles.filter((file) => file.id !== id);

    if (filesList.length > 0) {
      newFilesInStore.push({
        id,
        title,
        filesList, // save to restore uploaded files when changing steps
        keys: filesList.map((file) => file.key), // save all keys files to unify them later at step DONE
        type: name,
        contentType: CONTENT_TYPE_UNIFY_FILES,
        required: isRequired,
      });
    }

    documentStore.setMultipleUploadedFiles(newFilesInStore);
  }, [uploaded, filesList]);

  useEffect(() => {
    if (uploaded) return;

    const fileInStore = documentStore.multipleUploadedFiles.find((file) => file.id === id);
    if (!fileInStore) return;

    setFilesList(fileInStore.filesList);
    setUploaded(true);
  }, [uploaded]);

  useEffect(() => {
    filesList?.length < MAX_TOTAL_FILES_ACCEPTED && setIsErrorVisible(false);
    setUploaded(filesList?.length > 0 ? true : false);
  }, [filesList]);

  const handleRemoveFile = useCallback(
    (key) => {
      const fileListUpdated = filesList?.filter((file) => file?.key !== key);

      setFilesList(fileListUpdated);
    },
    [filesList],
  );

  const handleChange = useCallback(
    async ({ file, fileList }) => {
      setUploading(true);
      setUploaded(false);
      setIsErrorVisible(false);

      // if the user has tried to upload more files than allowed, returns an error message
      if (fileList?.length > MAX_TOTAL_FILES_ACCEPTED) {
        const maxTotalFilesAccepted = String(MAX_TOTAL_FILES_ACCEPTED);
        setErrorMessage(
          t(
            `${I18N_BASE_PATH}.methods.handleChange.errorMessage.maxTotalFilesAccepted`,
            `O total máximo de arquivos a serem anexados são ${maxTotalFilesAccepted}`,
            { maxTotalFilesAccepted },
          ),
        );
        setIsErrorVisible(true);
        setUploading(false);
        setUploaded(true);

        return;
      }

      const fileIsAlreadyUploaded = filesList?.some((f) => f?.name === file?.name);

      // if the file has already uploaded, returns an error message
      if (fileIsAlreadyUploaded) {
        setUploading(false);
        setUploaded(true);
        const fileName = file?.name;

        return message.warn({
          duration: 3,
          content: t(
            `${I18N_BASE_PATH}.methods.handleChange.errorMessage.maxTotalFilesAccepted`,
            `O arquivo ${fileName} já foi anexado`,
            { fileName },
          ),
        });
      }

      let fileSizeInMb = file.size / 1024 / 1024;
      // if the file size is bigger than allowed, returns an error message
      if (fileSizeInMb > maxSize) {
        setErrorMessage(
          t(`general.message.uploadFiles.maxSize`, `O arquivo deve ter no máximo ${maxSize}MB`, { maxSize }),
        );
        setIsErrorVisible(true);
        setUploading(false);
        setUploaded(true);

        return;
      }

      let isTypeAccepted = acceptedFileExtensions.some((ext) => file.type.includes(ext));
      // if the file type isn't accepted, returns an error message
      if (!isTypeAccepted) {
        const fileExtensions = acceptedFileExtensions.map((ext) => ext.toUpperCase()).join(", ");

        setErrorMessage(
          t(
            "general.message.uploadFiles.fileExtensions",
            `O arquivo deve possuir uma das extensões: ${fileExtensions}`,
            {
              fileExtensions,
            },
          ),
        );
        setIsErrorVisible(true);
        setUploading(false);
        setUploaded(true);
        return;
      }

      const fileExtension = file?.name?.split(".")?.pop()?.toLowerCase();

      let res = undefined;
      try {
        // signing the url of the file
        res = await getUploadUrl({ contentType: file.type }).then((res) => res.json());
      } catch (error) {
        setErrorMessage(
          t("general.message.uploadFiles.filesUploadedError", "Ocorreu um problema ao carregar os arquivos"),
        );
        setIsErrorVisible(true);
        setUploading(false);
        setUploaded(true);

        return;
      }

      if (res?.uploadUrl) {
        try {
          // saving the image in a temporary bucket
          await uploadTempFile(res?.uploadUrl, file);
        } catch (error) {
          setErrorMessage(
            t("general.message.uploadFiles.filesUploadedError", "Ocorreu um problema ao carregar os arquivos"),
          );
          setIsErrorVisible(true);
          setUploading(false);
          setUploaded(true);

          return;
        }
      }

      setFilesList((old) => [...old, { key: res?.key, name: file?.name, extension: fileExtension }]);
      setUploading(false);
      setUploaded(true);
    },
    [maxSize, acceptedFileExtensions, getUploadUrl, uploadTempFile, t],
  );

  return (
    <>
      <div className="mb-8">
        <Upload
          data-testid={`upload-file-${name}`}
          accept={acceptedFileExtensions.map((ext) => "." + ext).join(",")}
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleChange}
          multiple
          disabled={uploading}
          fileList={filesList}
          className="flex items-center w-full flex-col"
        >
          <div>
            <div
              className="flex items-center w-full h-full p-3 border border-solid border-gray-300 rounded-lg"
              id="style-border"
            >
              <Icon className={`text-secondary !text-2xl`} icon="files" />
              <span className="ml-4 mr-4 w-full bg-red select-none overflow-hidden">{filesTitleValue}</span>
              {!!uploading ? (
                <LoadingOutlined className="!text-primary text-lg" height="2rem" />
              ) : !!uploaded ? (
                <Icon className={`!text-secondary !text-2xl`} icon="check_d" />
              ) : (
                <Icon className={`!text-secondary !text-2xl`} icon="upload" />
              )}
            </div>
          </div>
        </Upload>
        <TextMessageFeedback
          isVisible={isErrorVisible}
          messages={{ error: { text: errorMessage } }}
          validations={{ error: true }}
        />
        <section
          className={`flex gap-2 flex-col w-full transition-all duration-500 ${
            filesList?.length > 0 && uploaded ? "opacity-100 mt-4" : "opacity-0"
          }`}
        >
          {filesList?.length > 0 &&
            uploaded &&
            filesList
              ?.slice(0, 3)
              ?.map((file) => (
                <MultipleFileItem key={file.key} file={file} fileIcon={fileIcon} handleRemoveFile={handleRemoveFile} />
              ))}

          {filesList?.length > 0 &&
            isDisplayingFullFilesList &&
            uploaded &&
            filesList
              ?.slice(3, filesList?.length)
              ?.map((file) => (
                <MultipleFileItem key={file.key} file={file} fileIcon={fileIcon} handleRemoveFile={handleRemoveFile} />
              ))}

          {filesList?.length > 3 && uploaded && (
            <ViewMore
              type="link"
              title={
                !isDisplayingFullFilesList
                  ? t(`${I18N_BASE_PATH}.components.viewMore.title.all`, `Exibir todos (${filesList?.length})`, {
                      filesCount: filesList?.length,
                    })
                  : t(`${I18N_BASE_PATH}.components.viewMore.title.less`, "Exibir menos")
              }
              text
              icon={!isDisplayingFullFilesList && "plus"}
              iconStyles="!text-md"
              onClick={() => setIsDisplayingFullFilesList((old) => !old)}
            />
          )}
        </section>
      </div>
    </>
  );
};

export default observer(UploadMultipleFiles);
