import { OptionButton as CafOptionButton } from "@combateafraude/react";
import classNames from "classnames";

const OptionButton = ({ children, className, selected, ...rest }) => {
  return (
    <CafOptionButton
      className={classNames(
        "flex items-center !border !rounded-xl !text-primary whitespace-pre-wrap overflow-hidden text-left !shadow-sm",
        `${selected ? "option-button-selected !bg-primary-light !border-primary" : "option-button !border-gray-100"}`,
        className,
      )}
      id="option-button"
      {...rest}
    >
      {children}
    </CafOptionButton>
  );
};

export default OptionButton;
