import { fetchWithRetry } from "@utils/index";

export async function sendWebHookNotify(attemptId, token) {
  if (!!attemptId) {
    try {
      const res = await fetchWithRetry(
        `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/onboardings/notify/${token}`,
        {
          method: "POST",
          body: JSON.stringify({ attemptId: attemptId }),
        },
      );
      return { status: res.status, response: await res.json() };
    } catch (error) {
      return { status: error.status, response: error.response.data, error: error.message };
    }
  }
}
