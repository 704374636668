import { useMemo } from "react";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";

import ProfileApprovedIllustration from "@assets/images/illustrations/success.svg";
import Card from "@components/Card";
import Button from "@components/Button";
import { FormInput } from "@components/Form";

import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";

import { observer } from "mobx-react-lite";
import store from "@store/index";
import { applyVariable } from "@utils/formatting";
import useTemplateData from "@hooks/useTemplateData";

const I18N_BASE_PATH = "src.pages.onboarding.steps.special.profileApproved";

const ProfileApproved = () => {
  // const { steps } = useSteps();

  const { t } = useTranslation();

  const { templateData } = useTemplateData();

  const onboardingType = useMemo(() => templateData?.template?.type, [templateData]);

  const companyQsaStatus = store.variables.company.companyQsaStatus;
  const { steps } = store.navigation;

  const filteredQsaStatus = useMemo(() => {
    const completed = companyQsaStatus.filter(({ status }) => status === "COMPLETED");
    const pending = companyQsaStatus.filter(({ status }) => status !== "COMPLETED");

    return {
      completed,
      pending,
    };
  }, [companyQsaStatus]);

  const finalStepButtonProps = useMemo(() => {
    const finalStep = steps[steps.length - 1];

    if (!finalStep) return;

    return {
      redirectUrl: applyVariable(finalStep.props.redirectUrl ?? "", true, store.variables.flatten()),
      redirectText: applyVariable(finalStep.props.redirectText ?? "", true, store.variables.flatten()),
    };
  }, [steps]);

  return (
    <Card>
      <Card.Body center={onboardingType !== "PJ_QSA" && onboardingType !== "PJ"}>
        <Card.Illustration
          src={ProfileApprovedIllustration}
          alt={t(defaultOnboardingIllustrationsAlternativeText?.illustration_success) || ""}
        />
        <Card.Heading className="text-center" text={t(`${I18N_BASE_PATH}.components.heading`, "Tudo certo")} />
        {onboardingType === "PF" && (
          <Card.Subheading
            text={t(
              `${I18N_BASE_PATH}.components.subheading.registrationAlreadyCreated`,
              "Este cadastro já foi concluído.",
            )}
          />
        )}
        {(onboardingType === "PJ_QSA" || onboardingType === "PJ") && (
          <>
            <Card.Subheading
              className="text-center"
              text={t(
                `${I18N_BASE_PATH}.components.subheading.registrationAlreadyCreated`,
                "Seu cadastro está completo e já foi enviado para análise! Assim que todos os sócios concluírem suas etapas de onboarding, você receberá um e-mail com seu login e senha de acesso.",
              )}
            />
            {!!companyQsaStatus.length && (
              <>
                {filteredQsaStatus.completed.length > 0 && (
                  <div className="flex flex-col text-left w-full">
                    <Card.Text
                      small
                      className="font-bold !mb-6"
                      text={t("general.message.flowCompleted", "Fluxo completo")}
                    />
                    {filteredQsaStatus.completed.map((qsaPartner, i) => (
                      <FormInput
                        key={`SOCIO_COMPLETED_${i}`}
                        id={`socio_completed_${i}`}
                        label={t("general.label.partner", "Sócio")}
                        className="w-full !border-primary !border rounded-md pointer-events-none"
                        icon={<Icon icon="person" />}
                        valid={true}
                        value={qsaPartner.name}
                        uppercase
                        textOnly
                      />
                    ))}
                  </div>
                )}
                {filteredQsaStatus.pending.length > 0 && (
                  <div className="flex flex-col text-left w-full">
                    <Card.Text
                      small
                      className="font-bold !mb-6"
                      text={t("general.message.flowUncompleted", "Aguardando completar")}
                    />
                    {filteredQsaStatus.pending.map((qsaPartner, i) => (
                      <FormInput
                        key={`SOCIO_NOT_COMPLETED_${i}`}
                        id={`socio_not_completed_${i}`}
                        label={t("general.label.partner", "Sócio")}
                        className="w-full pointer-events-none"
                        icon={<Icon icon="person" />}
                        suffix={<Icon className="text-gray-400" icon="clock" />}
                        value={qsaPartner.name}
                        uppercase
                        textOnly
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        )}
        {finalStepButtonProps?.redirectUrl && (
          <Button
            className="mb-4"
            type="primary"
            onClick={() => (window.location.href = finalStepButtonProps.redirectUrl)}
          >
            {finalStepButtonProps.redirectText ||
              t(`${I18N_BASE_PATH}.components.button.continueRegistration`, "Continuar cadastro")}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default observer(ProfileApproved);
