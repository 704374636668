import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

import noCompanies from "@assets/images/illustrations/no-companies.svg";

const NoCompanies = () => {
  const { t: translate } = useTranslation();

  return (
    <div className="flex flex-col w-full h-full items-center justify-center pt-6">
      <ReactSVG src={noCompanies} role="img" className="themed-svg" />
      <h1 className="text-3xl pt-4 font-bold">
        {translate("src.pages.onboarding.steps.companySearch.components.noCompanies.title", "No company was found")}
      </h1>
      <h3 className="text-base pt-4 text-center">
        {translate(
          "src.pages.onboarding.steps.companySearch.components.noCompanies.description",
          "Sorry we were unable to find the company details automatically. Please try again or click next to type in the details manually.",
        )}
      </h3>
    </div>
  );
};

export default NoCompanies;
