import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.helpButton";

/** Button that redirects the user to a client help center */
const HelpButton = ({ helpButtonLink, useNewTemplate = false }) => {
  const { t } = useTranslation();

  return (
    <a
      href={helpButtonLink}
      target="_blank"
      rel="noopener noreferrer"
      title={t(`${I18N_BASE_PATH}.title`, "Clique para ir a central de ajuda")}
      className={`${
        useNewTemplate ? "text-black hover:text-black" : "text-headings hover:text-headings"
      } z-20 font-semibold text-sm flex items-center hover:opacity-90 justify-center opacity-100 transition-colors ease-in-out bg-white px-2 py-1 sm:py-2 rounded-full`}
      id="help-button-link"
    >
      {t("general.label.help", "Ajuda")}
    </a>
  );
};

export default HelpButton;
