import { Icon } from "@combateafraude/react";
import { Switch, Dropdown } from "antd";
import { Trans, useTranslation } from "react-i18next";
import accessibilityIcon from "@assets/images/accessibility.png";
import useTemplateData from "@hooks/useTemplateData";

const I18N_BASE_PATH = "src.components.accessibilityDropdown";

export interface Props {
  isMonochromeMode: boolean;
  isHighContrastMode: boolean;
  isDefaultFontSize: boolean;
  toggleHighContrastTheme: () => void;
  toggleMonochromeTheme: () => void;
  toggleFontSize: () => void;
}

export default function AccessibilityDropdown(props: Props) {
  const {
    isMonochromeMode,
    isHighContrastMode,
    isDefaultFontSize,
    toggleHighContrastTheme,
    toggleMonochromeTheme,
    toggleFontSize,
  } = props;
  const { t } = useTranslation();

  const { templateData } = useTemplateData();

  if (!templateData) return null;

  const shouldRenderAccessibilityDropdown =
    templateData?.template.configurations?.accessibility?.highContrast ||
    templateData?.template.configurations?.accessibility?.increaseFont ||
    templateData?.template.configurations?.accessibility?.monochromeMode;

  return (
    <div className="z-20 flex items-center justify-center">
      {shouldRenderAccessibilityDropdown && (
        <Dropdown
          className="opacity-100"
          trigger={["click"]}
          overlay={
            <div
              className="accessibility-card rounded-lg border border-solid border-gray-300 shadow-md mt-4 px-5 py-7 bg-white"
              id="accessibility_menu"
            >
              {templateData?.template.configurations?.accessibility?.highContrast && (
                <ul className="flex flex-col px-1 py-1.5">
                  <li className="flex flex-col gap-1 justify-self-end">
                    <div className="flex gap-10 items-center justify-between">
                      <div className="flex items-center justify-between" id="accessibility_svg_icon">
                        <Icon icon="high-contrast" className="text-black pr-3" size="lg" />
                        <h4 className="font-bold text-sm text-black" id="accessibility_text_black">
                          {t(`${I18N_BASE_PATH}.components.accessibilityCard.highContrast`, "Alto Contraste")}
                        </h4>
                      </div>
                      <div className="flex flex-col gap-1 items-center justify-center">
                        <Switch className="w-9" checked={isHighContrastMode} onChange={toggleHighContrastTheme} />
                      </div>
                    </div>
                  </li>
                </ul>
              )}
              {templateData?.template.configurations?.accessibility?.increaseFont && (
                <ul className="flex flex-col px-1 py-1.5">
                  <li className="flex flex-col gap-1 justify-self-end">
                    <div className="flex gap-10 items-center justify-between">
                      <div className="flex items-center justify-between" id="accessibility_svg_icon">
                        <Icon icon="text_size" className="text-black pr-3" size="lg" />
                        <h4 className="font-bold text-sm text-black" id="accessibility_text_black">
                          <Trans i18nKey={`${I18N_BASE_PATH}.components.accessibilityCard.textSize`}>
                            Tamanho <br /> dos textos
                          </Trans>
                        </h4>
                      </div>
                      <div className="flex flex-col gap-1 items-center justify-center">
                        <Switch className="w-9" checked={!isDefaultFontSize} onChange={toggleFontSize} />
                      </div>
                    </div>
                  </li>
                </ul>
              )}
              {templateData?.template.configurations?.accessibility?.monochromeMode && (
                <ul className="flex flex-col px-1 py-1.5">
                  <li className="flex flex-col gap-1 justify-self-end">
                    <div className="flex gap-10 items-center justify-between">
                      <div className="flex items-center justify-between" id="accessibility_svg_icon">
                        <Icon icon="monochrome" className="text-black pr-3" size="lg" />
                        <h4 className="font-bold text-sm text-black" id="accessibility_text_black">
                          <Trans i18nKey={`${I18N_BASE_PATH}.components.accessibilityCard.monochromeMode`}>
                            Modo <br /> Monocromático
                          </Trans>
                        </h4>
                      </div>
                      <div className="flex flex-col gap-1 items-center justify-center">
                        <Switch className="w-9" checked={isMonochromeMode} onChange={toggleMonochromeTheme} />
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </div>
          }
        >
          <p className="!p-1 bg-white rounded-full pointer-events-auto cursor-pointer hover:opacity-80 transition-all duration-300 font-bold text-sm">
            <img
              src={accessibilityIcon}
              alt={t(
                `${I18N_BASE_PATH}.accessibilityDropdown.accessibilityIconAltText`,
                "Ícone Universal de acessibilidade",
              )}
              width={28}
              height={28}
            />
          </p>
        </Dropdown>
      )}
    </div>
  );
}
