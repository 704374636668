const I18N_BASE_PATH = "src.utils.rules";

export const rules = {
  ADMINISTRATOR_PARTNER_DONT_NEED_INFORM_PARTNERS_DATA: {
    id: "ADMINISTRATOR_PARTNER_DONT_NEED_INFORM_PARTNERS_DATA",
    name: `${I18N_BASE_PATH}.ADMINISTRATOR_PARTNER_DONT_NEED_INFORM_PARTNERS_DATA.name`,
    // => "Apenas Sócio Administrador faz fluxo PF"
    description: `${I18N_BASE_PATH}.ADMINISTRATOR_PARTNER_DONT_NEED_INFORM_PARTNERS_DATA.description`,
    // => "Apenas sócios administradores devem fazer o fluxo PF"
  },
  SET_FLOW_WHEN_USER_IS_A_ADMINISTRATOR_PARTNER: {
    id: "SET_FLOW_WHEN_USER_IS_A_ADMINISTRATOR_PARTNER",
    name: `${I18N_BASE_PATH}.SET_FLOW_WHEN_USER_IS_A_ADMINISTRATOR_PARTNER.name`,
    // => "Seta a opção SOCIO-ADM no fluxo"
    description: `${I18N_BASE_PATH}.SET_FLOW_WHEN_USER_IS_A_ADMINISTRATOR_PARTNER.description`,
    // => "Usado para inserir a opção SOCIO-ADM quando detectar que o usuário selecionado no QSA é um sócio administrador"
  },
};

export const hasRule = (ruleId = "", rules = []) => {
  if (!ruleId || rules.length === 0) return false;
  return rules?.filter((rule) => rule?.id === ruleId).length > 0;
};
