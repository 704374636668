export const countriesWithPersonId = {
  AR: "src.utils.documents.supportedDocs.AR",
  BR: "src.utils.documents.supportedDocs.BR",
  CL: "src.utils.documents.supportedDocs.CL",
  CN: "src.utils.documents.supportedDocs.CN",
  CO: "src.utils.documents.supportedDocs.CO",
  DK: "src.utils.documents.supportedDocs.DK",
  FI: "src.utils.documents.supportedDocs.FI",
  HK: "src.utils.documents.supportedDocs.HK",
  ID: "src.utils.documents.supportedDocs.ID",
  MY: "src.utils.documents.supportedDocs.MY",
  MX: "src.utils.documents.supportedDocs.MX",
  PH: "src.utils.documents.supportedDocs.PH",
  SG: "src.utils.documents.supportedDocs.SG",
  ZA: "src.utils.documents.supportedDocs.ZA",
  SE: "src.utils.documents.supportedDocs.SE",
  TR: "src.utils.documents.supportedDocs.TR",
  GH: "src.utils.documents.supportedDocs.GH",
  IN: "src.utils.documents.supportedDocs.IN",
  KE: "src.utils.documents.supportedDocs.KE",
  NG: "src.utils.documents.supportedDocs.NG",
  ES: "src.utils.documents.supportedDocs.ES",
  PL: "src.utils.documents.supportedDocs.PL",
};
