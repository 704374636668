import { useCallback, useEffect, useMemo, useState } from "react";
import Card from "@components/Card";
import { useAnalytics } from "@contexts/Analytics";
import useEvents from "@hooks/useEvents";
import store from "@store/index";
import { observer } from "mobx-react-lite";
import BlankCompany from "./components/BlankCompany";
import RenderCompanies from "./components/RenderCompanies";
import NoCompanies from "./components/NoCompanies";

const CompanyInformation = (props) => {
  const { emitEvent } = useEvents();
  const { company: companyStore } = store.variables;
  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const [searched, setSearched] = useState(false);

  const { logAnalyticsStepInfo, analyticsSteps } = useAnalytics();

  const handleSelectCompany = useCallback(({ id, availableServices, data }) => {
    companyStore.setCompanySearchedSelected({
      id,
      kybServices: {
        creditReportId: availableServices?.creditReportId ?? null,
        businessIdentityId: availableServices?.businessIdentityId ?? null,
      },
    });
  }, []);

  const hasCompany = useMemo(() => companyStore.companies.length > 0, [companyStore.companies]);

  const isDisabledGoForward = useMemo(() => {
    const hasSomeItemSelected = companyStore.companySearchedSelected.id !== undefined;
    return hasCompany && !hasSomeItemSelected;
  }, [companyStore.companies, companyStore.companySearchedSelected, hasCompany]);

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_COMPANY_LISTING);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    const searchingCompany = async () => {
      startLoader({ heading: "Searching companies..." });
      companyStore.setCompanies([]);
      companyStore.setCompanySearchedSelected({});
      await emitEvent({
        code: "SEARCH_COMPANY",
      });
      setSearched(true);
      stopLoader();
    };
    searchingCompany();
  }, [companyStore.companyName, companyStore.companyNumber, companyStore.companyCountry, companyStore.companyState]);

  return (
    <Card>
      <Card.Body>
        {hasCompany && searched && (
          <div className="h-20">
            <Card.Heading text={props.heading} />
            <BlankCompany
              id={null}
              handleSelectCompany={handleSelectCompany}
              isSelected={companyStore.companySearchedSelected.id === null}
            />
            {companyStore?.companies?.map((data) => (
              <RenderCompanies
                data={data}
                id={data.id}
                key={data.id}
                availableServices={data.availableServices}
                handleSelectCompany={handleSelectCompany}
                isSelected={companyStore.companySearchedSelected.id === data.id}
              />
            ))}
          </div>
        )}
        {!hasCompany && searched && <NoCompanies />}
      </Card.Body>
      <Card.NavBar disabledGoForward={isDisabledGoForward} />
    </Card>
  );
};

export default observer(CompanyInformation);
