import VariablesStore from ".";
import { makeAutoObservable } from "mobx";

export default class GeneralVariablesStore {
  idType = "";
  token = "";
  tenantId = "";
  workflowId = "";

  constructor(private variablesStore: VariablesStore) {
    makeAutoObservable(this);
  }

  setIdtype = (idType: string) => {
    this.idType = idType;
  };

  setToken = (token: string) => {
    this.token = token;
  };

  setTenantId = (tenantId: string) => {
    this.tenantId = tenantId;
  };

  setWorkflowId = (workflowId: string) => {
    this.workflowId = workflowId;
  };
}
