import * as queryString from "query-string";
import { PDFDocument } from "pdf-lib";

async function fileToArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject();
    };
    reader.readAsArrayBuffer(file);
  });
}

export async function uploadTempFile(url, file) {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", file.type);

  let newFile = await fileToArrayBuffer(file);

  let requestOptions = {
    method: "PUT",
    body: newFile,
    headers: myHeaders,
  };

  try {
    if (file?.type?.includes("pdf")) {
      await PDFDocument.load(newFile);
    }
  } catch (error) {
    throw new Error(error);
  }

  return await fetch(url, requestOptions);
}

export async function getUploadUrl(query = {}) {
  const queryStringified = queryString.stringify(query);

  return await fetch(`${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/upload?${queryStringified}`, {
    method: "GET",
  });
}
