import { useEffect, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Card from "@components/Card";
import UploadFile from "./components/UploadFile";
import UploadMultipleFiles from "./components/UploadMultipleFiles";
import { useAnalytics } from "@contexts/Analytics";
import { observer } from "mobx-react-lite";
import store from "@store/index";

const I18N_BASE_PATH = "src.pages.onboarding.steps.uploadDocuments";

/**
 *
 * Step that obtains documents, this step requests documents for the user,
 * via template it is possible to define whether sending the document is mandatory or not
 *
 */
const UploadDocuments = (props) => {
  const { t } = useTranslation();

  const { logAnalyticsStepInfo, analyticsSteps } = useAnalytics();
  const documentStore = store.variables.document;

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_UPLOAD_DOCUMENT);
  }, [logAnalyticsStepInfo]);

  const optionalFields = useMemo(() => props.fields.filter(({ required }) => !required), [props.fields]);

  const requiredFields = useMemo(() => props.fields.filter(({ required }) => !!required), [props.fields]);

  const canGoForward = useMemo(() => {
    const requiredFieldIds = requiredFields.map((field) => field.title);

    const requiredUploadedFiles = documentStore.uploadedFiles.filter((file) => requiredFieldIds.includes(file.id));
    const requiredUploadedMultipleFiles = documentStore.multipleUploadedFiles.filter((file) =>
      requiredFieldIds.includes(file.title),
    );

    return requiredUploadedFiles.length + requiredUploadedMultipleFiles.length >= requiredFields.length;
  }, [requiredFields, documentStore.uploadedFiles, documentStore.multipleUploadedFiles]);

  return (
    <Card>
      <Card.Body className="h-full">
        <Card.Heading
          text={
            props.heading ??
            t(`src.pages.onboarding.steps.allSteps.uniqueSteps.uploadDocuments.heading`, "Upload de arquivos")
          }
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              `src.pages.onboarding.steps.allSteps.uniqueSteps.uploadDocuments.subheading`,
              "Para que possamos validar as informações que você forneceu, por favor faça o upload da documentação listada abaixo",
            )
          }
        />
        <div className="flex flex-col">
          {requiredFields.length > 0 && (
            <span className="mb-6 font-bold text-black">
              {t(`${I18N_BASE_PATH}.components.fields.required`, "Obrigatórios")}
            </span>
          )}
          {requiredFields.length > 0 &&
            requiredFields.map((field, index) => (
              <Fragment key={"REQUIRED_" + index}>
                {field?.multiple ? (
                  <UploadMultipleFiles
                    id={"REQUIRED_" + index}
                    name={field?.type}
                    title={field?.title ?? t(`${I18N_BASE_PATH}.documentType.${field?.type}`)}
                    acceptedExtensions={field?.extensions}
                    maxSize={field?.maxSize || 10}
                    isRequired={field?.required}
                  />
                ) : (
                  <UploadFile
                    name={field?.type}
                    title={field?.title ?? t(`${I18N_BASE_PATH}.documentType.${field?.type}`)}
                    acceptedExtensions={field?.extensions}
                    maxSize={field?.maxSize || 10}
                  />
                )}
              </Fragment>
            ))}
          {optionalFields.length > 0 && (
            <span className="mb-6 font-bold text-black">
              {t(`${I18N_BASE_PATH}.components.fields.optional`, "Opcionais")}
            </span>
          )}
          {optionalFields.length > 0 &&
            optionalFields.map((field, index) => (
              <Fragment key={"NOT_REQUIRED_" + index}>
                {field?.multiple ? (
                  <UploadMultipleFiles
                    id={"NOT_REQUIRED_" + index}
                    name={field?.type}
                    title={field?.title ?? t(`${I18N_BASE_PATH}.documentType.${field?.type}`)}
                    acceptedExtensions={field?.extensions}
                    maxSize={field?.maxSize || 10}
                    isRequired={field?.required}
                  />
                ) : (
                  <UploadFile
                    name={field?.type}
                    title={field?.title ?? t(`${I18N_BASE_PATH}.documentType.${field?.type}`)}
                    acceptedExtensions={field?.extensions}
                    maxSize={field?.maxSize || 10}
                  />
                )}
              </Fragment>
            ))}
        </div>
      </Card.Body>
      <Card.NavBar disabledGoForward={!canGoForward} />
    </Card>
  );
};

export default observer(UploadDocuments);
