import camera from "@assets/images/illustrations/camera.svg";
import completed from "@assets/images/illustrations/completed.svg";
import dataset from "@assets/images/illustrations/dataset.svg";
import denied from "@assets/images/illustrations/denied.svg";
import notFound from "@assets/images/illustrations/not-found.svg";
import onboarding from "@assets/images/illustrations/onboarding.svg";
import people from "@assets/images/illustrations/people.svg";
import phone from "@assets/images/illustrations/phone.svg";
import success from "@assets/images/illustrations/success.svg";
import networkError from "@assets/images/illustrations/network-error.svg";
import PDFDocument from "@assets/images/illustrations/pdf-doc.svg";
import circleError from "@assets/images/illustrations/circle-error.svg";

/** All available illustrations */
export const illustrations = {
  CAMERA: "illustration_camera",
  COMPLETED: "illustration_completed",
  DATASET: "illustration_dataset",
  DENIED: "illustration_denied",
  NOT_FOUND: "illustration_notFound",
  ONBOARDING: "illustration_onboarding",
  PEOPLE: "illustration_people",
  PHONE: "illustration_phone",
  SUCCESS: "illustration_success",
  NETWORK_ERROR: "illustration_networkError",
  PDF_DOCUMENT: "illustration_PDFDocument",
  CIRCLE_ERROR: "illustration_circle_error",
};

/** Checks if image src is an illustration */
export function isIllustration(src = "") {
  return src.startsWith("illustration_");
}

export function isUrlOrIconIllustration(src = "") {
  return src?.match(/^(https?)/);
}

/** Gets the illustration svg from its name */
export function getIllustration(illustrationName = "") {
  switch (illustrationName) {
    case illustrations.CAMERA:
      return camera;
    case illustrations.COMPLETED:
      return completed;
    case illustrations.DATASET:
      return dataset;
    case illustrations.DENIED:
      return denied;
    case illustrations.NOT_FOUND:
      return notFound;
    case illustrations.ONBOARDING:
      return onboarding;
    case illustrations.PEOPLE:
      return people;
    case illustrations.PHONE:
      return phone;
    case illustrations.SUCCESS:
      return success;
    case illustrations.NETWORK_ERROR:
      return networkError;
    case illustrations.PDF_DOCUMENT:
      return PDFDocument;
    case illustrations.CIRCLE_ERROR:
      return circleError;
    default:
      return "";
  }
}
