import { action, makeAutoObservable } from "mobx";
import { RootStore } from "..";

import GeneralVariablesStore from "./general";
import PFPFVariablesStore from "./pfpf";
import DocumentVariablesStore from "./document";
import PersonVariablesStore from "./person";
import CompanyVariablesStore from "./company";

type GetKeys<T> = {
  [P in keyof T]: T[P] extends Function ? never : P;
}[keyof T];

type Variables =
  | GetKeys<GeneralVariablesStore>
  | GetKeys<PFPFVariablesStore>
  | GetKeys<DocumentVariablesStore>
  | GetKeys<PersonVariablesStore>
  | GetKeys<CompanyVariablesStore>;

export default class VariablesStore {
  private immutableVariables: Variables[] = [];

  general: GeneralVariablesStore;
  pfpf: PFPFVariablesStore;
  document: DocumentVariablesStore;
  person: PersonVariablesStore;
  company: CompanyVariablesStore;

  constructor(private rootStore: RootStore) {
    this.general = new GeneralVariablesStore(this);
    this.pfpf = new PFPFVariablesStore(this);
    this.document = new DocumentVariablesStore(this);
    this.person = new PersonVariablesStore(this);
    this.company = new CompanyVariablesStore(this);

    makeAutoObservable(this, {
      setImmutableVariable: action.bound,
      isImmutableVariable: action.bound,

      flatten: action.bound,
      updateVariables: action.bound,
    });
  }

  setImmutableVariable(key: Variables) {
    if (this.isImmutableVariable(key)) return;

    this.immutableVariables.push(key);
  }

  isImmutableVariable(key: Variables) {
    return this.immutableVariables.includes(key);
  }

  getStoreProps(store: object) {
    const keys = Object.keys(store) as never[];

    return keys.reduce((acc, key) => {
      if (typeof store[key] === "function") return acc;
      if (key === "variablesStore") return acc;
      if (key === "rootStore") return acc;

      return {
        ...acc,
        [key]: store[key],
      };
    }, {}) as Record<string, any>;
  }

  // method to get all variables in a flat object
  flatten() {
    const stores = Object.keys(this) as never[];

    return stores.reduce((acc, key) => {
      if (typeof this[key] === "function") return acc;
      if (key === "rootStore") return acc;

      return {
        ...acc,
        ...this.getStoreProps(this[key]),
      };
    }, {});
  }

  // method to update variables from a flat object
  updateVariables(variables: Record<string, any>) {
    const stores = Object.keys(this) as Array<keyof VariablesStore>;
    const variablesKeys = Object.keys(variables);

    variablesKeys.forEach((variable) => {
      let isMapped = false;

      stores.forEach((store) => {
        const storeProps = this.getStoreProps(this[store]);
        const storeKeys = Object.keys(storeProps);

        if (storeKeys.includes(variable)) {
          isMapped = true;

          if (!this.isImmutableVariable(variable as Variables)) {
            (this[store] as any)[variable] = variables[variable];
          }
        }
      });

      if (!isMapped) {
        (this.general as any)[variable] = variables[variable];
      }
    });
  }
}
