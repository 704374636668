import Welcome from "./Welcome";
import DocumentIssuedOrigin from "./DocumentIssuedOrigin";
import DocumentType from "./DocumentType";
import UsingTerms from "./UsingTerms";
import SendDocumentType from "./SendDocumentType";
import CameraAccess from "./CameraAccess";
import { DD, DDPreview } from "./DD";
import { PFL, PFLPreview } from "./PFL";
import { LivenessClearSale, LivenessClearSalePreview } from "./LivenessClearSale";
import FA from "./FA";
import DataConfirmation from "./DataConfirmation";
import DispatchAddress from "./DispatchAddress";
import CompanyAddress from "./CompanyAddress";
import CompanyData from "./CompanyData";
import EmployeeData from "./EmployeeData";
import PFPFData from "./PFPFData";
import { QSA, QSAContact, QSAContactInfo, QSANumber, Attorney } from "./QSA";
import Partner from "./Partner";
import Done from "./Done";
import UploadDocuments from "./UploadDocuments";
import Income from "./Income";
import { PhoneNumberValidation, EmailValidation } from "./Validation";
import Instructions from "./Instructions";
import Custom from "./Custom";
import FlowChoice from "./FlowChoice";
import CustomForm from "./CustomForm";
import FaceAuthIProov from "./FaceAuthIProov";
import { LivenessIProov, LivenessIProovPreview } from "./LivenessIProov";
import BusinessAddress from "./BusinessAddress";
import CompanyLocation from "./CompanyLocation";
import CompanySearch from "./CompanySearch";
import CompanyListing from "./CompanyListing";

import { parseStep } from "@utils/onboarding";

const steps = {
  WELCOME: Welcome,
  DOCUMENT_ISSUED_COUNTRY: DocumentIssuedOrigin,
  COMPANY_LOCATION: CompanyLocation,
  COMPANY_SEARCH: CompanySearch,
  COMPANY_LISTING: CompanyListing,
  DOCUMENT_TYPE: DocumentType,
  USING_TERMS: UsingTerms,
  SEND_DOCUMENT_TYPE: SendDocumentType,
  CAMERA_ACCESS: CameraAccess,
  DD: DD,
  DD_PREVIEW: DDPreview,
  PFL: PFL,
  PFL_PREVIEW: PFLPreview,
  LIVENESS_CLEARSALE: LivenessClearSale,
  LIVENESS_CLEARSALE_PREVIEW: LivenessClearSalePreview,
  FA: FA,
  DATA_CONFIRMATION: DataConfirmation,
  DISPATCH_ADDRESS: DispatchAddress,
  COMPANY_ADDRESS: CompanyAddress,
  COMPANY_DATA: CompanyData,
  EMPLOYEE_DATA: EmployeeData,
  PF_PF_DATA: PFPFData,
  QSA: QSA,
  ATTORNEY: Attorney,
  QSA_CONTACT: QSAContact,
  QSA_CONTACT_INFO: QSAContactInfo,
  QSA_NUMBER: QSANumber,
  PARTNER: Partner,
  DONE: Done,
  UPLOAD_DOCUMENTS: UploadDocuments,
  INCOME: Income,
  PHONE_NUMBER_VALIDATION: PhoneNumberValidation,
  EMAIL_VALIDATION: EmailValidation,
  INSTRUCTIONS: Instructions,
  CUSTOM: Custom,
  FLOW_CHOICE: FlowChoice,
  CUSTOM_FORM: CustomForm,
  LIVENESS_IPROOV: LivenessIProov,
  LIVENESS_IPROOV_PREVIEW: LivenessIProovPreview,
  FACE_AUTH_IPROOV: FaceAuthIProov,
  BUSINESS_ADDRESS: BusinessAddress,
};

export default function getStep(step: Step) {
  const stepName = parseStep(step)[0] as Step;

  if (!steps[stepName]) throw new Error(`Step ${step} not found`);

  return steps[stepName] as (props: object) => JSX.Element;
}

export type Step = keyof typeof steps;
