import { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { FormInput, FormSelect } from "@components/Form";
import { useAnalytics } from "@contexts/Analytics";
import { Mask, Regex } from "@utils/formatting";
import useZipCodeFormatter from "@hooks/formatters/useZipCodeFormatter";
import formMessagesValidations from "@utils/formErrors";
import useValidate from "@hooks/useValidate";
import useEvents from "@hooks/useEvents";
import { observer } from "mobx-react-lite";
import store from "@store/index";

const { Option } = Select;

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

const CompanyAddress = (props) => {
  const { t } = useTranslation();
  const { zipCodeMask } = useZipCodeFormatter();

  const [shouldValidate, setShouldValidate] = useState(false);

  const { logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions, analyticsSteps } = useAnalytics();
  const { emitEvent, error: zipCodeFailed, isLoading: isFetching } = useEvents();

  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const { isImmutableVariable, company: companyStore } = store.variables;
  const { goForward } = store.navigation;

  const [zipCode, state, city, street, neighborhood, number, complement] = props.fields ?? [];

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_COMPANY_ADDRESS);
  }, [logAnalyticsStepInfo]);

  useEffect(() => {
    if (companyStore.companyAddressZipCode) {
      if (
        !companyStore.companyAddressState ||
        !companyStore.companyAddressCity ||
        !companyStore.companyAddressStreet ||
        !companyStore.companyAddressNeighborhood ||
        !companyStore.companyAddressNumber
      ) {
        fetchZipCode();
      }
    }
  }, [companyStore.companyAddressZipCode]);

  useEffect(() => {
    if (companyStore.companyAddressStateUf) {
      emitEvent({
        code: "SELECT_COMPANY_STATE",
      });
    }
  }, [companyStore.companyAddressStateUf]);

  const fetchZipCode = async () => {
    if (companyStore.companyAddressZipCode && Regex.cep?.test(companyStore.companyAddressZipCode)) {
      startLoader({ heading: t("general.message.loader.address", "Buscando endereço...") });
      await emitEvent({
        code: "INSERT_COMPANY_CEP",
      });
      stopLoader();
    }
  };

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      setShouldValidate(true);
    } else {
      goForward();
    }
  };

  const handleBlur = (field) => {
    logAnalyticsActionInfo(
      analyticsActions.FIELD_CHANGE,
      {
        field,
      },
      props.name,
    );
  };

  const allFields = {
    cep: {
      validity: useValidate(companyStore.companyAddressZipCode, (zip) => Regex.cep?.test(zip)),
      get component() {
        return () => (
          <FormInput
            id="companyAddressZipCode"
            label={zipCode?.label ?? t(`general.fields.zipCode.label`, "CEP")}
            placeholder={zipCode?.placeholder ?? t(`general.fields.zipCode.placeholder`, "Digite o seu CEP")}
            icon={<Icon icon={zipCode?.icon ?? "home"} />}
            mask={zipCodeMask}
            type="tel"
            required
            showRequiredMessage={shouldValidate && !zipCodeFailed && !this.validity}
            error={
              zipCodeFailed ||
              (!!companyStore.companyAddressZipCode?.length > 0 && !Regex.cep?.test(companyStore.companyAddressZipCode))
            }
            isToValidateErrorWithinFocus={!zipCodeFailed}
            errorMessage={
              zipCodeFailed
                ? props.errors?.cepNotFound?.text ??
                  t(
                    `${I18N_BASE_PATH}.uniqueSteps.companyAddress.errors.cepNotFound`,
                    "Ocorreu um erro ao buscar o CEP. Por favor, preencha os dados manualmente.",
                  )
                : t(formMessagesValidations.valid_cep)
            }
            valid={this.validity}
            value={companyStore.companyAddressZipCode}
            onChange={companyStore.setCompanyAddressZipCode}
            disabled={isImmutableVariable("companyAddressZipCode")}
            onBlur={() => handleBlur("companyAddressZipCode")}
          />
        );
      },
    },
    state: {
      validity: useValidate(companyStore.companyAddressState),
      get component() {
        return () => (
          <FormSelect
            id="companyAddressState"
            label={state?.label ?? t(`general.fields.state.label`, "Estado")}
            placeholder={
              isFetching && !companyStore.companyAddressZipCode
                ? t(`general.fields.zipCode.waitingForZipCode`, "Aguardando CEP")
                : `${state?.placeholder ?? t(`general.fields.state.placeholder`, "Selecione seu estado")}`
            }
            showSearch
            allowClear
            onChange={companyStore.setCompanyAddressState}
            required
            showRequiredMessage={shouldValidate && !this.validity}
            prefixIcon={state?.icon ?? "home"}
            value={companyStore.companyAddressState}
            disabled={isImmutableVariable("companyAddressState")}
            onBlur={() => handleBlur("companyAddressState")}
          >
            {companyStore.companyStatesList.map((state) => (
              <Option key={state.name}>{state.name}</Option>
            ))}
          </FormSelect>
        );
      },
    },
    city: {
      validity: useValidate(companyStore.companyAddressCity),
      get component() {
        return () => (
          <FormSelect
            id="companyAddressCity"
            label={city?.label ?? t(`general.fields.city.label`, "Cidade")}
            placeholder={
              isFetching && !companyStore.companyAddressZipCode
                ? t(`general.fields.zipCode.waitingForZipCode`, "Aguardando CEP")
                : `${city?.placeholder ?? t(`general.fields.city.placeholder`, "Selecione sua cidade")}`
            }
            showSearch
            allowClear
            required
            showRequiredMessage={shouldValidate && !this.validity}
            prefixIcon={city?.icon ?? "home"}
            value={companyStore.companyAddressCity}
            onChange={companyStore.setCompanyAddressCity}
            disabled={isImmutableVariable("companyAddressCity")}
            onBlur={() => handleBlur("companyAddressCity")}
          >
            {companyStore.companyCitiesList.map((city) => (
              <Option key={city.name}>{city.name}</Option>
            ))}
          </FormSelect>
        );
      },
    },
    street: {
      validity: useValidate(companyStore.companyAddressStreet),
      get component() {
        return () => (
          <FormInput
            id="companyAddressStreet"
            label={street?.label ?? t(`general.fields.street.label`, "Rua")}
            placeholder={
              isFetching && !companyStore.companyAddressZipCode
                ? t(`general.fields.zipCode.waitingForZipCode`, "Aguardando CEP")
                : `${street?.placeholder ?? t(`general.fields.street.placeholder`, "Digite a rua")}`
            }
            icon={<Icon icon={street?.icon ?? "home"} />}
            required
            showRequiredMessage={shouldValidate && !this.validity}
            valid={this.validity}
            value={companyStore.companyAddressStreet}
            onChange={companyStore.setCompanyAddressStreet}
            disabled={isImmutableVariable("companyAddressStreet")}
            allowSpecialCharacteres
            onBlur={() => handleBlur("companyAddressStreet")}
          />
        );
      },
    },
    neighborhood: {
      validity: useValidate(companyStore.companyAddressNeighborhood),
      get component() {
        return () => (
          <FormInput
            id="companyAddressNeighborhood"
            label={neighborhood?.label ?? t(`general.fields.neighborhood.label`, "Bairro")}
            placeholder={
              isFetching && !companyStore.companyAddressZipCode
                ? t(`general.fields.zipCode.waitingForZipCode`, "Aguardando CEP")
                : `${neighborhood?.placeholder ?? t(`general.fields.neighborhood.placeholder`, "Digite o bairro")}`
            }
            icon={<Icon icon={neighborhood?.icon ?? "home"} />}
            required
            showRequiredMessage={shouldValidate && !this.validity}
            valid={this.validity}
            value={companyStore.companyAddressNeighborhood}
            onChange={companyStore.setCompanyAddressNeighborhood}
            disabled={isImmutableVariable("companyAddressNeighborhood")}
            onBlur={() => handleBlur("companyAddressNeighborhood")}
          />
        );
      },
    },
    number: {
      validity: useValidate(companyStore.companyAddressNumber),
      get component() {
        return () => (
          <FormInput
            id="companyAddressNumber"
            label={number?.label ?? t(`general.fields.number.label`, "Número")}
            placeholder={number?.placeholder ?? t(`general.fields.number.placeholder`, "Digite o número")}
            icon={<Icon icon={number?.icon ?? "home"} />}
            type="tel"
            required
            showRequiredMessage={shouldValidate && !this.validity}
            valid={this.validity}
            value={companyStore.companyAddressNumber}
            onChange={companyStore.setCompanyAddressNumber}
            disabled={isImmutableVariable("companyAddressNumber")}
            onBlur={() => handleBlur("companyAddressNumber")}
          />
        );
      },
    },
    complement: {
      validity: true,
      get component() {
        return () => (
          <FormInput
            id="companyAddressComplement"
            label={complement?.label ?? t(`general.fields.complement.label`, "Complemento")}
            placeholder={
              complement?.placeholder ?? t(`general.fields.complement.placeholder`, "Digite o complemento (opcional)")
            }
            icon={<Icon icon={complement?.icon ?? "home"} />}
            validate={(complement) => !!complement}
            value={companyStore.companyAddressComplement}
            onChange={companyStore.setCompanyAddressComplement}
            disabled={isImmutableVariable("companyAddressComplement")}
            allowSpecialCharacteres
            onBlur={() => handleBlur("companyAddressComplement")}
          />
        );
      },
    },
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.companyAddress.heading`, "Endereço da empresa")}
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(`${I18N_BASE_PATH}.uniqueSteps.companyAddress.subheading`, "Informe o endereço físico da empresa.")
          }
        />
        <Form layout="vertical" requiredMark="optional">
          {Object.keys(allFields).map((field, index) => ({ ...allFields[field].component(), key: index }))}
        </Form>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!Object.keys(allFields).every((field) => allFields[field].validity)}
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(CompanyAddress);
