import store from "@store/index";
import { Regex } from "@utils/formatting";
import { CountryCode } from "libphonenumber-js";

export default function useZipCodeFormatter() {
  const country = store.variables.person.personAddressCountry;

  const zipCodeMask = zipCodeFormats[country];

  const zipCodeValidation = (zipCode?: string) => {
    if (!zipCode?.length) return false;

    if (country === "BR") {
      return Regex.cep?.test(zipCode);
    }
    return true;
  };

  return { zipCodeMask, zipCodeValidation };
}

type ZipCodeFormats = Partial<Record<CountryCode, string>>;

const zipCodeFormats: ZipCodeFormats = {
  BR: "99999-999",
} as const;
