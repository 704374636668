import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { useAnalytics } from "@contexts/Analytics";
import useRudderAnalytics from "@contexts/RudderAnalytics/useRudderAnalytics";
import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";
import { observer } from "mobx-react-lite";
import { illustrations } from "@utils/illustrations";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

export interface WelcomeProps {
  name: string;
  illustration?: string;
  heading?: string;
  subheading?: string;
  shouldGetUserLocation?: boolean;
}

const Welcome = (props: WelcomeProps) => {
  const { t } = useTranslation();

  const { analyticsSteps, logAnalyticsStepInfo } = useAnalytics();
  const { RudderStackEvents, RudderStackPermissionUpdateStatus, setRudderTrackData } = useRudderAnalytics();

  const logAnalytics = () => {
    if (props.shouldGetUserLocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          logAnalyticsStepInfo(analyticsSteps.STEP_WELCOME, {
            position: { latitude, longitude },
          });
          setRudderTrackData(RudderStackEvents.LOCATION_PERMISSION_UPDATED, {
            action: RudderStackPermissionUpdateStatus.ALLOWED,
          });
        },
        (error) => {
          logAnalyticsStepInfo(analyticsSteps.STEP_WELCOME);
          if (error.code === error.PERMISSION_DENIED) {
            setRudderTrackData(RudderStackEvents.LOCATION_PERMISSION_UPDATED, {
              action: RudderStackPermissionUpdateStatus.DENIED,
            });
          } else {
            setRudderTrackData(RudderStackEvents.LOCATION_PERMISSION_UPDATED, {
              action: RudderStackPermissionUpdateStatus.DISMISSED,
            });
          }
        },
      );
    } else {
      logAnalyticsStepInfo(analyticsSteps.STEP_WELCOME);
    }
  };

  useEffect(logAnalytics, [logAnalyticsStepInfo]);

  return (
    <Card>
      <Card.Body center>
        <Card.Illustration
          alt={t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.ONBOARDING]) || ""}
          src={props.illustration ?? illustrations.ONBOARDING}
        />
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.welcome.heading`, "Captura do documento")}
        />
        <Card.Subheading
          text={
            props.subheading ?? t(`${I18N_BASE_PATH}.uniqueSteps.welcome.subheading`, "Tenha em mãos o seu RG ou CNH.")
          }
        />
      </Card.Body>
      <Card.NavBar />
    </Card>
  );
};

export default observer(Welcome);
