import usePhoneFormatter from "@hooks/formatters/usePhoneFormatter";
import { getCompanyData, requestCompanyData } from "../services";
import store from "@store/index";
import { Logger } from "@utils/logging";
import { useReducer } from "react";
import useTemplateData from "@hooks/useTemplateData";

interface RequestCompanyDataState {
  loading: boolean;
  error: boolean;
  success: boolean;
  errorData: any;
}

interface SetLoadingAction {
  type: "SET_LOADING";
}

interface SetErrorAction {
  type: "SET_ERROR";
  errorData: any;
}

interface SetSuccessAction {
  type: "SET_SUCCESS";
}

type Action = SetLoadingAction | SetErrorAction | SetSuccessAction;

function reducer(state: RequestCompanyDataState, action: Action): RequestCompanyDataState {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        errorData: null,
        loading: true,
        error: false,
        success: false,
      };
    case "SET_ERROR":
      return {
        ...state,
        errorData: action.errorData,
        loading: false,
        error: true,
        success: false,
      };
    case "SET_SUCCESS":
      return {
        ...state,
        errorData: null,
        loading: false,
        error: false,
        success: true,
      };
  }
}

const initialState = {
  loading: false,
  error: false,
  success: false,
  errorData: null,
};

let requestRetryCounter = 1;

export default function useRequestCompanyData() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { formatPhone } = usePhoneFormatter();

  const { changeOnboardingStatus } = useTemplateData();

  const { setImmutableVariable } = store.variables;
  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const companyStore = store.variables.company;
  const { token } = store.variables.general;

  const fetchCompanyData = async () => {
    const companyData = await getCompanyData(companyStore.companyCnpj, token);

    const query = companyData.data?.companyQuery;

    switch (query?.status) {
      case "QUERY_COMPLETED": {
        if (companyData.data?.status) {
          changeOnboardingStatus(companyData.data.status);
        }

        if (companyData.data?.company) {
          companyStore.setCompanyQsaOriginal(companyData?.data?.company?.QSA || []);
          const QSA = companyData?.data?.company?.QSA?.filter((qsa) => !qsa?.cnpj);
          companyStore.setCompanyQsa(QSA || []);

          let { officialName, fantasyName, phoneNumber } = companyData.data.company;

          if (officialName) companyStore.setCompanyOfficialName(officialName);
          if (fantasyName) companyStore.setCompanyFantasyName(fantasyName);

          if (!!officialName) setImmutableVariable("companyOfficialName");
          if (!!fantasyName) setImmutableVariable("companyFantasyName");

          if (phoneNumber) companyStore.setCompanyPhoneNumber(formatPhone("+55 " + phoneNumber));

          if (companyData.data?.company?.address) {
            let { zipCode, street, neighborhood, number, complement, city, state } = companyData.data?.company?.address;

            companyStore.setCompanyAddressZipCode(zipCode);
            companyStore.setCompanyAddressStreet(street);
            companyStore.setCompanyAddressNeighborhood(neighborhood);
            companyStore.setCompanyAddressNumber(number);
            companyStore.setCompanyAddressComplement(complement);
            companyStore.setCompanyAddressCity(city);
            companyStore.setCompanyAddressState(state);
          }
        }

        if (companyData.data?.qsa) companyStore.setCompanyQsaStatus(companyData.data.qsa);

        dispatch({ type: "SET_SUCCESS" });
        stopLoader();

        break;
      }
      case "QUERY_ERROR": {
        dispatch({ type: "SET_ERROR", errorData: null });
        stopLoader();
        break;
      }
      case "QUERY_REQUESTED": {
        if (requestRetryCounter < 2) {
          const queryStartedAt = new Date(query.startedAt);
          let timeDiff = Math.abs(Date.now() - queryStartedAt.getTime());

          if (timeDiff < 30000) {
            setTimeout(fetchCompanyData, 3000);
          }
          requestRetryCounter++;
        } else {
          dispatch({ type: "SET_ERROR", errorData: "Company Data Request Timeout" });
          stopLoader();
        }

        break;
      }
    }
  };

  const request = async () => {
    try {
      dispatch({ type: "SET_LOADING" });
      startLoader();
      const response = await requestCompanyData(companyStore.companyCnpj, token);

      if (response.status === 200) {
        await fetchCompanyData();
      }
    } catch (error) {
      Logger.error("Error requesting company data", error);
      dispatch({ type: "SET_ERROR", errorData: error });
      stopLoader();
    }
  };

  return { request, ...state };
}
