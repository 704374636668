import { Logger } from "./logging";

/** Checks if the browser is running on mobile. */
export function isMobile() {
  return window.innerWidth < 768;
}

/** Brightens or darkens a color (amount from -1 to 1) - https://stackoverflow.com/a/21038522/9629238 */
export function shadeColor(col, amount) {
  function hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;

    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;

    return s;
  }

  function color(r, g, b) {
    return "#" + hex2(r) + hex2(g) + hex2(b);
  }

  // Removes #
  if (col.charAt(0) === "#") col = col.substring(1);
  // Transforms 3 digit hex into 6
  if (col.length === 3)
    col = col
      .split("")
      .map(function (hex) {
        return hex + hex;
      })
      .join("");

  var r = parseInt(col.substr(0, 2), 16);
  var g = parseInt(col.substr(2, 2), 16);
  var b = parseInt(col.substr(4, 2), 16);

  if (amount < 0) {
    r = (1 + amount) * r;
    g = (1 + amount) * g;
    b = (1 + amount) * b;
  } else {
    r = (1 - amount) * r + amount * 255;
    g = (1 - amount) * g + amount * 255;
    b = (1 - amount) * b + amount * 255;
  }

  return color(r, g, b);
}

/** Gets a variable from the query params. */
export function getQueryVariable(variable) {
  try {
    var query = window.location.search.substring(1);
    if (query) {
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === variable) {
          return pair[1];
        }
      }
    }
  } catch (error) {
    Logger.error(error, {
      message: "Couldn't get query params variable.",
    });
    return "invalid";
  }
  return "invalid";
}

/** Fetches for n times until success */
export async function fetchWithRetry(url, options, n = 3) {
  try {
    return await fetch(url, options);
  } catch (err) {
    if (n === 1) throw err;
    return await fetchWithRetry(url, options, n - 1);
  }
}

export function blobToImage(blob) {
  return URL.createObjectURL(blob);
}

/** Checks if a string is a valid HTTP URL */
export function isValidUrl(url = "") {
  if (!url) return false;
  url = url.replace(/[\u200B-\u200D\uFEFF]/g, "");
  return url.indexOf("http://") === 0 || url.indexOf("https://") === 0;
}

export function convertMillisecondsToHours(ms) {
  return Math.floor(ms / (1000 * 60 * 60));
}

export async function getIPAddress() {
  const urls = [
    "https://icanhazip.com/",
    "https://api.ipify.org/",
    "https://ifconfig.co/ip",
    "https://api6.ipify.org/",
  ];

  async function getIp() {
    try {
      let lastError;
      let ip;
      for (const url of urls) {
        try {
          if (!ip) {
            // eslint-disable-next-line no-await-in-loop
            const response = await fetch(url);
            ip = ((await response.text()) || "").trim();
            return ip;
          }
          break;
        } catch (error) {
          lastError = error;
        }
      }

      if (!ip) {
        throw new Error("Could not get the public IP address", { cause: lastError });
      }
    } catch (error) {
      throw error;
    }
  }

  for (let attempts = 0; attempts < 2; attempts++) {
    try {
      return await getIp();
    } catch (e) {
      if (attempts === 1) {
        throw new Error(`Getting Ip Error: ${e}`);
      }
    }
  }
}
