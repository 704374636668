import { Button } from "antd";
import { Icon } from "@combateafraude/react";

const ViewMore = ({ onClick, title, type, icon, iconStyles, text, className }) => {
  return (
    <div className={`flex flex-1 hover:opacity-80 transition-opacity self-end ${className}`}>
      <Button className="!p-0 !text-primary flex items-center !font-medium" type={type} onClick={onClick}>
        {!text && <Icon className={`text-secondary mr-1 ${iconStyles}`} icon={icon} />}
        {title}
      </Button>
    </div>
  );
};

export default ViewMore;
