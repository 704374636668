import { useEffect, useRef, useState } from "react";
import Card from "@components/Card";
import { useTranslation } from "react-i18next";
import useFaceAuth from "./hooks/useFaceAuth";
import { useAnalytics } from "@contexts/Analytics";
import { Logger } from "@utils/logging";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import useTemplateData from "@hooks/useTemplateData";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

interface Props {
  name: string;
  heading?: string;
  subheading?: string;

  previewHeading?: string;
  previewSubheading?: string;
  previewRetryText?: string;

  errors?: {
    faOpeningError?: {
      text: string;
    };
  };
}

const FA = (props: Props) => {
  const [error, setError] = useState(false);

  const containerRef = useRef(null);

  const { t } = useTranslation();
  const { logAnalyticsStepInfo, analyticsSteps, logAnalyticsActionInfo, analyticsActions } = useAnalytics();
  const faceAuth = useFaceAuth();
  const { templateData } = useTemplateData();
  const clientsTenantIds = JSON.parse(process.env.REACT_APP_FA_CUSTOM_WORKFLOW_TENANTS || "").tenantIds;

  const isFaCustomWorkflowTenant = clientsTenantIds.some(
    (id: string) => templateData?.tenantId && id.includes(templateData.tenantId),
  );

  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const { goBackward, goForward, haveStep } = store.navigation;
  const hasDoneStep = haveStep("DONE");

  const customGoBackward = async () => {
    Logger.console("FAStep customGoBackward");
    await faceAuth.reset();
    goBackward();
  };

  const customGoForward = async ({ disabled }: { disabled: boolean }) => {
    if (!disabled) {
      Logger.console("FAStep customGoForward");
      await faceAuth.reset();
      goForward();
    }
  };

  const capture = () => {
    setError(false);
    Logger.console("FAStep capture");
    startLoader({ heading: t("general.message.loader.initializingPassiveFaceLiveness", "Iniciando leitor facial...") });
    faceAuth.capture(containerRef.current, {
      onGetResults: () => {
        customGoForward({ disabled: false });
      },
      onInitialize: () => {
        stopLoader();
        logAnalyticsActionInfo(analyticsActions.FA_CAPTURE_START, {}, props.name);
      },
      onCaptureInvalid: () => {
        logAnalyticsActionInfo(analyticsActions.FA_CAPTURE_FAILED, {}, props.name);
      },
      onError: (hasResults = false) => {
        stopLoader();
        if (hasDoneStep && hasResults && isFaCustomWorkflowTenant) {
          goForward();
        } else {
          setError(true);
        }
      },
    });
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_FA);

    capture();
    setError(false);
  }, [logAnalyticsStepInfo]);

  return (
    <Card>
      <Card.Body className="relative">
        <div ref={containerRef} className="h-full w-full flex items-center justify-center">
          {error && (
            <Card.Text
              className="mx-10"
              text={
                props.errors?.faOpeningError?.text ??
                t(
                  `${I18N_BASE_PATH}.uniqueSteps.fa.errors.faOpeningError`,
                  "Ocorreu um erro ao abrir o Autenticador de Faces. Por favor, refaça o Onboarding. Caso o problema persistir, entre em contato com o suporte.",
                )
              }
            />
          )}
        </div>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!faceAuth.hasResults()}
        goBackward={customGoBackward}
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(FA);
