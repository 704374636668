import { useCallback, useEffect, useState } from "react";
import { Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";

import TextMessageFeedback from "@components/TextMessageFeedback";

import { getUploadUrl, uploadTempFile } from "../../services";
import store from "@store/index";
import { useAnalytics } from "@contexts/Analytics";

const filesExtensions = {
  pdf: "pdf-file",
  jpg: "jpg-file",
  jpeg: "jpeg-file",
  png: "png-file",
  file: "file",
};

const I18N_BASE_PATH = "src.components.file.uploadFile";

const UploadFile = ({ title, name, acceptedExtensions, maxSize }) => {
  const { t } = useTranslation();

  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [fileTitle, setFileTitle] = useState(title || t(`${I18N_BASE_PATH}.states.fileTitle`, "Anexe um arquivo"));
  const [fileExtension, setFileExtension] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(t(`${I18N_BASE_PATH}.states.errorMessage`, "Ocorreu algum erro"));
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [document, setDocument] = useState({});

  const { logAnalyticsActionInfo, analyticsActions } = useAnalytics();

  const [acceptedFileExtensions, setAcceptedFileExtensions] = useState([
    "pdf",
    "csv",
    "txt",
    "png",
    "jpeg",
    "jpg",
    "svg",
  ]);

  const documentStore = store.variables.document;

  useEffect(() => {
    if (Array.isArray(acceptedExtensions) && acceptedExtensions.length > 0) {
      setAcceptedFileExtensions(acceptedExtensions);
    }

    const fileInStore = documentStore.uploadedFiles.find((file) => file.type === name);

    if (fileInStore) {
      setUploaded(true);
      setDocument(fileInStore);
      setFileTitle(fileInStore.fileName);
      setFileExtension(fileInStore.extension);
    } else {
      setFileTitle(title);
    }
  }, [acceptedExtensions, title]);

  // dynamic icons for the file upload button
  const fileIcon = useCallback(
    ({ type }) => {
      return <Icon className="text-secondary !text-2xl" icon={filesExtensions[type] || filesExtensions["file"]} />;
    },
    [filesExtensions],
  );

  const handleChange = async ({ file }) => {
    setUploaded(false);
    setUploading(true);
    setIsErrorVisible(false);

    let fileSizeInMb = file.size / 1024 / 1024;
    if (fileSizeInMb > maxSize) {
      setErrorMessage(
        t(`general.message.uploadFiles.maxSize`, `O arquivo deve ter no máximo ${maxSize}MB`, {
          maxSize,
        }),
      );
      setIsErrorVisible(true);
      setUploaded(false);
      setUploading(false);
      return;
    }

    let isTypeAccepted = acceptedFileExtensions.some((ext) => file.type.includes(ext));
    if (!isTypeAccepted) {
      const fileExtensions = acceptedFileExtensions.map((ext) => ext.toUpperCase()).join(", ");

      setErrorMessage(
        t(`general.message.uploadFiles.fileExtensions`, `O arquivo deve possuir uma das extensões: ${fileExtensions}`, {
          fileExtensions,
        }),
      );
      setIsErrorVisible(true);
      setUploaded(false);
      setUploading(false);
      return;
    }

    const res = await getUploadUrl({ contentType: file.type }).then((res) => res.json());
    if (res?.uploadUrl) {
      try {
        await uploadTempFile(res?.uploadUrl, file);
      } catch (error) {
        setErrorMessage(
          t(
            `${I18N_BASE_PATH}.methods.handleChange.errorMessage.uploadFile`,
            "Ocorreu um problema ao fazer o upload do arquivo",
          ),
        );
        setIsErrorVisible(true);
        setUploading(false);

        return;
      }
    }

    const extension = file?.name?.split(".").pop()?.toLowerCase();
    const newFileObject = {
      key: res?.key,
      id: title,
      type: name,
      contentType: file.type || "",
      fileName: file.name || "",
      extension,
    };

    const newUploadedFiles = documentStore.uploadedFiles
      .filter((doc) => (document?.type === name ? doc.type !== name : true))
      .concat([newFileObject]);

    documentStore.setUploadedFiles(newUploadedFiles);
    logAnalyticsActionInfo(analyticsActions.DOCUMENT_UPLOADED, {}, name);

    setUploading(false);
    setUploaded(true);
    setFileExtension(extension);
    setFileTitle(file.name);
  };

  return (
    <>
      <div className="mb-8">
        <Upload
          data-testid={`upload-file-${name}`}
          accept={acceptedFileExtensions.map((ext) => "." + ext).join(",")}
          showUploadList={false}
          onChange={handleChange}
          beforeUpload={() => false}
          disabled={uploading}
          multiple={false}
          className="flex items-center w-full"
        >
          <div>
            <div
              className="flex items-center w-full h-full p-3 border border-solid border-gray-300 rounded-lg"
              id="style-border"
            >
              {fileExtension && uploaded ? (
                fileIcon({ type: fileExtension })
              ) : (
                <Icon className={`text-secondary !text-2xl`} icon="file" />
              )}
              <span className="ml-4 mr-4 w-full bg-red select-none overflow-hidden">{fileTitle}</span>
              {uploading ? (
                <LoadingOutlined className="!text-primary text-lg" height="2rem" />
              ) : uploaded ? (
                <Icon className={`text-secondary !text-2xl`} icon="check_d" />
              ) : (
                <Icon className={`text-secondary !text-4xl`} icon="upload" />
              )}
            </div>
          </div>
        </Upload>
        <TextMessageFeedback
          isVisible={isErrorVisible}
          messages={{ error: { text: errorMessage } }}
          validations={{ error: true }}
        />
      </div>
    </>
  );
};

export default UploadFile;
