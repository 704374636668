import { useEffect, useMemo, useState } from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { FormSelect } from "@components/Form";
import useValidate from "@hooks/useValidate";
import countries from "./utils/countries";
import states from "./utils/states";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { useAnalytics } from "@contexts/Analytics";

const { Option } = Select;

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps.uniqueSteps";

const CompanyLocation = (props) => {
  const [shouldValidate, setShouldValidate] = useState(false);

  const { t } = useTranslation();

  const { logAnalyticsStepInfo, analyticsSteps } = useAnalytics();

  const { company: companyStore, isImmutableVariable } = store.variables;
  const { goForward } = store.navigation;

  const shouldRequireState = useMemo(() => {
    const countriesNeedState = ["US", "CA"];

    return countriesNeedState.includes(companyStore.companyCountry);
  }, [companyStore.companyCountry]);

  const statesFromCountry = useMemo(() => {
    if (!companyStore.companyCountry) {
      companyStore.setCompanyState(undefined);
      return [];
    } else {
      return states?.filter((state) => state.country === companyStore.companyCountry);
    }
  }, [companyStore.companyCountry]);

  const disabledGoForward = useMemo(() => {
    const noCompanyCountry = !companyStore.companyCountry;
    const noCompanyState = shouldRequireState && !companyStore.companyState;

    return noCompanyCountry || noCompanyState;
  }, [companyStore.companyState, companyStore.companyCountry, shouldRequireState]);

  const allFields = {
    countries: {
      validity: useValidate(companyStore.companyCountry),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormSelect
            label={label ?? t("general.label.country", "País")}
            placeholder={placeholder ?? t("general.label.companyCountry", "Selecione o país da empresa")}
            id={id}
            showSearch
            allowClear
            required
            showRequiredMessage={shouldValidate && !this.validity}
            prefixIcon={icon !== "" && icon ? icon : "worldIcon"}
            value={companyStore.companyCountry}
            onChange={(value) => {
              companyStore.setCompanyCountry(value);
            }}
            onClear={() => {
              companyStore.setCompanyCountry(undefined);
            }}
            disabled={isImmutableVariable("companyCountry")}
            validity={this.validity}
            optionFilterProp="children"
          >
            {countries?.map(({ code, name }) => (
              <Option key={code}>{name}</Option>
            ))}
          </FormSelect>
        );
      },
    },
    states: {
      validity: useValidate(companyStore.companyState),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <>
            {shouldRequireState ? (
              <FormSelect
                label={label ?? t("general.label.country", "Estado")}
                placeholder={placeholder ?? t("general.label.companyState", "Selecione o estado da empresa")}
                id={id}
                showSearch
                allowClear
                required={shouldRequireState}
                showRequiredMessage={shouldValidate && !this.validity}
                value={companyStore.companyState}
                prefixIcon={icon !== "" && icon ? icon : "worldIcon"}
                onChange={(value) => {
                  companyStore.setCompanyState(value);
                }}
                onClear={() => {
                  companyStore.setCompanyState(undefined);
                }}
                disabled={isImmutableVariable("companyState")}
                validity={this.validity}
                optionFilterProp="children"
              >
                {statesFromCountry?.map(({ value, label }) => (
                  <Option key={value}>{label}</Option>
                ))}
              </FormSelect>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  };

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      setShouldValidate(true);
    } else {
      goForward();
    }
  };

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_COMPANY_LOCATION);
  }, [logAnalyticsStepInfo]);

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.CompanyCountryAndState.heading`, "Company country")}
        />
        <Card.Subheading text={props.subheading ?? "Tell us where your company is from."} />
        <Form layout="vertical" requiredMark="optional" className={"pt-5"}>
          {props.fields?.map((item, index) =>
            item.field && allFields[item.field]
              ? { ...allFields[item.field].component({ ...item, id: item.field }), key: index }
              : null,
          )}
        </Form>
      </Card.Body>
      <Card.NavBar disabledGoForward={disabledGoForward} goForward={customGoForward} />
    </Card>
  );
};

export default observer(CompanyLocation);
