import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@combateafraude/react";

import Card from "@components/Card";
import { FormInput, InputTypes } from "@components/Form";

import store from "@store/index";
import { observer } from "mobx-react-lite";
import { useAnalytics } from "@contexts/Analytics";

const I18N_BASE_PATH = "src.pages.onboarding.steps.qsaNumber";

const QSANumber = (props) => {
  const { t } = useTranslation();

  const [shouldValidate, setShouldValidate] = useState(false);
  const { logAnalyticsActionInfo, analyticsActions } = useAnalytics();

  const { company: companyStore, isImmutableVariable } = store.variables;
  const { goForward, addStep, haveStep, index, removeStep } = store.navigation;

  const customGoForward = ({ disabled }) => {
    if (disabled) {
      setShouldValidate(true);
    } else {
      if (!haveStep("QSA_CONTACT_INFO")) {
        addStep(
          {
            name: "QSA_CONTACT_INFO",
            props: {
              name: "QSA_CONTACT_INFO",
              heading: props.contactInfoHeading,
              subheading: props.contactInfoSubheading,
              illustration: props.contactInfoIllustration,
              flow: props.flow,
            },
          },
          index + 1,
        );
      }

      if (!haveStep("QSA_CONTACT")) {
        const companyQsaContacts = Array(companyStore.companyQsaInfoNumber);
        const defaultValues = {
          cpf: "",
          name: "",
          email: "",
          phone: "",
          custom: true,
        };

        companyQsaContacts[0] = {
          main: !props.fromEmployee,
          ...defaultValues,
        };

        companyStore.setCompanyQsaInfo(
          companyQsaContacts.fill(
            {
              main: false,
              ...defaultValues,
            },
            1,
          ),
        );
        companyStore.companyQsaInfo.forEach((_, i) => {
          addStep(
            {
              name: "QSA_CONTACT",
              props: {
                name: `QSA_CONTACT-${i + 1}`,
                heading: props.contactHeading,
                subheading: props.contactSubheading,
                qsaIndex: i,
                flow: props.flow,
                isAttorney: props?.isAttorney && i === 0 ? true : false,
              },
            },
            index + 2 + i,
          );
        });
      }

      goForward();
    }
  };

  const handleNumberChange = (value) => {
    removeStep("QSA_CONTACT_INFO");
    removeStep("QSA_CONTACT");

    const isThereOnlyNumber = /^\d+$/.test(value);
    const maxNumberOfPartners = 30;

    if (value === "") {
      companyStore.setCompanyQsaInfoNumber(0);
    } else if (isThereOnlyNumber && Number(value) && Number(value) <= maxNumberOfPartners) {
      companyStore.setCompanyQsaInfoNumber(Number(value));
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={
            props.heading ??
            t(
              "src.pages.onboarding.steps.allSteps.uniqueSteps.qsa.number.heading",
              "Quantos sócios constituem sua empresa?",
            )
          }
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              "src.pages.onboarding.steps.allSteps.uniqueSteps.qsa.number.subheading",
              "Está informação deve ser a mesma que consta em seu contrato social somando 100% da participação.",
            )
          }
        />
        <FormInput
          maxLength={30}
          id="partnersQuantity"
          label={t(`${I18N_BASE_PATH}.components.partnersQuantity.label`, "Quantidade de sócios")}
          placeholder={t(`${I18N_BASE_PATH}.components.partnersQuantity.placeholder`, "Digite a quantidade de sócios")}
          className="mt-4"
          icon={<Icon icon="person" />}
          type="tel"
          inputType={InputTypes.NUMBER}
          required
          showRequiredMessage={shouldValidate && !companyStore.companyQsaInfoNumber}
          value={companyStore.companyQsaInfoNumber}
          onChange={handleNumberChange}
          disabled={isImmutableVariable("companyQsaInfoNumber")}
          onBlur={() => {
            logAnalyticsActionInfo(
              analyticsActions.FILLED_QSA_NUMBER,
              {
                qsaNumber: companyStore.companyQsaInfoNumber,
              },
              props.name,
            );
          }}
        />
      </Card.Body>
      <Card.NavBar disabledGoForward={companyStore.companyQsaInfoNumber === 0} goForward={customGoForward} />
    </Card>
  );
};

export default observer(QSANumber);
