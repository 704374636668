import { Icon } from "@combateafraude/react";
import { FormInput } from "@components/Form";

const Free = ({ id, icon, label, placeholder, onChange, value, onBlur }) => {
  return (
    <FormInput
      id={id}
      className="!m-0"
      type="free"
      label={label}
      placeholder={placeholder}
      icon={!!icon && <Icon icon={icon} />}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default Free;
