import classNames from "classnames";
import { useTranslation } from "react-i18next";

const RenderCompanies = ({ data, id, availableServices, isSelected, handleSelectCompany }) => {
  const { t: translate } = useTranslation();

  const renderStatusTag = (status) => {
    const statusNormalized = status?.toLowerCase();
    const statusActive = ["active"];
    const statusInactive = ["nonactive", "closed", "expired", "inactive", "terminated"];
    const statusOther = [
      "pending",
      "other",
      "closing",
      "expanded",
      "incorporated",
      "registered",
      "under_external_control",
    ];

    const statusOptions = {
      active: (
        <div className="w-14 flex p-1 bg-green-400 rounded-2xl items-center jus flex-col text-white text-xs h-6">
          {translate("src.pages.onboarding.steps.companySearch.components.renderCompanies.status.active", "Active")}
        </div>
      ),
      inactive: (
        <div className="w-14 flex p-1 bg-red-400 rounded-2xl items-center flex-col text-white text-xs h-6">
          {translate("src.pages.onboarding.steps.companySearch.components.renderCompanies.status.inactive", "Inactive")}
        </div>
      ),
      other: (
        <div className="w-14 flex p-1 bg-yellow-400 rounded-2xl items-center flex-col text-white text-xs h-6">
          {translate("src.pages.onboarding.steps.companySearch.components.renderCompanies.status.other", "Other")}
        </div>
      ),
      noOption: (
        <div className="w-7 flex p-1 bg-gray-400 rounded-2xl items-center flex-col text-white text-xs mr-5 h-6">-</div>
      ),
    };

    if (statusActive.includes(statusNormalized)) return statusOptions["active"];
    if (statusInactive.includes(statusNormalized)) return statusOptions["inactive"];
    if (statusOther.includes(statusNormalized)) return statusOptions["other"];
    return statusOptions["noOption"];
  };

  return (
    <div
      role="button"
      className={classNames(
        "w-full p-4 bg-white rounded-lg flex border border-gray-300 mt-2 cursor-pointer h-min",
        `${isSelected ? "option-button-selected !bg-blue-100 !border-blue-800" : "option-button !border-gray-200"}`,
      )}
      onClick={() => {
        handleSelectCompany({ id, availableServices, data });
      }}
    >
      <aside className="h-full flex flex-col w-full">
        <div className="flex justify-between w-full">
          <span className="font-bold text-sm leading-4 text-black uppercase flex-1 w-full ">{data?.company?.name}</span>
          {renderStatusTag(data?.company?.status)}
        </div>
        <div className="flex mt-1 gap-1">
          <span className="text-xs text-gray-800">
            {translate(
              "src.pages.onboarding.steps.companySearch.components.renderCompanies.companyNumber",
              "Company Number:",
            )}
          </span>
          <span className="text-xs text-gray-800">{data?.company?.number}</span>
        </div>
        <div className="flex mt-1 gap-1">
          <span className="text-xs text-gray-800">
            {translate("src.pages.onboarding.steps.companySearch.components.renderCompanies.address", "Address:")}
            {data?.company?.address}
          </span>
        </div>
      </aside>
      <aside></aside>
    </div>
  );
};

export default RenderCompanies;
