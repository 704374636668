import { useEffect, useState } from "react";
import useLivenessClearSale from "./hooks/useLivenessClearSale";
import Button from "@components/Button";
import Card from "@components/Card";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { useTranslation } from "react-i18next";
import type { Props as ClearSalePreviewProps } from "./LivenessClearSalePreview";
import { parseStep } from "@utils/onboarding";
import { useAnalytics } from "@contexts/Analytics";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

interface Props {
  name: string;
  heading?: string;
  subheading?: string;
  preview: boolean;

  previewHeading?: string;
  previewSubheading?: string;
  previewRetryText?: string;

  errors?: {
    livenessClearSaleOpeningError?: {
      text: string;
    };
  };
}

const LivenessClearSale = (props: Props) => {
  const [error, setError] = useState(false);
  const livenessClearSale = useLivenessClearSale();
  const { logAnalyticsActionInfo, analyticsActions } = useAnalytics();
  const { t } = useTranslation();

  const { goForward, haveStep, addStep, index } = store.navigation;

  const openLivenessClearSaleSdk = () => {
    // setCustomGoBackward(() => (navigationProps) => customGoBackward(navigationProps));
    // setCustomGoForward(() => (navigationProps) => customGoForward(navigationProps));

    // logAnalyticsStepInfo(analyticsSteps.STEP_PFL);

    setError(false);

    livenessClearSale.capture({
      onReady: () => {
        logAnalyticsActionInfo(analyticsActions.LIVENESS_CLEARSALE_CAPTURE_START, {}, props.name);
      },
      onCompleted: () => {
        goForward();
      },
      onError: () => {
        setError(true);
        logAnalyticsActionInfo(analyticsActions.LIVENESS_CLEARSALE_CAPTURE_FAILED, {}, props.name);
      },
    });
  };

  useEffect(() => {
    if (props.preview && !haveStep("LIVENESS_CLEARSALE_PREVIEW")) {
      const stepIndex = parseStep(props.name)[1];

      addStep(
        {
          name: `LIVENESS_CLEARSALE_PREVIEW-${stepIndex}`,
          props: {
            name: `LIVENESS_CLEARSALE_PREVIEW-${stepIndex}`,
            heading: props.previewHeading,
            retryText: props.previewRetryText,
            subheading: props.previewSubheading,
          } as ClearSalePreviewProps,
        },
        index + 1,
      );
    }

    openLivenessClearSaleSdk();
  }, []);

  return (
    <Card>
      <Card.Body className="relative">
        <div className="h-full w-full flex items-center justify-center">
          {error && (
            <Card.Body center>
              <Card.Text
                className="mx-10"
                text={
                  props.errors?.livenessClearSaleOpeningError?.text ??
                  t(
                    `${I18N_BASE_PATH}.uniqueSteps.livenessClearSale.errors.livenessClearSaleOpeningError`,
                    "O leitor facial pode ter fechado por inatividade ou algum outro motivo. Utilize o botão abaixo para abri-lo novamente.",
                  )
                }
              />
              <Button type={"primary"} onClick={openLivenessClearSaleSdk} className={""}>
                Tentar novamente
              </Button>
            </Card.Body>
          )}
        </div>
      </Card.Body>
      <Card.NavBar disabledGoForward={!livenessClearSale.hasResults()} />
    </Card>
  );
};

export default observer(LivenessClearSale);
