import Card from "@components/Card";
import { useTranslation } from "react-i18next";
import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";
import { observer } from "mobx-react-lite";
import { illustrations } from "@utils/illustrations";

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

const QSAContactInfo = (props) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body center>
        <Card.Illustration
          alt={t(defaultOnboardingIllustrationsAlternativeText?.[props.illustration ?? illustrations.PEOPLE]) || ""}
          src={props.illustration ?? illustrations.PEOPLE}
        />
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.qsa.contactInfo.heading`, "Contato dos sócios")}
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              `${I18N_BASE_PATH}.uniqueSteps.qsa.contactInfo.subheading`,
              "Vamos precisar do contatos dos sócios de sua empresa para enviarmos um link de confirmação. É fundamental que você informe a todos sobre este cadastro.",
            )
          }
        />
      </Card.Body>
      <Card.NavBar />
    </Card>
  );
};

export default observer(QSAContactInfo);
