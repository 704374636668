import { observer } from "mobx-react-lite";
import { Carousel as CarouselAntd } from "antd";

import "./styles.less";

interface Props {
  slides: {
    element: any;
    label?: string;
  }[];
}

export default observer(function Carousel({ slides }: Props) {
  return (
    <div id="carousel-component">
      <CarouselAntd>
        {slides.map((slide) => (
          <div className="carousel-slide-container">
            <div className="carousel-slide-element">{slide.element}</div>
            {slide.label && (
              <div className="carousel-slide-label">
                <span>{slide.label}</span>
              </div>
            )}
          </div>
        ))}
      </CarouselAntd>
    </div>
  );
});
