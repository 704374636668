import { useMemo, useState } from "react";
import { Form } from "antd";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";

import Card from "@components/Card";
import { FormInput, InputTypes } from "@components/Form";
import useValidate from "@hooks/useValidate";
import { validateCPF, validateEmail } from "@utils/validations";
import { parseStep } from "@utils/onboarding";
import { removeSpecialChars } from "@utils/formatting";
import { Mask, Regex } from "@utils/formatting";
import formMessagesValidations from "@utils/formErrors";
import usePhoneFormatter from "@hooks/formatters/usePhoneFormatter";
import { observer } from "mobx-react-lite";
import store from "@store/index";
import { useAnalytics } from "@contexts/Analytics";

const I18N_BASE_PATH = "src.pages.onboarding.steps.qsaContact";

const QSAContact = (props) => {
  const { t } = useTranslation();

  const [shouldValidate, setShouldValidate] = useState(false);
  const { phoneValidation, formatPhone } = usePhoneFormatter();

  const { companyQsaInfo, updateQsaInfo, companyCountry, setReferenceAttorney, referenceAttorney } =
    store.variables.company;
  const { setPersonName, setPersonAttorney } = store.variables.person;
  const { goForward } = store.navigation;

  const { analyticsActions, logAnalyticsActionInfo } = useAnalytics();

  const qsaInfo = companyQsaInfo.at(props.qsaIndex);
  const isMain = Boolean(qsaInfo?.main);
  const isCustom = Boolean(qsaInfo?.custom);

  const isDuplicatedCPF = useMemo(
    () => !!companyQsaInfo.find((qsa, index) => !!qsaInfo?.cpf && qsa.cpf === qsaInfo?.cpf && index !== props.qsaIndex),
    [companyQsaInfo, props.qsaIndex, qsaInfo],
  );

  const isDuplicatedEmail = useMemo(
    () =>
      !!companyQsaInfo.find(
        (qsa, index) => !!qsaInfo?.email && qsa.email === qsaInfo?.email && index !== props.qsaIndex,
      ),
    [companyQsaInfo, props.qsaIndex, qsaInfo],
  );

  const isDuplicatedPhone = useMemo(
    () =>
      !!companyQsaInfo.find(
        (qsa, index) => !!qsaInfo?.phone && qsa.phone === qsaInfo?.phone && index !== props.qsaIndex,
      ),
    [companyQsaInfo, props.qsaIndex, qsaInfo],
  );

  const allFields = {
    name: {
      validity: useValidate(qsaInfo?.name, (name) => (!isCustom ? true : !!name)),
      get component() {
        return () =>
          !isCustom ? (
            <Card.Text
              small
              className="!mb-6 font-bold"
              text={`${parseStep(props.name)[1]}. ${
                !!qsaInfo ? qsaInfo?.name : t("general.label.unknown", "Desconhecido")
              }`}
            />
          ) : (
            <FormInput
              cleanInitialEmptySpace
              id={`name${props.qsaIndex}`}
              label={t("general.fields.qsa.name.label", "Nome")}
              placeholder={t("general.fields.qsa.name.placeholder", "Digite o nome")}
              icon={<Icon icon="person" />}
              valid={this.validity}
              value={qsaInfo?.name ?? ""}
              onChange={(value) => {
                updateQsaInfo(props.qsaIndex, {
                  ...qsaInfo,
                  name: value,
                });
                if (isMain && !props?.isAttorney) setPersonName(value);
                if (isMain && props?.isAttorney) {
                  setReferenceAttorney({ ...referenceAttorney, name: value });
                  setPersonAttorney(true);
                }
              }}
              uppercase
              required
              showRequiredMessage={shouldValidate && !this.validity}
              textOnly
            />
          );
      },
    },
    cpf: {
      validity: useValidate(
        qsaInfo?.cpf,
        (cpf) => !isDuplicatedCPF && Regex.cpf.test(cpf) && validateCPF(removeSpecialChars(cpf)),
      ),
      get component() {
        return () => (
          <FormInput
            id={`cpf${props.qsaIndex}`}
            label={t("general.fields.qsa.cpf.label", "CPF")}
            placeholder={t("general.fields.qsa.cpf.placeholder", "Digite o CPF")}
            icon={<Icon icon="person" />}
            mask={Mask.cpf}
            type="tel"
            valid={this.validity}
            required
            showRequiredMessage={shouldValidate && !this.validity}
            error={qsaInfo?.cpf?.length > 0 && !this.validity}
            isToValidateErrorWithinFocus={!isDuplicatedCPF}
            errorMessage={
              isDuplicatedCPF
                ? t(formMessagesValidations.qsa_contact_cpf_duplicated)
                : t(formMessagesValidations.valid_cpf)
            }
            value={qsaInfo?.cpf ?? ""}
            onChange={(value) => {
              updateQsaInfo(props.qsaIndex, {
                ...qsaInfo,
                cpf: value,
              });
              if (isMain && props?.isAttorney) {
                setReferenceAttorney({ ...referenceAttorney, cpf: value });
              }
            }}
            uppercase
          />
        );
      },
    },
    email: {
      validity: useValidate(qsaInfo?.email, (email) => !isDuplicatedEmail && (isMain ? true : validateEmail(email))),
      get component() {
        return () =>
          isMain ? (
            <></>
          ) : (
            <FormInput
              id={`email${props.qsaIndex}`}
              label={t("general.fields.qsa.email.label", "Email")}
              placeholder={t("general.fields.qsa.email.placeholder", "Digite o email")}
              icon={<Icon icon="mail" />}
              type="email"
              valid={this.validity}
              required
              showRequiredMessage={shouldValidate && !this.validity}
              error={qsaInfo?.email?.length > 0 && !this.validity}
              isToValidateErrorWithinFocus={!isDuplicatedEmail}
              errorMessage={
                isDuplicatedEmail
                  ? t(formMessagesValidations.qsa_contact_email_duplicated)
                  : t(formMessagesValidations.valid_email)
              }
              value={qsaInfo?.email ?? ""}
              onChange={(value) => {
                updateQsaInfo(props.qsaIndex, {
                  ...qsaInfo,
                  email: value,
                });
              }}
            />
          );
      },
    },
    phone: {
      validity: useValidate(qsaInfo?.phone, (phone) => !isDuplicatedPhone && (isMain ? true : phoneValidation(phone))),
      get component() {
        return () =>
          isMain ? (
            <></>
          ) : (
            <FormInput
              id={`telefone${props.qsaIndex}`}
              label={t("general.fields.qsa.phone.label", "Telefone")}
              placeholder={t("general.fields.qsa.phone.placeholder", "Digite o telefone")}
              icon={<Icon icon="contact_" />}
              inputType={InputTypes.PHONE}
              type="tel"
              valid={this.validity}
              required
              showRequiredMessage={shouldValidate && !this.validity}
              error={qsaInfo?.phone?.length > 0 && !this.validity}
              errorMessage={
                isDuplicatedPhone
                  ? t(formMessagesValidations.qsa_contact_phone_number_duplicated)
                  : t(formMessagesValidations.valid_phone_number)
              }
              isToValidateErrorWithinFocus={!isDuplicatedPhone}
              value={qsaInfo?.phone ?? ""}
              onChange={(value) => {
                updateQsaInfo(props.qsaIndex, {
                  ...qsaInfo,
                  phone: formatPhone(value),
                });
              }}
            />
          );
      },
    },
  };

  const fieldsToShow =
    companyCountry && companyCountry !== "BR"
      ? Object.keys(allFields).filter((k) => k !== "cpf")
      : Object.keys(allFields);

  const customGoForward = ({ disabled }) => {
    if (disabled) setShouldValidate(true);
    else {
      if ((isCustom && props.qsaIndex === companyQsaInfo.length) || props.qsaIndex === companyQsaInfo.length - 1) {
        logAnalyticsActionInfo(analyticsActions.FILLED_ALL_QSA_CONTACTS, {}, props.name);
      }
      setShouldValidate(false);
      goForward();
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={`${
            props.heading ??
            t("src.pages.onboarding.steps.allSteps.uniqueSteps.qsa.contact.heading", "Contato dos sócios")
          }${isCustom ? ` (${props.qsaIndex + 1}/${companyQsaInfo.length})` : ""}`}
        />
        <Card.Subheading
          text={
            isCustom
              ? isMain
                ? t(
                    `${I18N_BASE_PATH}.components.subheading.main`,
                    "Primeiro vamos falar sobre você. Preencha os campos abaixo.",
                  )
                : t(
                    `${I18N_BASE_PATH}.components.subheading.default`,
                    "Preencha as informações abaixo sobre os sócios da empresa.",
                  )
              : props.subheading ??
                t(
                  "src.pages.onboarding.steps.allSteps.uniqueSteps.qsa.contact.subheading",
                  "Vamos utilizar estes contatos para envio de seus respectivos fluxos de onboarding.",
                )
          }
        />
        <Form layout="vertical">
          {fieldsToShow.map((field, index) => ({ ...allFields[field].component(), key: index }))}
        </Form>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!fieldsToShow.every((field) => allFields[field].validity)}
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(QSAContact);
