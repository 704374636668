/* eslint-disable no-dupe-keys */
import { Button as CafButton } from "@combateafraude/react";
import classNames from "classnames";

import useTemplateData from "@hooks/useTemplateData";

/** Regular Button */
const Button = ({
  children,
  className,
  type,
  rounded = true,
  size = "",
  shape = "",
  disabled = false,
  outlined = false,
  onClick,
  /** Disables the button without changing its appearance */
  falseDisabled = false,
  /** Light colored button */
  light = false,
  /** Enables shadow */
  shadow = false,
  ...rest
}) => {
  const { templateData } = useTemplateData();

  return (
    <CafButton
      className={classNames("font-bold text-base !border-none", className, {
        "min-h-9": size !== "small",
        "min-w-9": shape === "circle",
        "px-5 py-0": !rounded && size !== "small",
        "!text-primary": type === "primary" && (outlined || light) && shape !== "circle",
        "!text-secondary": type === "secondary" && (outlined || light) && shape !== "circle",
        "!text-backbuttonicon": shape === "circle" && templateData?.template?.colors?.backButtonIcon,
        "!bg-backbutton": shape === "circle" && templateData?.template?.colors?.backButton,
        "!border-backbuttonborder !border-solid !border":
          shape === "circle" && templateData?.template?.colors?.backButtonBorder,
        "!text-white": type === "secondary" && !outlined && shape !== "circle",
        "!text-gray-500": type === "default" && (outlined || light),
        "!text-red-500": type === "danger" && (outlined || light),
        "!border-2 !border-primary": type === "primary" && outlined,
        "!border-2 !border-secondary": type === "secondary" && outlined,
        "!border-2 !border-gray-500": type === "default" && outlined,
        "!border-2 !border-red-500": type === "danger" && outlined,
        "!bg-primary": type === "primary" && !light && !disabled,
        "!bg-primary-light": type === "primary" && light && !disabled,
        "!bg-secondary": type === "secondary" && !light && !disabled,
        "!bg-secondary-light": type === "secondary" && light && !disabled,
        "!bg-gray-100 !text-gray-300": light && (disabled || falseDisabled),
        "!bg-gray-300": !light && disabled,
        "pointer-events-none cursor-not-allowed": falseDisabled,
        "!shadow-none": !shadow,
      })}
      type={type}
      rounded={rounded}
      size={size}
      shape={shape}
      disabled={disabled}
      outlined={outlined}
      onClick={falseDisabled ? null : onClick}
      {...rest}
    >
      {children}
    </CafButton>
  );
};

export default Button;
