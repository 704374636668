import { useTranslation } from "react-i18next";

import InfoIcon from "@assets/images/illustrations/info.svg";
import Card from "@components/Card";

import { observer } from "mobx-react-lite";
import { defaultOnboardingIllustrationsAlternativeText } from "@utils/accessibility";

const I18N_BASE_PATH = "src.pages.onboarding.steps.special.linkAccessed";

const LinkAccessed = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body center={true}>
        <Card.Illustration
          src={InfoIcon}
          alt={t(defaultOnboardingIllustrationsAlternativeText?.illustration_success) || ""}
        />
        <Card.Heading className="text-center" text={t(`${I18N_BASE_PATH}.components.heading`, "Link já utilizado!")} />
        <Card.Subheading
          text={t(
            `${I18N_BASE_PATH}.components.subheading`,
            "Esse link já foi preenchido. Por favor, gere um novo link de onboarding.",
          )}
        />
      </Card.Body>
    </Card>
  );
};

export default observer(LinkAccessed);
