import { LoadingOutlined } from "@ant-design/icons";
import { Spin as AntdSpin } from "antd";

const LoaderAntd = () => {
  const iconLoader = <LoadingOutlined style={{ fontSize: 80 }} spin />;

  return <AntdSpin indicator={iconLoader} style={{ color: "var(--color-primary)" }} className="h-28" />;
};

export default LoaderAntd;
