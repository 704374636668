import useTemplateData from "@hooks/useTemplateData";
import store from "@store/index";
import { Logger } from "@utils/logging";
import { useMemo, useRef, useEffect } from "react";
import { sendWebHookNotify } from "../services";
import { useAnalytics } from "@contexts/Analytics";
import { removeSpecialCharsAndNumbers } from "@utils/formatting";
import useEvents from "@hooks/useEvents";
import IPROOV_EVENTS from "@utils/iproovEvents";

let _result;
let _webHookSent = false;
let _language;
let facesSdk;
let isClosing = false;

export default function useIProovFaceAuth() {
  const { sdkToken, templateData } = useTemplateData();
  const { logAnalyticsEventInfo, analyticsEvents } = useAnalytics();
  const { emitEvent } = useEvents();
  const sdk = window["FacesSDK"];

  const { personId } = store.variables.person;
  const { haveStep } = store.navigation;
  const eventProcessed = useRef({});
  const eventListeners = useRef([]);

  const faStep = useMemo(
    () =>
      templateData?.template?.steps.find((step) => {
        const stepName = removeSpecialCharsAndNumbers(step.name);
        return stepName === "FACEAUTHIPROOV";
      }),
    [templateData],
  );

  const linkPerson = useMemo(
    () => ({
      cpf: templateData?.data?.cpf?.trim(),
      name: templateData?.data?.name,
    }),
    [templateData],
  );

  const onboardingToken = templateData.data.token;

  const callWebHook = async (attemptId, failedLiveness) => {
    logAnalyticsEventInfo(analyticsEvents.FA_WEB_HOOK_START_SEND, { attemptId, failedLiveness });
    const webHookNotifyResponse = await sendWebHookNotify(attemptId, failedLiveness, onboardingToken);

    if (webHookNotifyResponse?.status === 200) {
      Logger.console("FA WebHook Notify Response", webHookNotifyResponse);
      _webHookSent = true;
      return true;
    } else {
      Logger.console("FA WebHook Notify Error", webHookNotifyResponse);
      logAnalyticsEventInfo(analyticsEvents.FACE_AUTH_IPROOV_WEB_HOOK_ERROR, webHookNotifyResponse);
    }
  };

  const addEventListener = (type, listener, options = {}) => {
    document.addEventListener(type, listener, options);
    eventListeners.current.push({ type, listener });
  };

  const removeEventListeners = () => {
    eventListeners.current.forEach(({ type, listener }) => {
      document.removeEventListener(type, listener);
    });
    eventListeners.current = [];
  };

  const capture = async (containerNode, options) => {
    _result = undefined;
    _language = "pt_br";
    removeEventListeners();

    if (options?.language === "pt-br") _language = "pt_br";
    if (options?.language === "en-US") _language = "en";
    if (options?.language === "es-MX") _language = "es";

    if (facesSdk) {
      if (!isClosing) {
        isClosing = true;
        await facesSdk.close();
        facesSdk = null;
        isClosing = false;
      } else {
        return;
      }
    }

    try {
      addEventListener("sdk-ready", options.onInitialize, { once: true });

      addEventListener("streamed", () => {
        options.onStartLoader("general.message.loader.iproovProcessingPhoto");
      });

      IPROOV_EVENTS.forEach((type) => {
        addEventListener(
          type,
          async (e) => {
            if (eventProcessed.current[type]) {
              return;
            }

            eventProcessed.current[type] = true;

            const detail = e.detail;
            const executeRetry =
              type === "canceled" || (type === "error" && detail?.feedback === "error_no_face_found");

            if (executeRetry) {
              if (facesSdk) {
                await facesSdk.close();
              }
              eventProcessed.current[type] = false;
              await options.onRetry();
            }

            const eventType = `FACE_AUTH_IPROOV_${type.toUpperCase()}`;

            logAnalyticsEventInfo(analyticsEvents[eventType], { detail: e.detail, type });

            let sendEvent = () => {};

            if (type === "failed") {
              sendEvent = () => emitEvent({ code: "SDK_CAPTURE_FAILED", detail: _result || {} });
            }

            if (type === "error") {
              sendEvent = () => emitEvent({ code: "SDK_CAPTURE_INVALID", detail: _result || {} });
            }

            window.addEventListener("SDK_CAPTURE_RESULT", () => sendEvent(), { once: true });
          },
          { once: true },
        );
      });

      facesSdk = await sdk.initializeSdk(
        sdkToken,
        containerNode?.id,
        true,
        personId ?? linkPerson.cpf ?? linkPerson.name,
        {
          environment: process.env.REACT_APP_SDK_ENV,
          isSigned: false,
          settings: {
            filter: faStep?.sdkOptions?.filter ?? "clear",
            language: _language,
          },
          permissionButton: {
            label: "",
            color: "",
            backgroundColor: "",
            borderRadius: "",
            border: "",
            padding: "",
          },
          startButton: {
            label: options?.buttonText ?? faStep?.takeSelfieButtonText,
            color: faStep?.buttonTextColor ?? "#fff",
            backgroundColor: faStep?.buttonBackgroundColor ?? "#666666",
            borderRadius: "100px",
            border: `1px solid ${faStep?.buttonBorderColor ?? faStep?.buttonBackgroundColor ?? "#666666"}`,
            padding: "0rem 0.7rem",
          },
        },
      );
      await options.onInitialize();
      logAnalyticsEventInfo(analyticsEvents.FACE_AUTH_IPROOV_START, { _result });

      _result = await facesSdk.execute();

      logAnalyticsEventInfo(analyticsEvents.FACE_AUTH_IPROOV_CLOSE, { _result });

      options.onStartLoader("general.message.loader.iproovProcessingPhoto");

      if (_result?.attemptId) {
        await callWebHook(_result.attemptId, false);
      } else {
        await callWebHook(null, true);
      }

      Logger.info("useIProovFaceAuth", "capture", _result);

      emitEvent({ code: "SDK_CAPTURE_RESULT", detail: _result || {} });

      await options.onGetResults(_result);
    } catch (error) {
      logAnalyticsEventInfo(analyticsEvents.FACE_AUTH_IPROOV_ERROR, {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      });
    }
  };

  const hasResults = () => {
    return _result !== undefined;
  };

  const getResults = () => {
    return _result;
  };

  const verifyWebHook = () => _webHookSent;

  const isOnTemplate = () => {
    return templateData?.template?.category === "FACE_AUTHENTICATOR" && haveStep("FACE_AUTH_IPROOV");
  };

  const getIsMatch = () => {
    return _result?.isMatch;
  };

  useEffect(() => {
    return () => {
      removeEventListeners();
    };
  }, []);

  return {
    capture,
    hasResults,
    getResults,
    verifyWebHook,
    callWebHook,
    isOnTemplate,
    getIsMatch,
  };
}
