import { useEffect, useMemo, useState } from "react";
import { Form, Select } from "antd";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { FormInput, FormSelect } from "@components/Form";
import { useAnalytics } from "@contexts/Analytics";
import formMessagesValidations from "@utils/formErrors";
import useEvents from "@hooks/useEvents";
import useZipCodeFormatter from "@hooks/formatters/useZipCodeFormatter";
import countries from "@utils/countries";
import { observer } from "mobx-react-lite";
import store from "@store/index";

const { Option } = Select;

const I18N_BASE_PATH = "src.pages.onboarding.steps.allSteps";

const DispatchAddress = (props) => {
  const { t } = useTranslation();

  const [shouldValidate, setShouldValidate] = useState(false);

  const { start: startLoader, stop: stopLoader } = store.ui.loading;
  const { person: personStore, document: documentStore, isImmutableVariable, general: generalStore } = store.variables;
  const { goForward } = store.navigation;

  const { emitEvent, error: zipCodeFailed, isLoading: isFetching } = useEvents();
  const { logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions, analyticsSteps } = useAnalytics();
  const { zipCodeMask, zipCodeValidation } = useZipCodeFormatter();

  const isBRAddress = useMemo(
    () => personStore.personAddressCountry === "BR" || !documentStore.documentIssuedCountry,
    [personStore.personAddressCountry],
  );

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_DISPATCH_ADDRESS);
  }, [logAnalyticsStepInfo]);

  const fetchZipCode = async () => {
    if (zipCodeValidation(personStore.personAddressZipCode) && isBRAddress && !generalStore?.workflowId) {
      startLoader({ heading: t("general.message.loader.address", "Buscando endereço...") });
      await emitEvent({
        code: "INSERT_CEP",
      });
      stopLoader();
    }
  };

  useEffect(() => {
    if (personStore.personAddressZipCode) {
      if (
        !personStore.personAddressState ||
        !personStore.personAddressCity ||
        !personStore.personAddressStreet ||
        !personStore.personAddressNeighborhood ||
        !personStore.personAddressNumber
      ) {
        fetchZipCode();
      }
    }
  }, [personStore.personAddressZipCode]);

  useEffect(() => {
    if (personStore.personAddressStateUf) {
      emitEvent({
        code: "SELECT_STATE",
      });
    }
  }, [personStore.personAddressStateUf]);

  const handleBlur = (field) => {
    logAnalyticsActionInfo(
      analyticsActions.FIELD_CHANGE,
      {
        field,
      },
      props.name,
    );
  };

  const allFields = {
    country: {
      get validity() {
        return ({ required }) =>
          !!personStore.personAddressCountry || !documentStore.documentIssuedCountry || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return (
            <FormSelect
              id="personAddressCountry"
              label={label ?? t("general.fields.country.label", "País")}
              placeholder={placeholder ?? t("general.fields.country.placeholder", "Selecione seu país")}
              showSearch
              allowClear
              required={required}
              showRequiredMessage={shouldValidate && !valid}
              prefixIcon={icon ?? "home"}
              value={personStore.personAddressCountry}
              disabled={isImmutableVariable("personAddressCountry")}
              onChange={personStore.setPersonAddressCountry}
              optionFilterProp="children"
              onBlur={() => handleBlur("personAddressCountry")}
            >
              {countries?.map(({ code, name }) => (
                <Option key={code}>{t(`countries.${code}`, name)}</Option>
              ))}
            </FormSelect>
          );
        };
      },
    },
    zipCode: {
      get validity() {
        return ({ required }) => {
          if (required || (!!personStore.personAddressZipCode && personStore.personAddressZipCode.length > 0)) {
            return !!personStore.personAddressZipCode && zipCodeValidation(personStore.personAddressZipCode);
          }
          return true;
        };
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return (
            <FormInput
              id="personAddressZipCode"
              label={label ?? t("general.fields.zipCode.label", "CEP")}
              placeholder={placeholder ?? t("general.fields.zipCode.placeholder", "Digite o seu CEP")}
              icon={<Icon icon={icon ?? "home"} />}
              mask={zipCodeMask}
              required={required}
              showRequiredMessage={shouldValidate && !zipCodeFailed && !valid}
              error={zipCodeFailed || (!!personStore.personAddressZipCode.length > 0 && !valid)}
              isToValidateErrorWithinFocus={!zipCodeFailed}
              errorMessage={
                zipCodeFailed
                  ? props.errors?.cepNotFound?.text ??
                    t(
                      `${I18N_BASE_PATH}.uniqueSteps.dispatchAddress.errors.cepNotFound`,
                      "Ocorreu um erro ao buscar o CEP. Por favor, preencha os dados manualmente.",
                    )
                  : t(formMessagesValidations.valid_cep)
              }
              valid={valid}
              value={personStore.personAddressZipCode}
              disabled={isImmutableVariable("personAddressZipCode")}
              onChange={personStore.setPersonAddressZipCode}
              onBlur={() => handleBlur("personAddressZipCode")}
            />
          );
        };
      },
    },
    state: {
      get validity() {
        return ({ required }) => !!personStore.personAddressState || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return isBRAddress && !generalStore?.workflowId ? (
            <FormSelect
              id="personAddressState"
              label={label ?? t("general.fields.state.label", "Estado")}
              placeholder={
                isFetching && !personStore.personAddressState
                  ? t("general.fields.zipCode.waitingForZipCode", "Aguardando CEP")
                  : `${placeholder ?? t("general.fields.state.placeholder", "Selecione seu estado")}`
              }
              showSearch
              allowClear
              required={required}
              onChange={personStore.setPersonAddressState}
              showRequiredMessage={shouldValidate && !valid}
              prefixIcon={icon ?? "home"}
              value={personStore.personAddressState}
              disabled={isImmutableVariable("personAddressState")}
              onBlur={() => handleBlur("personAddressState")}
            >
              {personStore.personStatesList.map((state) => (
                <Option key={state.name}>{state.name}</Option>
              ))}
            </FormSelect>
          ) : (
            <FormInput
              id="personAddressState"
              label={label ?? t("general.fields.state.label", "Estado")}
              placeholder={`${placeholder ?? t("general.fields.state.inputPlaceholder", "Digite o seu estado")}`}
              required={required}
              onChange={personStore.setPersonAddressState}
              valid={valid}
              showRequiredMessage={shouldValidate && !valid}
              icon={<Icon icon={icon ?? "home"} />}
              value={personStore.personAddressState}
              disabled={isImmutableVariable("personAddressState")}
              onBlur={() => handleBlur("personAddressState")}
            />
          );
        };
      },
    },
    city: {
      get validity() {
        return ({ required }) => !!personStore.personAddressCity || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return isBRAddress && !generalStore?.workflowId ? (
            <FormSelect
              id="personAddressCity"
              label={label ?? t("general.fields.city.label", "Cidade")}
              placeholder={
                isFetching && !personStore.personAddressCity
                  ? t("general.fields.zipCode.waitingForZipCode", "Aguardando CEP")
                  : `${placeholder ?? t("general.fields.city.placeholder", "Selecione sua cidade")}`
              }
              showSearch
              allowClear
              required={required}
              showRequiredMessage={shouldValidate && !valid}
              prefixIcon={icon ?? "home"}
              value={personStore.personAddressCity}
              disabled={isImmutableVariable("personAddressCity")}
              onChange={personStore.setPersonAddressCity}
              onBlur={() => handleBlur("personAddressCity")}
            >
              {personStore.personCitiesList.map((city) => (
                <Option key={city.name}>{city.name}</Option>
              ))}
            </FormSelect>
          ) : (
            <FormInput
              id="personAddressCity"
              label={label ?? t("general.fields.city.label", "Cidade")}
              placeholder={`${placeholder ?? t("general.fields.city.inputPlaceholder", "Digite a sua cidade")}`}
              required={required}
              valid={valid}
              showRequiredMessage={shouldValidate && !valid}
              icon={<Icon icon={icon ?? "home"} />}
              value={personStore.personAddressCity}
              disabled={isImmutableVariable("personAddressCity")}
              onChange={personStore.setPersonAddressCity}
            />
          );
        };
      },
    },
    street: {
      get validity() {
        return ({ required }) => !!personStore.personAddressStreet || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return (
            <FormInput
              id="personAddressStreet"
              label={label ?? t("general.fields.street.label", "Rua")}
              placeholder={
                isFetching &&
                !personStore.personAddressStreet &&
                (documentStore.documentIssuedCountry === "BR" || !documentStore.documentIssuedCountry)
                  ? t("general.fields.zipCode.waitingForZipCode", "Aguardando CEP")
                  : `${placeholder ?? t("general.fields.street.placeholder", "Digite a rua")}`
              }
              icon={<Icon icon={icon ?? "home"} />}
              required={required}
              allowSpecialCharacteres
              showRequiredMessage={shouldValidate && !valid}
              valid={valid}
              value={personStore.personAddressStreet}
              disabled={isImmutableVariable("personAddressStreet")}
              onChange={personStore.setPersonAddressStreet}
              onBlur={() => handleBlur("personAddressStreet")}
            />
          );
        };
      },
    },
    neighborhood: {
      get validity() {
        return ({ required }) => !!personStore.personAddressNeighborhood || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return (
            <FormInput
              id="personAddressNeighborhood"
              label={label ?? t("general.fields.neighborhood.label", "Bairro")}
              placeholder={
                isFetching &&
                !personStore.personAddressNeighborhood &&
                (documentStore.documentIssuedCountry === "BR" || !documentStore.documentIssuedCountry)
                  ? t("general.fields.zipCode.waitingForZipCode", "Aguardando CEP")
                  : `${placeholder ?? t("general.fields.neighborhood.placeholder", "Digite o bairro")}`
              }
              icon={<Icon icon={icon ?? "home"} />}
              required={required}
              showRequiredMessage={shouldValidate && !valid}
              valid={valid}
              value={personStore.personAddressNeighborhood}
              disabled={isImmutableVariable("personAddressNeighborhood")}
              onChange={personStore.setPersonAddressNeighborhood}
              onBlur={() => handleBlur("personAddressNeighborhood")}
            />
          );
        };
      },
    },
    number: {
      get validity() {
        return ({ required }) => !!personStore.personAddressNumber || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return (
            <FormInput
              id="personAddressNumber"
              label={label ?? t("general.fields.number.label", "Número")}
              placeholder={placeholder ?? t("general.fields.number.placeholder", "Digite o número")}
              icon={<Icon icon={icon ?? "home"} />}
              showRequiredMessage={shouldValidate && !valid}
              valid={valid}
              value={personStore.personAddressNumber}
              disabled={isImmutableVariable("personAddressNumber")}
              onChange={personStore.setPersonAddressNumber}
              required={required}
              onBlur={() => handleBlur("personAddressNumber")}
            />
          );
        };
      },
    },
    complement: {
      get validity() {
        return ({ required }) => !!personStore.personAddressComplement || !required;
      },
      get component() {
        return ({ icon, label, placeholder, required }) => {
          const valid = this.validity({ required });

          return (
            <FormInput
              id="personAddressComplement"
              label={label ?? t("general.fields.complement.label", "Complemento")}
              placeholder={placeholder ?? t("general.fields.complement.placeholder", "Digite o complemento (opcional)")}
              icon={<Icon icon={icon ?? "home"} />}
              validate={(complement) => !!complement}
              showRequiredMessage={shouldValidate && !valid}
              allowSpecialCharacteres
              valid={valid}
              value={personStore.personAddressComplement}
              disabled={isImmutableVariable("personAddressComplement")}
              onChange={personStore.setPersonAddressComplement}
              required={required}
              onBlur={() => handleBlur("personAddressComplement")}
            />
          );
        };
      },
    },
  };

  const customGoForward = ({ disabled }) => {
    if (!disabled) {
      goForward();
    } else {
      setShouldValidate(true);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={props.heading ?? t(`${I18N_BASE_PATH}.uniqueSteps.dispatchAddress.heading`, "Endereço de envio")}
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              `${I18N_BASE_PATH}.uniqueSteps.dispatchAddress.subheading`,
              "Para este endereço iremos enviar seu cartão.",
            )
          }
        />
        <Form layout="vertical" requiredMark="optional">
          {props.fields?.map(({ field, icon, label, placeholder, required }, index) => ({
            ...allFields[field].component({
              icon,
              label,
              placeholder,
              required,
            }),
            key: index,
          }))}
        </Form>
      </Card.Body>
      <Card.NavBar
        goForward={customGoForward}
        disabledGoForward={!props.fields?.every(({ field, required }) => allFields[field].validity({ required }))}
      />
    </Card>
  );
};

export default observer(DispatchAddress);
