import store from "@store/index";
import { observer } from "mobx-react-lite";
import Card from "@components/Card";

import LoaderAntd from "./LoaderAntd";
import LoaderDefault from "./LoaderDefault";
import PageLoader from "./PageLoader";

interface Props {
  component?: string;
}

export { PageLoader };

export default observer(function Loader(props: Props) {
  const { heading, isLoading, subheading } = store.ui.loading;

  const renderLoader = () => {
    if (props.component === "loading") return <LoaderDefault />;
    else if (!!props.component) return <img src={props.component} alt="" className="h-32 mb-4" />;
    else return <LoaderAntd />;
  };

  if (isLoading || props.component === "loading") {
    return (
      <div key="LOADER" className={`loader w-full h-full z-30 transition-all rounded-lg py-1`}>
        <div className="w-full h-full flex flex-col items-center justify-center">
          {renderLoader()}
          <Card.Text className="font-bold !text-primary text-center px-10" text={heading} />
          <Card.Text small className="text-center px-10" text={subheading} />
        </div>
      </div>
    );
  }

  return null;
});
