import { useEffect, useMemo, useState } from "react";
import { Form, Select } from "antd";
import { Icon } from "@combateafraude/react";
import { useTranslation } from "react-i18next";
import Card from "@components/Card";
import { FormInput, FormSelect, InputTypes } from "@components/Form";
import { useAnalytics } from "@contexts/Analytics";
import useValidate from "@hooks/useValidate";
import { validateEmail } from "@utils/validations";
import formMessagesValidations from "@utils/formErrors";
import usePhoneFormatter from "@hooks/formatters/usePhoneFormatter";
import store from "@store/index";
import { observer } from "mobx-react-lite";

const { Option } = Select;

const I18N_BASE_PATH = "src.pages.onboarding.steps.employeeData";

const EmployeeData = (props) => {
  const { t } = useTranslation();

  const [occupationOption, setOccupationOption] = useState(undefined);
  const [shouldValidate, setShouldValidate] = useState(false);
  const otherOccupation = t(`${I18N_BASE_PATH}.constants.occupationOption.other`, "Outro");

  const { analyticsSteps, logAnalyticsStepInfo, logAnalyticsActionInfo, analyticsActions } = useAnalytics();
  const { phoneValidation } = usePhoneFormatter();

  const { company: companyStore, isImmutableVariable } = store.variables;
  const { goForward, setStepProps } = store.navigation;

  const isOtherOccupation = useMemo(() => {
    return occupationOption === otherOccupation;
  }, [occupationOption]);

  const handleOccuparionChange = (occupation) => {
    if (occupation === otherOccupation) {
      companyStore.setCompanyEmployeeOccupation("");
    } else {
      companyStore.setCompanyEmployeeOccupation(occupation);
    }

    setOccupationOption(occupation);
  };

  const occupations = useMemo(() => {
    const options = props.fields.find((field) => field.field === "occupation")?.options;

    if (options?.length) return options;

    return [
      t(`${I18N_BASE_PATH}.fields.occupation.options.adm`, "Administrativo"),
      t(`${I18N_BASE_PATH}.fields.occupation.options.sales`, "Comercial"),
      t(`${I18N_BASE_PATH}.fields.occupation.options.hr`, "RH"),
      t(`${I18N_BASE_PATH}.fields.occupation.options.technology`, "Tecnologia"),
      t(`${I18N_BASE_PATH}.fields.occupation.options.other.value`, "Outro"),
    ];
  }, [props.fields, t]);

  useEffect(() => {
    logAnalyticsStepInfo(analyticsSteps.STEP_EMPLOYEE_DATA);

    if (!!companyStore.companyEmployeeOccupation && occupations.includes(companyStore.companyEmployeeOccupation)) {
      setOccupationOption(companyStore.companyEmployeeOccupation);
    } else if (!!companyStore.companyEmployeeOccupation) {
      setOccupationOption(otherOccupation);
    }
  }, [occupations, logAnalyticsStepInfo]);

  useEffect(() => {
    if (companyStore.companyEmployeeOccupation === otherOccupation && !occupationOption) {
      setOccupationOption(otherOccupation);
    }
  }, [companyStore.companyEmployeeOccupation]);

  const handleBlur = (field) => {
    logAnalyticsActionInfo(
      analyticsActions.FIELD_CHANGE,
      {
        field,
      },
      props.name,
    );
  };

  const allFields = {
    name: {
      validity: useValidate(companyStore.companyEmployeeName),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            cleanInitialEmptySpace
            id={id}
            label={label ?? t(`${I18N_BASE_PATH}.fields.name.label`, "Seu nome")}
            placeholder={placeholder ?? t(`${I18N_BASE_PATH}.fields.name.placeholder`, "Digite o seu nome")}
            icon={icon !== "" && <Icon icon={icon || "person"} />}
            uppercase
            required
            showRequiredMessage={shouldValidate && !this.validity}
            value={companyStore.companyEmployeeName}
            disabled={isImmutableVariable("companyEmployeeName")}
            onChange={companyStore.setCompanyEmployeeName}
            valid={this.validity}
            textOnly
            onBlur={() => handleBlur(id)}
          />
        );
      },
    },
    occupation: {
      validity: useValidate(companyStore.companyEmployeeOccupation),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <>
            <FormSelect
              id={id}
              label={label ?? t(`${I18N_BASE_PATH}.fields.occupation.label`, "Seu setor na empresa")}
              placeholder={
                placeholder ?? t(`${I18N_BASE_PATH}.fields.occupation.placeholder`, "Escolha o seu setor na empresa")
              }
              prefixIcon={icon ?? "company"}
              required
              showRequiredMessage={
                shouldValidate &&
                occupationOption !== t(`${I18N_BASE_PATH}.constants.occupationOption.other`, "Outro") &&
                !this.validity
              }
              value={occupationOption}
              disabled={isImmutableVariable("companyEmployeeOccupation")}
              onChange={handleOccuparionChange}
              onBlur={() => handleBlur(id)}
            >
              {occupations.map((option, i) => (
                <Option value={option} key={`OCCUPATION_OPTION_${i}`}>
                  {option}
                </Option>
              ))}
            </FormSelect>
            <FormInput
              label={t(`${I18N_BASE_PATH}.fields.occupation.options.other.input.label`, "Especifique seu setor")}
              placeholder={t(
                `${I18N_BASE_PATH}.fields.occupation.options.other.input.placeholder`,
                "Digite o seu setor na empresa",
              )}
              className={`relative transition-all transform ${
                isOtherOccupation ? "mt-0 opacity-1" : "-mt-20 opacity-0 pointer-events-none"
              }`}
              icon={icon !== "" && <Icon icon={icon || "company"} />}
              required={isOtherOccupation}
              showRequiredMessage={isOtherOccupation && shouldValidate && !this.validity}
              uppercase
              value={companyStore.companyEmployeeOccupation}
              disabled={isImmutableVariable("companyEmployeeOccupation")}
              onChange={companyStore.setCompanyEmployeeOccupation}
              valid={this.validity}
            />
          </>
        );
      },
    },
    email: {
      validity: useValidate(companyStore.companyEmployeeEmail, validateEmail),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            id={id}
            label={label ?? t(`${I18N_BASE_PATH}.fields.email.label`, "Seu email")}
            placeholder={placeholder ?? t(`${I18N_BASE_PATH}.fields.email.placeholder`, "Digite o seu email")}
            icon={icon !== "" && <Icon icon={icon || "mail"} />}
            required
            showRequiredMessage={shouldValidate && !companyStore.companyEmployeeEmail?.length > 0 && !this.validity}
            error={!validateEmail(companyStore.companyEmployeeEmail) && !!companyStore.companyEmployeeEmail?.length > 0}
            isToValidateErrorWithinFocus
            errorMessage={t(formMessagesValidations.valid_email)}
            value={companyStore.companyEmployeeEmail}
            disabled={isImmutableVariable("companyEmployeeEmail")}
            onChange={companyStore.setCompanyEmployeeEmail}
            valid={this.validity}
            type="email"
            onBlur={() => handleBlur(id)}
          />
        );
      },
    },
    phone_number: {
      validity: useValidate(companyStore.companyEmployeePhoneNumber, phoneValidation),
      get component() {
        return ({ label, placeholder, icon, id }) => (
          <FormInput
            id={id}
            label={label ?? t(`${I18N_BASE_PATH}.fields.phone.label`, "Seu telefone")}
            placeholder={
              placeholder ?? t(`${I18N_BASE_PATH}.fields.phone.placeholder`, "Digite o seu telefone de contato")
            }
            icon={icon !== "" && <Icon icon={icon || "contact_"} />}
            inputType={InputTypes.PHONE}
            type="tel"
            required
            showRequiredMessage={
              shouldValidate && !companyStore.companyEmployeePhoneNumber?.length > 0 && !this.validity
            }
            error={!!companyStore.companyEmployeePhoneNumber?.length > 0 && !this.validity}
            isToValidateErrorWithinFocus
            errorMessage={t(formMessagesValidations.valid_phone_number)}
            value={companyStore.companyEmployeePhoneNumber}
            disabled={isImmutableVariable("companyEmployeePhoneNumber")}
            onChange={companyStore.setCompanyEmployeePhoneNumber}
            valid={this.validity}
            onBlur={() => handleBlur(id)}
          />
        );
      },
    },
  };

  const customGoForward = ({ disabled }) => {
    if (disabled) setShouldValidate(true);
    else {
      if (!props.manual) {
        setStepProps("QSA", (currentProps) => ({
          ...currentProps,
          fromEmployee: true,
        }));

        companyStore.setCompanyQsaInfo(
          companyStore.companyQsa.map((qsa) => ({
            name: qsa.name,
            cpf: "",
            email: "",
            phone: "",
          })),
        );
      }

      setShouldValidate(false);
      goForward();
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Heading
          text={
            props.heading ??
            t("src.pages.onboarding.steps.allSteps.uniqueSteps.employeeData.heading", "Suas informações")
          }
        />
        <Card.Subheading
          text={
            props.subheading ??
            t(
              "src.pages.onboarding.steps.allSteps.uniqueSteps.employeeData.subheading",
              "Primeiro vamos falar sobre você. Preencha os campos abaixo.",
            )
          }
        />
        <Form layout="vertical">
          {props.fields.map((item, index) =>
            item.field && allFields[item.field]
              ? { ...allFields[item.field].component({ ...item, id: item.field }), key: `EMPLOYEE_DATA_INPUT_${index}` }
              : null,
          )}
        </Form>
      </Card.Body>
      <Card.NavBar
        disabledGoForward={!props.fields.every(({ field }) => allFields[field] && allFields[field].validity)}
        goForward={customGoForward}
      />
    </Card>
  );
};

export default observer(EmployeeData);
