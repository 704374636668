import { useState, useMemo } from "react";
import { Logger } from "@utils/logging";
import { applyVariable } from "@utils/formatting";
import set from "lodash.set";
import store from "@store/index";
import useTemplateData from "./useTemplateData";

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
    FlutterOnboardingChannel?: {
      postMessage: (message: string) => void;
    };
  }
}

const eventCodes = [
  "DD_CAPTURE_FINISH",
  "FINISH_FACE_AUTH",
  "ONBOARDING_FINISHED",
  "SDK_FACE_AUTH",
  "INSERT_CEP",
  "SELECT_STATE",
  "INSERT_COMPANY_CEP",
  "SELECT_COMPANY_STATE",
  "SDK_FACE_AUTH_IS_MATCH_FALSE",
  "SEARCH_COMPANY",
] as const;

interface Message {
  [key: string]: any;
  code: (typeof eventCodes)[number];
}

interface Services {
  [key: string]: {
    body: {
      [key: string]: string;
    };
    urlParams: {
      [key: string]: string;
    };
  };
}

// To use this hook, you need to add the observer HOC to your component
export default function useEvents() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const { token } = store.variables.general;

  const { templateData } = useTemplateData();

  const events = templateData?.template?.events;

  const servicesMap = useMemo(() => {
    const services = templateData?.services;

    if (!!services) {
      return services.reduce((acc, service) => {
        acc[service.name] = {
          body: service.body,
          urlParams: service.urlParams,
        };

        return acc;
      }, {} as Services);
    }
  }, [templateData]);

  const emitEvent = async (message: Message) => {
    Logger.info("Emitting event", message);
    const definedEvent = new Event(message.code);
    window.dispatchEvent(definedEvent);

    const jsonMessage = JSON.stringify(message);

    if (!!events && !!servicesMap) {
      const eventRun = events.find(({ on }) => on === message.code);

      if (!!eventRun) {
        setIsLoading(true);
        setError(false);

        for (const run of eventRun.run) {
          const service = servicesMap[run];

          if (!!service) {
            const headers = new Headers();

            const request = {
              headers,
              method: "POST",
              body: {
                data: {},
                urlParams: {},
              },
            };

            if (!!service.body)
              Object.keys(service.body).forEach((key) => {
                const value = applyVariable(service.body[key], false, store.variables.flatten());

                if (!!value)
                  set(request.body.data, key, applyVariable(service.body[key], false, store.variables.flatten()));
              });

            if (!!service.urlParams)
              Object.keys(service.urlParams).forEach((key) => {
                const value = applyVariable(service.urlParams[key], false, store.variables.flatten());

                set(request.body.urlParams, key, value || "");
              });

            Logger.info("Running service", run, {
              request,
              service,
            });
            try {
              const response = await fetch(
                `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API}/onboardings/${token}/services/${run}`,
                {
                  ...request,
                  body: JSON.stringify(request.body),
                },
              );

              if (response.ok || (response.status >= 200 && response.status < 300)) {
                const data = await response.json();

                store.variables.updateVariables(data);
              } else {
                setError(true);
              }
            } catch (error) {
              setError(true);
            }
          }
        }
        setIsLoading(false);
      }
    }

    // Iframe
    window.parent.postMessage(message, "*");
    // React Native
    window?.ReactNativeWebView?.postMessage(jsonMessage);
    // Flutter
    window?.FlutterOnboardingChannel?.postMessage(jsonMessage);
    // IOS - Not tested yet
    // window?.webkit?.messageHandlers?.onboardingHandler?.postMessage(jsonMessage);
  };

  return { emitEvent, isLoading, error };
}
